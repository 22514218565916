import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"; 
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminLevelService from '../../api/services/AdminLevelService';

const LevelsList = () => {
    
    const [loading, setLoading] = useState(true);
    const [LevelsList, setLevelsList] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = LevelsList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(LevelsList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate(); 
    
     
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    } 
    useEffect(() => {
        if (localStorage.getItem('usertoken')) { 
            getLevelsList(); 
        }
        else {
            navigate('/');
        }

    }, [])
    const getLevelsList = async () => { 
        try { 
            setLoading(true);
            let responseData = await AdminLevelService.index().json();  
            setLevelsList(responseData.data);
            setLoading(false);    
        } catch (error) { 
            console.log(error);               
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();  
                
            }
        }
    }
 
   
    return (
        <>
        <MainPagetitle mainTitle="Levels" pageTitle={'Levels'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Level List</h4>
                                       
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr style={{ textAlign: '' }}>
                                                    <th >
                                                        <strong>No.</strong>
                                                    </th>
                                                    <th>
                                                        <strong  className="d-flex">Title</strong>
                                                    </th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                                                {
                                                    records.map((element, index) => {

                                                        return (
                                                            <tr style={{ textAlign: '' }}>
                                                                <td >
                                                                    {index + 1}
                                                                </td>
                                                                <td  className="d-flex">
                                                                    {element.title} 
                                                                </td> 
                                                                
                                                                
                                                                
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>
                                    )}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {LevelsList.length < lastIndex ? LevelsList.length : lastIndex}
                                                {" "}of {LevelsList.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default LevelsList;
