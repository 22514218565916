import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Nav, Tab } from "react-bootstrap";
import MainPagetitle from '../../layouts/MainPagetitle';
import AdminDashboardService from '../../api/services/AdminDashboardService';
import ReactApexChart from "react-apexcharts";

const Home = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [gameloading, setgameLoading] = useState(false);
	const [ontSNAPLoading, setontSNAPLoading] = useState(false);
	const [RekenkubusLoading, setRekenkubusLoading] = useState(false);
	const [TotalSchool, setTotalSchool] = useState('');
	const [TotalClasses, setTotalClasses] = useState('');
	const [TotalStudents, setTotalStudents] = useState('');
	const [TotalTeachers, setTotalTeachers] = useState('');

	const [RekenkubusCategory, setRekenkubusCategory] = useState([]);
	const [RekenkubusCounts, setRekenkubuscounts] = useState([]);
	const [TaalkubusCategory, setTaalkubusCategory] = useState([]);
	const [TaalkubusCounts, setTaalkubuscounts] = useState([]);
	const [ontSNAPCategory, setontSNAPCategory] = useState([]);
	const [ontSNAPCounts, setontSNAPcounts] = useState([]);
	const [Activekey, setActivekey] = useState('day');
	const [TaalkubusActivekey, setTaalkubusActivekey] = useState("day");
	const [ontSNAPActivekey, setontSNAPActivekey] = useState('day');
	useEffect(() => {
		if (localStorage.getItem('usertoken')) {
			gettotalList();
			Rekenkubusgamelist();
			Taalkubusgamelist();
			ontSNAPgamelist();
		}
		else {
			navigate('/');
		}

	}, [])
	const gettotalList = async () => {
		try {
			setLoading(true);
			let responseData = await AdminDashboardService.index().json();
			setTotalSchool(responseData.data.total_school)
			setTotalClasses(responseData.data.total_classes)
			setTotalStudents(responseData.data.total_students)
			setTotalTeachers(responseData.data.total_teacher)
			setLoading(false);
		} catch (error) {

			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				console.log(errorJson);
			}
		}
	}
	const Rekenkubusgamelist = async () => {
		try {
			setLoading(true);
			let userData = {
				"game_id": 1,
				"filter": "day"
			}
			let responseData = await AdminDashboardService.gamesindex(userData).json();
			setRekenkubusCategory(responseData.datacounts.domain_name)
			setRekenkubuscounts(responseData.datacounts.domain_count)
			setLoading(false);
		} catch (error) {

			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				console.log(errorJson);
			}
		}
	}
	const Taalkubusgamelist = async () => {
		try {
			setLoading(true);
			let userData = {
				"game_id": 2,
				"filter": "day"
			}
			let responseData = await AdminDashboardService.gamesindex(userData).json();
			setTaalkubusCategory(responseData.datacounts.domain_name)
			setTaalkubuscounts(responseData.datacounts.domain_count)
			setLoading(false);
		} catch (error) {

			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				console.log(errorJson);
			}
		}
	}
	const ontSNAPgamelist = async () => {
		try {
			setLoading(true);
			let userData = {
				"game_id": 3,
				"filter": "day"
			}
			let responseData = await AdminDashboardService.gamesindex(userData).json();
			setontSNAPCategory(responseData.datacounts.domain_name)
			setontSNAPcounts(responseData.datacounts.domain_count)
			setLoading(false);
		} catch (error) {

			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				console.log(errorJson);
			}
		}
	}

	const handleRekenkubus = async (eventKey) => {
		setRekenkubusLoading(true);
		setActivekey(eventKey);

		try {
			const userData = {
				game_id: 1,
				filter: eventKey,
			};

			// Make the API call
			const response = await AdminDashboardService.gamesindex(userData);
			const responseData = await response.json(); // Ensure you're awaiting the response.json() method

			// Update state with the response data
			setRekenkubusCategory(responseData.datacounts.domain_name)
			setRekenkubuscounts(responseData.datacounts.domain_count)
		} catch (error) {
			console.error('Error fetching data:', error); // More general error handling

			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				console.log('HTTP Error:', errorJson);
			}
		} finally {
			setRekenkubusLoading(false);// Ensure loading is set to false in finally block
		}
	};
	const series = [
		{
			name: "Score",
			data: RekenkubusCounts,
		},
	]
	const options = {
		chart: {
			height: 433,
			type: "bar",
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '30%',
				endingShape: 'rounded'
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
			width: 10,
			show: true,
		},
		colors: ["var(--primary)"],
		fill: {
			gradient: {
				opacityFrom: 0.6,
				opacityTo: 0.5,
			},
		},


		title: {
			text: undefined,
			offsetX: 0,
			offsetY: 0,
			style: {
				// color: undefined,
				fontSize: "12px",
				fontFamily: "Helvetica, Arial, sans-serif",
				fontWeight: 600,
				borderColor: "rgba(44, 44, 44, 1)",
				borderWidth: "0",
				backgroundColor: "rgba(44, 44, 44, 1)",
				cssClass: "apexcharts-xaxis-title",
			},
		},
		yaxis: {

			logbase: 10,
			// min:0,
			// max:100,
			tickAmount: 5,
			axisBorder: {
				show: true,

				color: "#FFA79D",
				offsetX: 0,
				offsetY: 0,
			},
		},
		grid: {
			show: true, // you can either change hear to disable all grids
			xaxis: {
				lines: {
					show: false, //or just here to disable only x axis grids
				},
			},
			yaxis: {
				lines: {
					show: true, //or just here to disable only y axis
				},
				title: {
					text: '$ (thousands)'
				}
			},
		},
		xaxis: {
			// show: false,
			categories: RekenkubusCategory,
		},
		scales: {
			y:
			{
				gridLines: {
					color: "rgba(89, 59, 219,0.1)",
					drawBorder: true,
				},
				ticks: {
					fontColor: "#999",
				},
			},

			x:
			{
				gridLines: {
					display: false,
					zeroLineColor: "transparent",
				},
				ticks: {
					stepSize: 5,
					fontColor: "#999",
					fontFamily: "Nunito, sans-serif",
				},
			},

		},
		tooltips: {
			mode: "index",
			intersect: false,
			titleFontColor: "#888",
			bodyFontColor: "#555",
			titleFontSize: 12,
			bodyFontSize: 15,
			backgroundColor: "rgba(256,256,256,0.95)",
			displayColors: true,
			xPadding: 10,
			yPadding: 7,
			borderColor: "rgba(220, 220, 220, 0.9)",
			borderWidth: 2,
			caretSize: 6,
			caretPadding: 10,
		},
		markers: {
			size: 5,
		}
	}
	const handleTaalkubus = async (eventKey) => {
		setgameLoading(true);
		setTaalkubusActivekey(eventKey);

		try {
			const userData = {
				game_id: 2,
				filter: eventKey,
			};

			// Make the API call
			const response = await AdminDashboardService.gamesindex(userData);
			const responseData = await response.json(); // Ensure you're awaiting the response.json() method

			// Update state with the response data
			setTaalkubusCategory(responseData.datacounts.domain_name);
			setTaalkubuscounts(responseData.datacounts.domain_count);
		} catch (error) {
			console.error('Error fetching data:', error); // More general error handling

			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				console.log('HTTP Error:', errorJson);
			}
		} finally {
			setgameLoading(false);// Ensure loading is set to false in finally block
		}
	};

	const Taalkubusseries = [
		{
			name: "Score",
			data: TaalkubusCounts,
		},
	]
	const Taalkubusoptions = {
		chart: {
			height: 433,
			type: "bar",
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '30%',
				endingShape: 'rounded'
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
			width: 10,
			show: true,
		},
		colors: ["var(--primary)"],
		fill: {
			gradient: {
				opacityFrom: 0.6,
				opacityTo: 0.5,
			},
		},


		title: {
			text: undefined,
			offsetX: 0,
			offsetY: 0,
			style: {
				// color: undefined,
				fontSize: "12px",
				fontFamily: "Helvetica, Arial, sans-serif",
				fontWeight: 600,
				borderColor: "rgba(44, 44, 44, 1)",
				borderWidth: "0",
				backgroundColor: "rgba(44, 44, 44, 1)",
				cssClass: "apexcharts-xaxis-title",
			},
		},
		yaxis: {

			logbase: 10,
			// min:0,
			// max:100,
			tickAmount: 5,
			axisBorder: {
				show: true,

				color: "#FFA79D",
				offsetX: 0,
				offsetY: 0,
			},
		},
		grid: {
			show: true, // you can either change hear to disable all grids
			xaxis: {
				lines: {
					show: false, //or just here to disable only x axis grids
				},
			},
			yaxis: {
				lines: {
					show: true, //or just here to disable only y axis
				},
				title: {
					text: '$ (thousands)'
				}
			},
		},
		xaxis: {
			// show: false,
			categories: TaalkubusCategory,
		},
		scales: {
			y:
			{
				gridLines: {
					color: "rgba(89, 59, 219,0.1)",
					drawBorder: true,
				},
				ticks: {
					fontColor: "#999",
				},
			},

			x:
			{
				gridLines: {
					display: false,
					zeroLineColor: "transparent",
				},
				ticks: {
					stepSize: 5,
					fontColor: "#999",
					fontFamily: "Nunito, sans-serif",
				},
			},

		},
		tooltips: {
			mode: "index",
			intersect: false,
			titleFontColor: "#888",
			bodyFontColor: "#555",
			titleFontSize: 12,
			bodyFontSize: 15,
			backgroundColor: "rgba(256,256,256,0.95)",
			displayColors: true,
			xPadding: 10,
			yPadding: 7,
			borderColor: "rgba(220, 220, 220, 0.9)",
			borderWidth: 2,
			caretSize: 6,
			caretPadding: 10,
		},
		markers: {
			size: 5,
		}
	}
	const handleontSNAP = async (eventKey) => {
		setontSNAPLoading(true);
		setontSNAPActivekey(eventKey);

		try {
			const userData = {
				"game_id": 3,
				"filter": eventKey
			};

			// Make the API call
			const response = await AdminDashboardService.gamesindex(userData);
			const responseData = await response.json(); // Ensure you're awaiting the response.json() method

			// Update state with the response data
			setontSNAPCategory(responseData.datacounts.domain_name)
			setontSNAPcounts(responseData.datacounts.domain_count)
		} catch (error) {
			console.error('Error fetching data:', error); // More general error handling

			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				console.log('HTTP Error:', errorJson);
			}
		} finally {
			setontSNAPLoading(false);// Ensure loading is set to false in finally block
		}
	};

	const ontSNAPseries = [
		{
			name: "Score",
			data: ontSNAPCounts,
		},
	]
	const ontSNAPoptions = {
		chart: {
			height: 433,
			type: "bar",
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '30%',
				endingShape: 'rounded'
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
			width: 10,
			show: true,
		},
		colors: ["var(--primary)"],
		fill: {
			gradient: {
				opacityFrom: 0.6,
				opacityTo: 0.5,
			},
		},


		title: {
			text: undefined,
			offsetX: 0,
			offsetY: 0,
			style: {
				// color: undefined,
				fontSize: "12px",
				fontFamily: "Helvetica, Arial, sans-serif",
				fontWeight: 600,
				borderColor: "rgba(44, 44, 44, 1)",
				borderWidth: "0",
				backgroundColor: "rgba(44, 44, 44, 1)",
				cssClass: "apexcharts-xaxis-title",
			},
		},
		yaxis: {

			logbase: 10,
			// min:0,
			// max:100,
			tickAmount: 5,
			axisBorder: {
				show: true,

				color: "#FFA79D",
				offsetX: 0,
				offsetY: 0,
			},
		},
		grid: {
			show: true, // you can either change hear to disable all grids
			xaxis: {
				lines: {
					show: false, //or just here to disable only x axis grids
				},
			},
			yaxis: {
				lines: {
					show: true, //or just here to disable only y axis
				},
				title: {
					text: '$ (thousands)'
				}
			},
		},
		xaxis: {
			// show: false,
			categories: ontSNAPCategory,
		},
		scales: {
			y:
			{
				gridLines: {
					color: "rgba(89, 59, 219,0.1)",
					drawBorder: true,
				},
				ticks: {
					fontColor: "#999",
				},
			},

			x:
			{
				gridLines: {
					display: false,
					zeroLineColor: "transparent",
				},
				ticks: {
					stepSize: 5,
					fontColor: "#999",
					fontFamily: "Nunito, sans-serif",
				},
			},

		},
		tooltips: {
			mode: "index",
			intersect: false,
			titleFontColor: "#888",
			bodyFontColor: "#555",
			titleFontSize: 12,
			bodyFontSize: 15,
			backgroundColor: "rgba(256,256,256,0.95)",
			displayColors: true,
			xPadding: 10,
			yPadding: 7,
			borderColor: "rgba(220, 220, 220, 0.9)",
			borderWidth: 2,
			caretSize: 6,
			caretPadding: 10,
		},
		markers: {
			size: 5,
		}
	}
	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle={'Dashboard'} parentTitle={'Home'} />
			<div className="container-fluid">
				{loading ? (
					<div class="spinner">
						<div class="lds-roller">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>

				) : (
					<div className="row">
						<div className="col-xl-12 wid-100">
							<div className="row">

								<div className="col-xl-3  col-lg-6 col-sm-6">
									<div className="widget-stat card bg-danger ">
										<div className="card-body p-4">
											<div className="media">
												<span className="me-3">

													<svg width="30" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
														<g clipPath="url(#clip0)">
															<path d="M59.0284 17.8807L30.7862 3.81817C30.2918 3.57103 29.7082 3.57103 29.2138 3.81817L0.971632 17.8807C0.375968 18.1794 3.05176e-05 18.787 3.05176e-05 19.4531C3.05176e-05 20.1192 0.375968 20.7268 0.971632 21.0255L29.2138 35.088C29.461 35.2116 29.7305 35.2734 30 35.2734C30.2696 35.2734 30.5391 35.2116 30.7862 35.088L59.0284 21.0255C59.6241 20.7268 60 20.1192 60 19.4531C60 18.787 59.6241 18.1794 59.0284 17.8807Z" fill="white" />
															<path d="M56.4844 46.1441V26.2285L52.9688 27.9863V46.1441C50.9271 46.8722 49.4532 48.805 49.4532 51.0937V54.6093C49.4532 55.5809 50.2394 56.3671 51.211 56.3671H58.2422C59.2138 56.3671 60 55.5809 60 54.6093V51.0937C60 48.805 58.526 46.8722 56.4844 46.1441Z" fill="white" />
															<path d="M32.3587 38.2329C31.6308 38.5967 30.8154 38.789 30 38.789C29.1846 38.789 28.3692 38.5967 27.6414 38.2329L10.5469 29.7441V33.5156C10.5469 40.4147 19.1578 45.8203 30 45.8203C40.8422 45.8203 49.4532 40.4147 49.4532 33.5156V29.7441L32.3587 38.2329Z" fill="white" />
														</g>
														<defs>
															<clipPath >
																<rect width="60" height="60" fill="white" />
															</clipPath>
														</defs>
													</svg>
												</span>
												<div className="media-body text-white">
													<p className="mb-1">Total Schools</p>
													<h3 className="text-white">{TotalSchool ? TotalSchool : 0}</h3>

													<small></small>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-xl-3  col-lg-6 col-sm-6">
									<div className="widget-stat card bg-secondary">
										<div className="card-body p-4">
											<div className="media">
												<span className="me-3">
													<svg height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink"
														viewBox="0 0 495.322 495.322" xmlspace="preserve">
														<g>
															<g>
																<path style={{ fill: "#fff" }} d="M347.392,131.619h-65.093V66.501c0-0.514-0.433-0.944-0.938-0.944
																	c-36.938,0-66.967,30.045-66.967,66.96c0,5.941,1.036,11.599,2.518,17.087l38.268-29.761c2.909-2.295,7.562-1.742,9.879,1.213
																	c2.427,3.109,1.873,7.562-1.229,9.948l-41.619,32.417c11.176,21.379,33.406,36.079,59.152,36.079
																	c36.953,0,66.968-30.006,66.968-66.967C348.33,132.042,347.932,131.619,347.392,131.619z"/>
																<path style={{ fill: "#fff" }} d="M348.646,79.473c-0.571-0.663-1.396-1.054-2.271-1.075c-0.922-0.022-1.72,0.33-2.321,0.965
																	l-37.784,39.856c-0.852,0.898-1.086,2.217-0.598,3.354c0.488,1.137,1.607,1.874,2.845,1.874h55.092c0.008,0,0.016,0.001,0.02,0
																	c1.711,0,3.097-1.387,3.097-3.097c0-0.237-0.026-0.467-0.076-0.688C364.944,105.408,358.724,91.172,348.646,79.473z
																	M315.719,118.253l30.415-32.083c7.336,9.395,12.105,20.369,13.944,32.083H315.719z"/>
																<path style={{ fill: "#fff" }} d="M293.701,113.661c0.372,0.148,0.761,0.22,1.146,0.22c0.834,0,1.652-0.337,2.249-0.967
												l36.263-38.267c0.606-0.64,0.911-1.509,0.838-2.387c-0.073-0.879-0.519-1.685-1.223-2.216
												c-11.098-8.362-24.169-13.546-37.8-14.991c-0.869-0.095-1.745,0.19-2.397,0.777s-1.025,1.424-1.025,2.302v52.652
												C291.751,112.053,292.523,113.192,293.701,113.661z M297.944,61.662c10.146,1.596,19.875,5.458,28.476,11.304l-28.476,30.049
												V61.662z"/>
																<path style={{ fill: "#fff" }} d="M107.049,68.543c-17.632,0-31.925,14.301-31.926,31.933c0,17.633,14.294,31.926,31.926,31.926
			c17.64,0,31.933-14.293,31.933-31.926C138.982,82.844,124.689,68.543,107.049,68.543z"/>
																<path style={{ fill: "#fff" }} d="M477.074,401.089l-22.461-20.663c-3.73,10.44-11.147,26.006-25.809,42.464
			c-12.496,14.047-28.219,26.376-46.719,36.815l23.507,21.615c21.77,20.081,55.499,18.331,75.212-3.764
			C500.502,455.419,498.812,421.105,477.074,401.089z"/>
																<path style={{ fill: "#fff" }} d="M419.394,416.07c12.082-13.542,20.618-28.587,25.361-44.646l-19.393-17.871
			c-19.712-18.146-40.221-9.855-60.487,7.523c0,0-0.406,0.307-1.062,0.88c-0.005-0.004-0.01-0.008-0.015-0.012
			c-8.52-6.439-18.252-9.331-28.563-11.502c-8.929-1.881-21.035-3.716-26.809-11.656c-4.557-6.266,0.046-15.016,3.032-20.9
			c1.1-2.167,2.259-4.306,3.418-6.443l67.192,0.021c17.205,0,27.205-12.48,27.205-27.896V27.872C409.335,12.481,397.161,0,382.099,0
			H28.052C12.977,0,0.778,12.481,0.778,27.872V283.54c0,15.429,12.198,27.88,27.274,27.88h135.847c0,0,5.88,55.791-41.206,55.791
			v27.911h41.206h82.297h41.213v-27.911c-48.866,0-41.213-55.791-41.213-55.791l58.298,0.019
			c-5.175,9.625-9.353,19.509-4.946,29.935c4.022,9.518,16.577,13.737,25.673,16.063c11.523,2.944,22.607,4.373,32.518,11.198
			c-1.783,2.311-3.086,4.234-3.086,4.234c-14.246,22.046-23.182,40.255-3.93,57.972l22.414,20.665
			C384.345,445.731,403.197,434.278,419.394,416.07z M70.188,245.251h-8.49v-72.05h8.521L70.188,245.251z M206.879,294.133
			c-9.135,0-16.55-7.554-16.55-16.826c0-9.271,7.416-16.795,16.55-16.795c9.15,0,16.558,7.522,16.558,16.795
			C223.437,286.564,216.028,294.133,206.879,294.133z M145.598,245.251l-0.015-72.38h8.191v22.668c0,0,0,0.077,0,0.107
			c0,17.184,13.629,14.684,18.238,11.37c3.867-2.781,21.724-14.839,21.724-14.839l11.445-7.753c0,0,8.204-5.629,8.237-5.657
			c9.016-6.624,1.873-15.928,1.873-15.928l45.667-35.541c1.027-0.807,1.213-2.28,0.414-3.301c-0.8-1.005-2.271-1.212-3.302-0.415
			l-45.812,35.641l-0.016-0.015c-2.111-1.589-3.417-2.248-5.935-2.348c-3.493-0.153-7.156,1.064-10.876,3.637l-1.712,1.259
			l-14.823,10.232v-4.367c-0.62-33.791-40.623-32.731-40.623-32.731h-16.396l-13.817,23.09l-13.871-23.075h-15.75
			c-39.963,0-40.262,28.817-40.262,39.648h-0.016v70.683h-8.19V29.687h350.198v215.564H145.598z M409.624,409.987
			c-1.615,1.834-3.905,2.753-6.177,2.674c-1.749-0.062-3.477-0.706-4.91-1.954l-32.58-28.666c-3.249-2.88-3.568-7.836-0.688-11.115
			c2.849-3.249,7.805-3.552,11.085-0.688l32.583,28.648C412.183,401.75,412.504,406.723,409.624,409.987z"/>
															</g>
														</g>
													</svg>
												</span>
												<div className="media-body text-white">
													<p className="mb-1">Total Classes</p>
													<h3 className="text-white">{TotalClasses ? TotalClasses : 0}</h3>


												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3  col-lg-6 col-sm-6">
									<div className="widget-stat card bg-primary">
										<div className="card-body  p-4">
											<div className="media">
												<span className="me-3">
													<i className="la la-users"></i>
												</span>
												<div className="media-body text-white">
													<p className="mb-1">Total Students</p>
													<h3 className="text-white">{TotalStudents ? TotalStudents : 0}</h3>

												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3  col-lg-6 col-sm-6">
									<div className="widget-stat card bg-success">
										<div className="card-body  p-4">
											<div className="media">
												<span className="me-3">
													<svg width="30" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M57.4998 47.5001C57.4998 48.1631 57.2364 48.799 56.7676 49.2678C56.2988 49.7367 55.6629 50.0001 54.9998 50.0001H24.9998C24.3368 50.0001 23.7009 49.7367 23.2321 49.2678C22.7632 48.799 22.4998 48.1631 22.4998 47.5001C22.4998 43.5218 24.0802 39.7065 26.8932 36.8935C29.7063 34.0804 33.5216 32.5001 37.4998 32.5001H42.4998C46.4781 32.5001 50.2934 34.0804 53.1064 36.8935C55.9195 39.7065 57.4998 43.5218 57.4998 47.5001ZM39.9998 10.0001C38.022 10.0001 36.0886 10.5866 34.4441 11.6854C32.7996 12.7842 31.5179 14.346 30.761 16.1732C30.0041 18.0005 29.8061 20.0112 30.192 21.951C30.5778 23.8908 31.5302 25.6726 32.9288 27.0711C34.3273 28.4697 36.1091 29.4221 38.0489 29.8079C39.9887 30.1938 41.9994 29.9957 43.8267 29.2389C45.6539 28.482 47.2157 27.2003 48.3145 25.5558C49.4133 23.9113 49.9998 21.9779 49.9998 20.0001C49.9998 17.3479 48.9463 14.8044 47.0709 12.929C45.1955 11.0536 42.652 10.0001 39.9998 10.0001ZM17.4998 10.0001C15.522 10.0001 13.5886 10.5866 11.9441 11.6854C10.2996 12.7842 9.0179 14.346 8.26102 16.1732C7.50415 18.0005 7.30611 20.0112 7.69197 21.951C8.07782 23.8908 9.03022 25.6726 10.4287 27.0711C11.8273 28.4697 13.6091 29.4221 15.5489 29.8079C17.4887 30.1938 19.4994 29.9957 21.3267 29.2389C23.1539 28.482 24.7157 27.2003 25.8145 25.5558C26.9133 23.9113 27.4998 21.9779 27.4998 20.0001C27.4998 17.3479 26.4463 14.8044 24.5709 12.929C22.6955 11.0536 20.152 10.0001 17.4998 10.0001ZM17.4998 47.5001C17.4961 44.8741 18.0135 42.2735 19.0219 39.8489C20.0304 37.4242 21.5099 35.2238 23.3748 33.3751C21.8487 32.7989 20.2311 32.5025 18.5998 32.5001H16.3998C12.7153 32.5067 9.18366 33.9733 6.57833 36.5786C3.97301 39.1839 2.50643 42.7156 2.49982 46.4001V47.5001C2.49982 48.1631 2.76321 48.799 3.23205 49.2678C3.70089 49.7367 4.33678 50.0001 4.99982 50.0001H17.9498C17.6588 49.1984 17.5066 48.3529 17.4998 47.5001Z" fill="white" />
													</svg>
												</span>
												<div className="media-body text-white">
													<p className="mb-1">Total Teachers</p>
													<h3 className="text-white">{TotalTeachers ? TotalTeachers : 0}</h3>


												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="col-xl-6 col-xxl-6 col-lg-12 col-sm-12">
							<div id="user-activity" className="card">

								<Tab.Container defaultActiveKey="day" activeKey={Activekey} onSelect={handleRekenkubus}>
									<div className="card-header border-0 pb-0 d-sm-flex d-block">
										<h4 className="card-title">Rekenkubus</h4>
										<div className="card-action mb-sm-0 my-2">
											<Nav className="nav nav-tabs" role="tablist">
												{["day", "week", "month", "year"].map((key) => (
													<Nav.Item className="nav-item" key={key}>
														<Nav.Link className="nav-link" eventKey={key}>
															{key.charAt(0).toUpperCase() + key.slice(1)}
														</Nav.Link>
													</Nav.Item>
												))}
											</Nav>
										</div>
									</div>
									<div className="card-body">
										{RekenkubusLoading ? (
											<div className="loading">Loading...</div> // Placeholder for loading state
										) : (
											<Tab.Content className="tab-content" id="myTabContent">
												{["day", "week", "month", "year"].map((key) => (
													<Tab.Pane eventKey={key} key={key} role="tabpanel">
														<div style={{ minHeight: "290px" }}>
															<ReactApexChart
																options={options}
																series={series}
																type="bar"
																height={300}
															/>
														</div>
													</Tab.Pane>
												))}
											</Tab.Content>
										)}
									</div>
								</Tab.Container>
							</div>
						</div>
						<div className="col-xl-6 col-xxl-6 col-lg-12 col-sm-12">
							<div id="user-activity" className="card">

								<Tab.Container defaultActiveKey="day" activeKey={TaalkubusActivekey} onSelect={handleTaalkubus}>
									<div className="card-header border-0 pb-0 d-sm-flex d-block">
										<h4 className="card-title">Taalkubus</h4>
										<div className="card-action mb-sm-0 my-2">
											<Nav className="nav nav-tabs" role="tablist">
												{["day", "week", "month", "year"].map((key) => (
													<Nav.Item className="nav-item" key={key}>
														<Nav.Link className="nav-link" eventKey={key}>
															{key.charAt(0).toUpperCase() + key.slice(1)}
														</Nav.Link>
													</Nav.Item>
												))}
											</Nav>
										</div>
									</div>
									<div className="card-body">
										{gameloading ? (
											<div className="loading">Loading...</div> // Placeholder for loading state
										) : (
											<Tab.Content className="tab-content" id="myTabContent">
												{["day", "week", "month", "year"].map((key) => (
													<Tab.Pane eventKey={key} key={key} role="tabpanel">
														<div style={{ minHeight: "290px" }}>
															<ReactApexChart
																options={Taalkubusoptions}
																series={Taalkubusseries}
																type="bar"
																height={300}
															/>
														</div>
													</Tab.Pane>
												))}
											</Tab.Content>
										)}
									</div>
								</Tab.Container>

							</div>
						</div>

						<div className="col-xl-6 col-xxl-6 col-lg-12 col-sm-12">
							<div id="user-activity" className="card">

								<Tab.Container defaultActiveKey="day" activeKey={ontSNAPActivekey} onSelect={handleontSNAP}>
									<div className="card-header border-0 pb-0 d-sm-flex d-block">
										<h4 className="card-title">ontSNAP de wiskunde
										</h4>
										<div className="card-action mb-sm-0 my-2">
											<Nav className="nav nav-tabs" role="tablist">
												{["day", "week", "month", "year"].map((key) => (
													<Nav.Item className="nav-item" key={key}>
														<Nav.Link className="nav-link" eventKey={key}>
															{key.charAt(0).toUpperCase() + key.slice(1)}
														</Nav.Link>
													</Nav.Item>
												))}
											</Nav>
										</div>
									</div>
									<div className="card-body">
										{ontSNAPLoading ? (
											<div className="loading">Loading...</div> // Placeholder for loading state
										) : (
											<Tab.Content className="tab-content" id="myTabContent">
												{["day", "week", "month", "year"].map((key) => (
													<Tab.Pane eventKey={key} key={key} role="tabpanel">
														<div style={{ minHeight: "290px" }}>
															<ReactApexChart
																options={ontSNAPoptions}
																series={ontSNAPseries}
																type="bar"
																height={300}
															/>
														</div>
													</Tab.Pane>
												))}
											</Tab.Content>
										)}
									</div>
								</Tab.Container>
							</div>
						</div>

					</div>
				)}
			</div>


		</>
	)
}
export default Home;