import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import MainPagetitle from '../../layouts/MainPagetitle';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import AdminUsersService from '../../api/services/AdminUsersService';
import AdminSchoolService from '../../api/services/AdminSchoolService';
import UserService from '../../api/services/UserService';

import { MultiSelect } from 'react-multi-select-component';
const UserList = () => {
    const [Error, setError] = useState('');
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL + 'User/';
    const [loading, setLoading] = useState(true);
    const [UserList, setUserList] = useState([]);
    const [SchoolList, setSchoolList] = useState([]);
    const [SchoolData, setSchoolData] = useState([]);
    const [ClassdataList, setClassList] = useState([]);
    const [selected, setSelected] = useState([]);

    const [selectevalue, selectedarrayValues] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalData, setTotalData] = useState('');
    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = UserList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(UserList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
    const [Roles, setRoles] = useState([]);
    const [Roles_id, setRolesget] = useState([]);
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState('');
    const [importError, setImportError] = useState('');
    const [userRole, setuserRole] = useState('');
    const getSchoolList = async () => {
        try {
            let responseData = await AdminSchoolService.index().json();
            setSchoolList(responseData.data);

        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];

        const formData = new FormData();
        // formData.append("school_id", SchoolData);
        formData.append("file", file);
        setFile(file)



    };

    const handleimportdata = async (event) => {
        event.preventDefault();
        try {

            if (userRole === "") {
                setImportError("Please fill out all fields to proceed")
            }
            else {
                const formData = new FormData();
                formData.append("userrole", userRole);
                formData.append("school_id", SchoolData);
                formData.append("file", file);
                formData.append("classroom", selectevalue.toString());
                const bearerToken = JSON.parse(localStorage.getItem('usertoken'));
                const responseData = await axios.post(
                    `${process.env.REACT_APP_LARAVEL_IMAGE_URL}api/admin/school/users/import`,
                    formData,
                    {
                        headers: {
                            "content-type": "multipart/form-data",
                            Authorization: `Bearer ${bearerToken}`,
                        },
                    }
                );
                if (responseData.data.status == true) {
                    setLoading(false);
                    swal(responseData.data.message).then((willDelete) => {
                        if (willDelete) {
                            getUserList();
                            setOpen(false);
                            setSelected([])
                            selectedarrayValues([])
                            setImportError('')
                            setuserRole('')
                            setSchoolData([])
                        }
                    })
                }
            }



        } catch (error) {
            setLoading(false);
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }


    }
    const onSchoolChange = (e) => {
        setSchoolData(e.target.value);
        var id = e.target.value

        let getgamelist = SchoolList.length != 0 ? SchoolList.filter(function (item) {
            return item.id == id.toString();
        }) : '';
        setClassList(getgamelist[0].classname)

        setSelected([])
    }

    const options = ClassdataList.map(element => ({
        value: element,
        label: element,
        // disabled: !element.available  
    }));
    const handleSelectChange = (selectedItems) => {

        if (userRole === "student") {
            selectedarrayValues(selectedItems);
        }
        else {
            const selectedValues = selectedItems.map(item => item.value);
            selectedarrayValues(selectedValues);
            setSelected(selectedItems);
        }

    }
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const getRoles = async () => {
        try {
            let responseData = await AdminUsersService.role_index().json();
            setRoles(responseData.data);
        } catch (error) {
            console.log(error);
        }
    };
    const onUserChange = (event) => {
        const selectedRoleId = event.target.value;
        setRolesget(selectedRoleId);
        getUserList(selectedRoleId);
    }
    const getUserList = async (roleId) => {
        try {
            var userData = {
                role_id: roleId
            }
            setLoading(true);
            let responseData = await AdminUsersService.index(userData).json();

            setTotalData(responseData.data.length);
            setUserList(responseData.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    useEffect(() => {
        getRoles();
        getSchoolList();
        if (localStorage.getItem('usertoken')) {
            getUserList();
        }
        else {
            navigate('/');
        }

    }, [])
    const handleDelete = async (e) => {

        try {
            let responseData = await AdminUsersService.destroy(e).json()
            console.log(responseData);
            if (responseData.status === true) {
                swal("User Delete Succesfully").then((willDelete) => {
                    if (willDelete) {
                        getUserList();
                    }
                })

            }

        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }



    const handleStatus = async (userid, element) => {
        try {
            let userData = {
                "is_active": element
            }

            let responseData = await UserService.active_student(userid, userData).json()
            if (responseData.status === true) {
                getUserList();
            }


        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
    return (
        <>
            <MainPagetitle mainTitle="Users" pageTitle={'Users'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <div className="d-flex justify-content-between text-wrap align-items-center gap-3 ">
                                            <div><h4 className="heading mb-0">User List</h4></div>
                                            <div>
                                                <select className="form-control dropdown" id="role_id" name="role_id" onChange={onUserChange} required>
                                                    <option value="">Select role</option> {/* Use value instead of selected */}
                                                    {Roles.map((element, index) =>
                                                        <option key={index} value={element.id}>{element.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>


                                        <div>
                                            <Link to={`/admin/useradd`} className="btn btn-primary btn-sm ms-1">+ Add User</Link> {" "}
                                            {/* changes on 04-07-2024 */}
                                            <button className='btn btn-primary btn-sm ms-1' onClick={() => setOpen(true)}>Import Users</button>
                                            {open && (
                                                <div className="popup">
                                                    <div className="popup-inner">
                                                        <h2>Import Users Data</h2>
                                                        <form onSubmit={handleimportdata}>
                                                            <div className="col-xl-12 mb-3">
                                                                <label htmlFor="val-class_id" className="form-label" >Select User</label>
                                                                <select className="form-control" name="user_id" onChange={(e) => setuserRole(e.target.value)}>
                                                                    <option disabled selected value=''>Select User</option>
                                                                    <option value='teacher'>Teacher</option>
                                                                    <option value='student'>Student</option>
                                                                </select>

                                                            </div>
                                                            <div className="col-xl-12 mb-3">
                                                                <label htmlFor="val-school_id" className="form-label" >Select School</label>
                                                                <select className="form-control" name="school_id" onChange={onSchoolChange}  >
                                                                    <option disabled selected value=''>Select School</option>
                                                                    {SchoolList.map((element, index) =>
                                                                        <option key={index} value={element.id}>{element.name} </option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                            <div className="col-xl-12 mb-3">
                                                                <label htmlFor="val-class_id" className="form-label" >Select class</label>
                                                                {
                                                                    userRole === "student"
                                                                        ?
                                                                        <select className="form-control" name="school_id" value={selectevalue} onChange={(e) => handleSelectChange(e.target.value)} >
                                                                            <option disabled selected value=''>Select class</option>
                                                                            {options.map((element, index) =>
                                                                                <option key={index} value={element.value}>{element.label} </option>
                                                                            )}
                                                                        </select>
                                                                        :
                                                                        <MultiSelect
                                                                            name="class"
                                                                            options={options}
                                                                            value={selected}
                                                                            onChange={handleSelectChange}
                                                                            placeholder="Select Class"

                                                                        />

                                                                }

                                                            </div>

                                                            <div {...getRootProps()} className="dropzone">
                                                                <input {...getInputProps()} />
                                                                <p>Drag 'n' drop a file here, or click to select one</p>
                                                            </div>

                                                            <div style={{ padding: '10px', color: "red" }}><h5 style={{ color: "red" }}>{importError}</h5></div>

                                                            <button className='btn btn-primary btn-sm ms-1 mt-2 ' type="submit">Save</button>
                                                            <button className='btn btn-danger btn-sm ms-1 mt-2 ' onClick={() => {
                                                                setOpen(false)
                                                                setImportError("")
                                                            }}>Close</button>

                                                        </form>
                                                    </div>

                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        {loading ? (
                                            <div class="spinner">
                                                <div class="lds-roller">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>

                                        ) : (
                                            <table id="empoloyees-tblwrapper" className="table sty-hide-rows ItemsCheckboxSec dataTable no-footer mb-0">
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th >
                                                            <strong>No.</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Image</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Roles</strong>
                                                        </th>
                                                        <th>
                                                            <strong>School Name</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Name</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Email</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th> <div className="d-flex"><strong>Action</strong></div></th>
                                                    </tr>
                                                </thead>
                                                {(totalData === 0) ?
                                                    <div style={{ padding: '10px' }}><h5>No Data Found</h5></div>
                                                    :
                                                    <tbody>

                                                        {
                                                            records.map((element, index) => {
                                                                return (
                                                                    <tr style={{ textAlign: 'center' }}>
                                                                        <td>
                                                                            {index + 1}
                                                                        </td>
                                                                        <td>

                                                                            {/* <img className="img-drop-area" height={element.image?50:0} width={element.image?50:0} src={element.image?imageUrl+element.image: "User"} alt='USer'/> */}
                                                                            {element.image == null ? "-" : <img className="img-drop-area" height={element.image ? 50 : 0} width={element.image ? 50 : 0} src={element.image ? imageUrl + element.image : "User"} alt='User' />}

                                                                        </td>
                                                                        <td>
                                                                            {/* {element.roles[0].name} */}
                                                                            {element.roles && element.roles[0] ? element.roles[0].name : '-'}

                                                                        </td>

                                                                        <td  >
                                                                            {/* {element.school && element.school[0] ? element.school[0].name : '-'} */}
                                                                            {element.school ? element.school.name : '-'}

                                                                        </td>
                                                                        <td>
                                                                            {element.firstname} {element.lastname}
                                                                        </td>
                                                                        <td>
                                                                            {element.email}
                                                                        </td>
                                                                        <td>

                                                                            {element.roles && element.roles[0] ? element.roles[0].name === "Student" ?
                                                                                <>
                                                                                    {element.is_active === 0 ?
                                                                                        <Link to="#" className="btn btn-danger btn-sm ms-1"
                                                                                            // onClick={() => handleStatus(element.id, 1)}
                                                                                            onClick={() =>
                                                                                                swal({
                                                                                                    title: "Do you want to active user?",
                                                                                                    icon: "warning",
                                                                                                    buttons: true,
                                                                                                    dangerMode: true,
                                                                                                }).then((willDelete) => {
                                                                                                    if (willDelete) {
                                                                                                        handleStatus(element.id, 1);
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        >Deactive</Link>
                                                                                        : <Link to="#" className="btn btn-primary btn-sm ms-1"
                                                                                            onClick={() =>
                                                                                                swal({
                                                                                                    title: "Do you want to deactive user?",
                                                                                                    icon: "warning",
                                                                                                    buttons: true,
                                                                                                    dangerMode: true,
                                                                                                }).then((willDelete) => {
                                                                                                    if (willDelete) {
                                                                                                        handleStatus(element.id, 0);
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        // onClick={() => handleStatus(element.id, 0)}>Active</Link>
                                                                                        >Active</Link>
                                                                                    }
                                                                                </>

                                                                                :
                                                                                "_" : '-'}

                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <div className="d-flex">
                                                                                <Link

                                                                                    to={`/admin/useredit/${element.id}`}
                                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                                >
                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                </Link>
                                                                                <Link
                                                                                    onClick={() =>
                                                                                        swal({
                                                                                            title: "Are you sure?",
                                                                                            icon: "warning",
                                                                                            buttons: true,
                                                                                            dangerMode: true,
                                                                                        }).then((willDelete) => {
                                                                                            if (willDelete) {
                                                                                                (handleDelete(element.id));
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    className="btn btn-danger shadow btn-xs sharp"
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </Link>
                                                                            </div>
                                                                        </td>


                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                }
                                            </table>
                                        )}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {UserList.length < lastIndex ? UserList.length : lastIndex}
                                                {" "}of {UserList.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default UserList;
