import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';


import AdminModuleService from '../../api/services/AdminModuleService';
import { Editor } from "@tinymce/tinymce-react";

const ModuleAdd = () => {

  const navigate = useNavigate();
  const [file, setFile] = useState()
  const [error, setError] = useState("")
  const onFileChange = (e, file) => {
    if (file) {
      setFile();
      return;
    }
    const fileReader = new FileReader()
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      var image = fileReader.result
      setFile(image)
    }
  }
  // const [RoleList, setRoleList] = useState([]);

  // useEffect(() => {
  //     getRoleList();
  // }, []);

  // const getRoleList = async () => {
  //     try {
  //       let responseData = await AdminModuleService.Role_index().json();  
  //       setRoleList(responseData.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };  

  const onSubmitForm = async (event) => {
    event.preventDefault();
    try {

      var userData = {
        "name": event.target.name.value,
        "subtitle": event.target.subtitle.value,
        "image": file ? file.split(',')[1] : "",
        "description": event.target.description.value,
      }
   
      let responseData = await AdminModuleService.store(userData).json()
      if (responseData.status === true) {

        navigate("/admin/module_index");
      }
    } catch (error) {
      console.log(error)
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }

  };

  return (
    <>
      <MainPagetitle mainTitle="Module" pageTitle={'Module'} parentTitle={'Home'} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Module</h4>
              </div>
              <div className="card-body">
                <form className="form-valide" onSubmit={onSubmitForm}>
                  <div className="row">

                    <div className="col-xl-6 mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Title<span className="text-danger">*</span></label>
                      <input type="text" name="name" className="form-control" id="exampleFormControlInput1" placeholder="" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">SubTitle<span className="text-danger">*</span></label>
                      <input type="text" name="subtitle" className="form-control" id="exampleFormControlInput1" placeholder="" />
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">image<span className="text-danger">*</span></label>
                      {/* <input type="file" name="image"   className="form-control" id="exampleFormControlInput1" placeholder="" /> */}
                      <input type="file" className="form-control" name='image' id="val-image" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />
                    </div>
                    <div className="col-xl-12 mb-3">
                      <label htmlFor="exampleFormControlInput1" className="form-label">Description<span className="text-danger">*</span></label>
                      {/* <textarea type="text" name="description" className="form-control" id="exampleFormControlInput1" placeholder=""></textarea> */}
                      <Editor
                        apiKey="mzqtqwlw84qnnu5kf0a75l26z26pon7ty2tl1lw5b7u5ukf7"
                        textareaName='description'
                        name='description'
                        // initialValue={LessonUpdate.description}
                        init={{
                          height: 200,
                          menubar: false,
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | blocks | " +
                            "bold italic forecolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    </div>
                  </div>

                  <h5 style={{ color: 'red' }}>{error}</h5>
                  <div>
                    <button type="submit" className="btn btn-primary me-1">Submit</button>
                    <Link to={`/admin/module_index`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default ModuleAdd;