import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import MainPagetitle from '../../layouts/MainPagetitle';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import AdminSchoolService from '../../api/services/AdminSchoolService';

const SchoolList = () => {
    const [Error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [SchoolList, setSchoolList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalData, setTotalData] = useState('');
    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = SchoolList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(SchoolList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", file);
        const bearerToken = JSON.parse(localStorage.getItem('usertoken'));
        try {
            const responseData = await axios.post(
                `${process.env.REACT_APP_LARAVEL_IMAGE_URL}api/admin/school/import`,
                formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                        Authorization: `Bearer ${bearerToken}`,
                    },
                }
            );
            if (responseData.data.status == true) {
                swal(responseData.data.message).then((willDelete) => {
                    if (willDelete) {
                        getSchoolList();
                    }
                })
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
        setOpen(false);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });


    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const getSchoolList = async () => {
        try {
            setLoading(true);
            let responseData = await AdminSchoolService.index().json();
            setTotalData(responseData.data.length);
            setSchoolList(responseData.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('usertoken')) {
            getSchoolList();
        }
        else {
            navigate('/');
        }

    }, [])
    const handleDelete = async (e) => {

        try {
            let responseData = await AdminSchoolService.destroy(e).json()
            console.log(responseData);
            if (responseData.status === true) {
                swal("School Delete Succesfully").then((willDelete) => {
                    if (willDelete) {
                        getSchoolList();
                    }
                })

            }

        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
    const handleCallback = () => {
        // Update the name in the component's state
        getSchoolList()

    }
    return (
        <>
            <MainPagetitle mainTitle="Schools" pageTitle={'Schools'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">School List</h4>
                                        <div>
                                            <Link to={`/admin/schooladd`} className="btn btn-primary btn-sm ms-1">+ Add School</Link> {" "}

                                            <button className='btn btn-primary btn-sm ms-1' onClick={() => setOpen(true)}>Import Excel</button>
                                            {open && (
                                                <div className="popup">
                                                    <div className="popup-inner">
                                                        <h2>Upload Excel File</h2>
                                                        <div {...getRootProps()} className="dropzone">
                                                            <input {...getInputProps()} />
                                                            <p>Drag 'n' drop a file here, or click to select one</p>
                                                        </div>
                                                        {/* <form onSubmit={}> */}
                                                        {/* <button className='btn btn-primary btn-sm ms-1 mt-2 ' onClick={() => handleFileUpload()}>Upload</button> */}
                                                        <button className='btn btn-primary btn-sm ms-1 mt-2 ' onClick={() => setOpen(false)}>Close</button>
                                                        {/* </form> */}
                                                    </div>
                                                </div>
                                            )}</div>
                                        {/* {fileData && (
                                                    <div>
                                                    <h3>Uploaded Data:</h3>
                                                    <pre>{JSON.stringify(fileData, null, 2)}</pre>
                                                    </div>
                                                )} */}
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">

                                        {loading ? (
                                            <div class="spinner">
                                                <div class="lds-roller">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>

                                        ) : (
                                            <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th >
                                                            <strong>No.</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Name</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Class</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Brin Number</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Stitching</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Address</strong>
                                                        </th>
                                                        <th>
                                                            <strong>State</strong>
                                                        </th>
                                                        <th>
                                                            <strong>City</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Place</strong>
                                                        </th>

                                                        <th> <div className="d-flex"><strong>Action</strong></div></th>
                                                    </tr>
                                                </thead>
                                                {(totalData === 0) ?
                                                    <div style={{ padding: '10px' }}><h5>No Data Found</h5></div>
                                                    :
                                                    <tbody>

                                                        {
                                                            records.map((element, index) => {

                                                                return (
                                                                    <tr style={{ textAlign: 'center' }}>
                                                                        <td>
                                                                            {index + 1}
                                                                        </td>

                                                                        <td>
                                                                            {element.name}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                element.classname != null ?
                                                                                    element && element.classname.map((element) => {
                                                                                        return (element ? element.replace(/"/g, '') : "_")
                                                                                    }).join(', ')
                                                                                    : ""
                                                                            }
                                                                            {/* {
                                                                        element && element.classname.map((element)=>{
                                                                            return ( element ? element.value :"_")
                                                                        })
                                                                    } */}
                                                                            {/* {element.classname} */}
                                                                        </td>
                                                                        <td>
                                                                            {element.brin_number}
                                                                        </td>
                                                                        <td>
                                                                            {element.stitching}
                                                                        </td>
                                                                        <td>
                                                                            {element.address}
                                                                        </td>
                                                                        <td>
                                                                            {element.state}
                                                                        </td>
                                                                        <td>
                                                                            {element.city}
                                                                        </td>
                                                                        <td>
                                                                            {element.place}
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <div className="d-flex">
                                                                                <Link

                                                                                    to={`/admin/schoolupdate/${element.id}`}
                                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                                >
                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                </Link>
                                                                                <Link
                                                                                    onClick={() =>
                                                                                        swal({
                                                                                            title: "Are you sure?",
                                                                                            icon: "warning",
                                                                                            buttons: true,
                                                                                            dangerMode: true,
                                                                                        }).then((willDelete) => {
                                                                                            if (willDelete) {
                                                                                                (handleDelete(element.id));
                                                                                            }
                                                                                        })
                                                                                    }

                                                                                    className="btn btn-danger shadow btn-xs sharp"
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                }
                                            </table>
                                        )}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {SchoolList.length < lastIndex ? SchoolList.length : lastIndex}
                                                {" "}of {SchoolList.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SchoolList;
