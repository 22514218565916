import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"; 
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminGameService from '../../api/services/AdminGameDomainService';



const GameList = () => {
    const [Error, setError] = useState('');
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL+'Game/';
    // console.log(imageUrl);
    const [loading, setLoading] = useState(true);
    const [GameList, setGameList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalData, setTotalData] = useState('');
    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = GameList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(GameList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate(); 
     function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    } 
    
    const getGameList = async () => { 
        try {  
            setLoading(true); 
            let responseData = await AdminGameService.index().json();
            console.log(responseData.data);
            setTotalData(responseData.data.length);
            setGameList(responseData.data);
            setLoading(false); 
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    useEffect(() => {   
        if (localStorage.getItem('usertoken')) { 
            getGameList();  
        }
        else {
            navigate('/');
        }

    }, [])
    const handleDelete = async (e) => { 
        try {  
            let responseData = await AdminGameService.destroy(e).json()
            console.log(responseData);
            
            if (responseData.status === true) {
                swal("Game Delete Succesfully").then((willDelete) => {
                    if (willDelete) {
                        getGameList();
                    }
                }) 
            } 
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
     
    return (
        <>
    <MainPagetitle mainTitle="Game Domains" pageTitle={'Game Domains'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <div className="d-flex justify-content-between text-wrap align-items-center gap-3 ">
                                            <div><h4 className="heading mb-0">Game Domains List</h4></div> 
                                        </div> 
                                        <div>
                                          {/*  <Link to={`/admin/gameadd`} className="btn btn-primary btn-sm ms-1">+ Add Game</Link> {" "}*/}
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr style={{ textAlign: ' ' }}>
                                                    <th >
                                                        <strong>No.</strong>
                                                    </th>  
                                                 
                                                    <th>
                                                        <strong>Domain Name</strong>
                                                    </th> 
                                                    <th>
                                                        <strong>Game Name</strong>
                                                    </th> 
                                                    <th  style={{textAlign:'left'}}>
                                                        <strong>Level</strong>
                                                    </th> 
                                                    {/* <th> <div className="d-flex"><strong>Action</strong></div></th>*/}
                                                </tr>
                                            </thead>
                                            {(totalData === 0) ?
                                            <div style={{padding:'10px'}}><h5>No Data Found</h5></div>
                                            : 
                                            <tbody>

                                                { records.map((element, index) => { 
                                                         return (
                                                            <tr style={{ textAlign: '' }}>
                                                                <td>
                                                                    {index + 1}
                                                                </td> 
                                                                <td>
                                                                    {element.title}
                                                                </td> 
                                                                <td>
                                                                    {element.game_name}
                                                                </td> 
                                                                <td style={{textAlign:'left'}}>
                                                                {element.levels.map((levels, index) =>
                                                                <div  key={index}>
                                                                    {levels.title} 
                                                                    </div>
                                                                )}
                                                                </td>
                                                               {/* <td style={{ textAlign: '' }}> 
                                                                <div className="d-flex">
                                                                <Link
                                                                    to={`/admin/gameupdate/${element.id}`}
                                                                    className="btn btn-primary shadow btn-xs sharp me-1"
                                                                    >
                                                                    <i className="fas fa-eye"></i>
                                                                    </Link>
                                                                        <Link

                                                                            to={`/admin/gameupdate/${element.id}`}
                                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </Link>
                                                                        <Link
                                                                            onClick={() =>
                                                                                swal({
                                                                                    title: "Are you sure?", 
                                                                                    icon: "warning",
                                                                                    buttons: true,
                                                                                    dangerMode: true,
                                                                                }).then((willDelete) => {
                                                                                    if (willDelete) {
                                                                                        (handleDelete(element.id));
                                                                                    }
                                                                                })
                                                                            }

                                                                            className="btn btn-danger shadow btn-xs sharp"
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </td>*/}
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                            }
                                        </table>
                                         )}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {GameList.length < lastIndex ? GameList.length : lastIndex}
                                                {" "}of {GameList.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default GameList;
