
import clientAuth from "../clientAuth"
export default {
    index: () => clientAuth.get(`gamescore_index`),
    store: (userData) => clientAuth.post('gamescore_add', { json: userData }),
    show: (id) => clientAuth.get(`gamescore_show/${id}`),
    update: (id, userData) => clientAuth.post(`gamescore_update/${id}`, { json: userData }),
    destroy: (id) => clientAuth.delete(`gamescore_delete/${id}`),  
    
    SchoolWithstudentData: () => clientAuth.get(`getstudentwithschool`),
    

};