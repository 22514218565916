import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminUsersService from '../../api/services/AdminUsersService';
import AdminSchoolService from '../../api/services/AdminSchoolService';
import AdminClassService from '../../api/services/AdminClassService';
import AdminLevelService from '../../api/services/AdminLevelService';
import { MultiSelect } from 'react-multi-select-component';
import swal from "sweetalert";

const UserEdit = () => {
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL + 'User/';
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [Roles, setRoles] = useState([]);
    const [UserUpdate, SetUserUpdate] = useState([]);
    const [Userrole, setRolesedit] = useState([]);
    const params = useParams();
    const navigate = useNavigate()
    const [SchoolData, setSchoolData] = useState([]);
    const [SchoolList, setSchoolList] = useState([]);
    const [ClassdataList, setClassList] = useState([]);
    const [ClassroomData, setClassroomData] = useState([]);
    const [LevelsList, setLevelsList] = useState([]);
    const [LevelsData, setLevelsData] = useState('');
    const [selected, setSelected] = useState([]);
    const [selectevalue, selectedarrayValues] = useState([]);
    const [teacherselected, setteacherSelected] = useState([]);
    const [selectteacherevalue, selectedteacherarrayValues] = useState([]);




    const getRoles = async () => {
        try {
            let responseData = await AdminUsersService.role_index().json();
            setRoles(responseData.data);
        } catch (error) {
            console.log(error);
        }
    };


    const GetUserUpdate = async (id) => {
        try {
            setLoading(false);
            let responseData = await AdminUsersService.show(id).json();
            setRolesedit(responseData.data.role_id)
            SetUserUpdate(responseData.data);

            //school list 
            let schoollistData = await AdminSchoolService.index().json();
            setSchoolList(schoollistData.data);
            let similarschool = schoollistData.data.filter(function (item) {
                return item.name == responseData.data.school[0].name;
            });

            setClassList(similarschool[0].classname)


            let getnewclassdata = similarschool[0].classname.length > 0
                ? similarschool[0].classname
                    .filter(item => responseData.data.classroom.includes(item))
                    .map(item => ({ label: item, value: item }))
                : [];

            const selectedValues = getnewclassdata.map(item => item.value);

            selectedarrayValues(selectedValues);
            setSelected(getnewclassdata)
            setLoading(false);
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
    const options = ClassdataList.map(element => ({
        value: element,
        label: element,
        // disabled: !element.available  
    }));

    const onUserChange = (e) => {
        setRolesedit(e.target.value);
    }


    useEffect(() => {
        if (localStorage.getItem('usertoken')) {
            GetUserUpdate(params.id);
            getRoles();
            setRolesedit();

            getLevelsList();
        }
        else {
            navigate('/');
        }

    }, [])
    const onFileChange = (e, file) => {
        if (file) {
            setFile();
            return;
        }
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
            var image = fileReader.result
            setFile(image)
        }
    }


    const onSchoolChange = (e) => {

        var id = e.target.value
        setSchoolData(e.target.value);
        let getgamelist = SchoolList.length != 0 ? SchoolList.filter(function (item) {
            return item.id == id.toString();
        }) : '';

        setClassList(getgamelist[0].classname)
        var getvalue = getgamelist[0].classname != null ? getgamelist[0].classname.map(option => ({ value: option, label: option })) : ""

        setClassroomData(getvalue)
        setSchoolData(e.target.value);
        setSelected([])
    }

    // useEffect(() => {
    //     handleSelectChange(selected)
    // }, [selected])
    const handleSelectChange = (selectedItems) => {
        const selectedValues = selectedItems.map(item => item.value);
        console.log(selectedValues, "selectedValues")
        selectedarrayValues(selectedValues);
        setSelected(selectedItems);
    }
    const onClassroomChange = (e) => {
        // setClassroomData(e.target.value);
    }

    // const getClassList = async () => {
    //     try {
    //         let responseData = await AdminClassService.index().json();
    //         setClassList(responseData.data);
    //     } catch (error) {
    //         console.log(error);
    //         if (error.name === 'HTTPError') {
    //             const errorJson = await error.response.json();
    //             setError(errorJson.message);
    //         }
    //     }
    // }

    const getLevelsList = async () => {
        try {
            let responseData = await AdminLevelService.index().json();
            setLevelsList(responseData.data);
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }

    const onLevelChange = (e) => {
        setLevelsData(e.target.value);
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            var userData = {
                firstname: event.target.firstname.value,
                lastname: event.target.lastname.value,
                email: event.target.email.value,
                image: file ? file.split(',')[1] : "",
                role_id: event.target.role_id.value,
                // school_id: event.target.school_id.value ? event.target.school_id.value : "",
                // continuityscore:event.target.continuityscore.value ? event.target.continuityscore.value :"",
                // currentlevel : event.target.currentlevel.value ? event.target.currentlevel.value  :"",
                // classroom_id : event.target.classroom_id.value ? event.target.classroom_id.value :"",
            }



            if (event.target.role_id.value == 3) {
                // Add properties for role_id 3
                // userData.continuityscore = event.target.continuityscore.value || null;
                // userData.currentlevel = event.target.currentlevel.value || null;
                userData.classroom =  selectevalue.map((element) => {
                    return (element ? element.replace(/"/g, '') : "_")
                }).join(',') || null;
                userData.school_id = event.target.school_id.value || null;
            } else {

                console.log(event.target.classroom);
                // For other role_ids, set properties to null
                // userData.continuityscore = null;
                // userData.currentlevel = null;
                userData.classroom = selectevalue.map((element) => {
                    return (element ? element.replace(/"/g, '') : "_")
                }).join(',')
                userData.school_id = event.target.school_id.value ? event.target.school_id.value : "";

            }

            const data = await AdminUsersService.update(params.id, userData).json();

            if (data.status === true) {
                swal("User Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/user')
                    }
                })
            }
        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }


    }
    return (
        <Fragment>
            <MainPagetitle mainTitle="User" pageTitle={'User'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Edit User</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>

                                    ) : (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-firstname" className="form-label" >First Name<span className="text-danger">*</span></label>
                                                    <input type="text" defaultValue={UserUpdate.firstname} className="form-control" name='firstname' id="val-firstname" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-lastname" className="form-label" >Last Name<span className="text-danger">*</span></label>
                                                    <input type="text" defaultValue={UserUpdate.lastname} className="form-control" name='lastname' id="val-lastname" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-email" className="form-label" >Email<span className="text-danger">*</span></label>
                                                    <input type="text" defaultValue={UserUpdate.email} className="form-control" name='email' id="val-email" placeholder="" />
                                                </div>


                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-image" className="form-label" >Image</label><br></br>
                                                    {/* <input type="file"  defaultValue={UserUpdate.image} className="form-control" name='image' id="val-username" placeholder=""/> */}
                                                    <img className="img-drop-area" height={UserUpdate.image ? 50 : 0} width={50} src={UserUpdate.image ? imageUrl + UserUpdate.image : "Module"} alt='Module' />

                                                    <input type="file" className="form-control" name='image' id="val-image" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />

                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-role_id" className="form-label" >Select User Role<span className="text-danger">*</span></label>
                                                    <select className="form-control" id="role_id" name="role_id" onChange={onUserChange} required >
                                                        <option disabled selected value=''>Select User Role</option>
                                                        {Roles.map((element, index) =>
                                                            <option key={index} value={element.id} selected={element.id === UserUpdate.role_id ? UserUpdate.role_id : '' ? true : ''}>{element.name}</option>
                                                        )}

                                                    </select>
                                                </div>


                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-school_id" className="form-label" >Select School</label>
                                                    <select className="form-control" id="school_id" name="school_id" onChange={onSchoolChange}  >
                                                        <option disabled selected value=''>Select School</option>

                                                        {SchoolList.map((element, index) =>
                                                            <option key={index} value={element.id} selected={element.id === UserUpdate.school_id ? UserUpdate.school_id : '' ? true : ''}>{element.name} </option>
                                                        )}
                                                    </select>
                                                </div>
                                                {Userrole == 3 ? (
                                                    <>
                                                        <div className="col-xl-6 mb-3">
                                                            <label htmlFor="val-school_id" className="form-label" >Select Class</label>
                                                            <select className="form-control" id="classroom_id" name="classroom" onChange={onClassroomChange}  >
                                                                <option disabled selected value=''>Select Class</option>
                                                                {ClassdataList.map((element, index) =>
                                                                    <option key={index} value={element.id} selected={selected}>{element} </option>
                                                                )}
                                                            </select>
                                                        </div>

                                                        {/* <div className="col-xl-6 mb-3">
                                                            <label htmlFor="val-currentlevel" className="form-label" >Select Current Level</label>
                                                            <select className="form-control" id="currentlevel" name="currentlevel" onChange={onLevelChange}  >
                                                                <option disabled value={''}>Select Current Level</option>
                                                                {LevelsList.map((element, index) =>
                                                                    <option key={index} value={element.id} selected={element.id == UserUpdate.currentlevel_id}>
                                                                        {element.title}
                                                                    </option>
                                                                )}

                                                            </select>
                                                        </div>
                                                        <div className="col-xl-6 mb-3">
                                                            <label htmlFor="val-continuityscore" className="form-label" >Continuity Score<span className="text-danger">*</span></label>
                                                            <input type="number" className="form-control" defaultValue={UserUpdate.continuityscore} name='continuityscore' id="val-continuityscore" placeholder="" />
                                                        </div> */}
                                                    </>
                                                ) : <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-school_id" className="form-label" >Select Class</label>
                                                    {/* <MultiSelect
                                                    options={ClassdataList}
                                                    value={ClassroomData}
                                                    onChange={handleSelectChange}
                                                    // onChange={setSelected}
                                                    labelledBy="Select"
                                                    // disabled

                                                /> */}
                                                    <MultiSelect
                                                        name="class"
                                                        options={options}
                                                        value={selected}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select Games"
                                                    />

                                                </div>}
                                            </div>
                                            <h5 style={{ color: 'red' }}>{error}</h5>
                                            <div>
                                                <button type="submit" className="btn btn-primary me-1">Submit</button>
                                                <Link to={`/admin/user`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default UserEdit;
