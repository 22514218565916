import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import FormWizard from "react-form-wizard-component";

import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import AdminModuleService from '../../api/services/AdminModuleService';
import AdminGameService from '../../api/services/AdminGameService';
import AdminLevelService from '../../api/services/AdminLevelService';
import AdminClassService from '../../api/services/AdminClassService';
import AdminSchoolAssignTouserService from '../../api/services/AdminSchoolAssignTouserService';
import swal from "sweetalert";
import { Editor } from "@tinymce/tinymce-react";


const SchoolAssignToUsersAdd = () => {

  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [studentImage, setStudentImage] = useState();
  const [error, setError] = useState("")
  const [GameList, setGameList] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectevalue, selectedarrayValues] = useState([]);
  const [LevelsList, setLevelsList] = useState([]);
  const [LevelsData, setLevelsData] = useState('');
  const [ClassdataList, setClassList] = useState([]);
  const [ClassroomData, setClassroomData] = useState([]);
   // school input
  const [SchoolTabname, setnameTabInput] = useState("");
  const [SchoolTabaddress, setaddressTabInput] = useState("");
  const [SchoolTabstate, setstateTabInput] = useState("");
  const [SchoolTabcity, setcityTabInput] = useState("");
  // teacher input
  const [teacherTabfirstname, setfirstnameTabInput] = useState("");
  const [teacherTablastname, setlastnameTabInput] = useState("");
  const [teacherTabemail, setemailTabInput] = useState(""); 
  const [teacherTabpassword, setpasswordTabInput] = useState(""); 
  const [teacherTabroleid, setTeacherroleidTabInput] = useState(2); 
  const [teacherTabclassid, setclassroomidTabInput] = useState(""); 
   
  // student input
  const [StudentTabfirstname, setstudfirstnameTabInput] = useState("");
  const [StudentTablastname, setstudlastnameTabInput] = useState("");
  const [StudentTablastemail, setstudemailTabInput] = useState("");
  const [StudentTablastcontinuityscore, setstudcontinuityscoreTabInput] = useState("");
  const [StudentTablastpassword, setstudpasswordTabInput] = useState("");
  const [StudentTablastcurrentlevel, setstudcurrentlevelTabInput] = useState("");
  const [StudentTablastroleid, setstudroleidTabInput] = useState(3);
 
  // Form validatoin
  const [schoolValidation, setSchoolValidation] = useState(false);
  const [teacherValidation, setTeacherValidation] = useState(false);
  const [imageValidation, setImageValidation] = useState(true);
  const [studentValidation, setStudentValidation] = useState(false);
  const [studentImageValidation, setStudentImageValidation] = useState(true);
  const [gameIdValidation, setGameIdValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextIndex, setNextIndex] = useState();
   

  const checkValidateTab = () => { 
    if (SchoolTabname === "",SchoolTabaddress === "",SchoolTabstate === "",SchoolTabcity === ""
      ,teacherTabfirstname === "",teacherTablastname === "",teacherTabemail === "" 
    ) {
      return false;
    }
    
    return true;
  };
  // error messages
  const errorMessages = () => { 
    alert("Please fill in the required fields");
  };

  const onFileChange = (e) => { 
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(file);
      setImageValidation(false);
    } else {
      setImageValidation(true);
    }
  };

  const onStudentImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setStudentImage(reader.result);
      };
      reader.readAsDataURL(file);
      setStudentImageValidation(false);
    } else {
      setStudentImageValidation(true);
    }
  };

  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
};
   const handleComplete = () => {
    console.log("Form completed!"); 
  }; 
  const onSubmitForm = async (event) => {
     
     console.log('event',event.target);
    event.preventDefault();
    if(selectevalue.length == 0) {
      setGameIdValidation(true);
      return;
    }
    setLoading(true);
    try {
    
      var userData = {
        "name": SchoolTabname,
        "address": SchoolTabaddress,
        "state": SchoolTabstate,
        "city": SchoolTabcity,

        "firstname": teacherTabfirstname,
        "lastname": teacherTablastname,
        "email": teacherTabemail,
        "password": teacherTabpassword,
        "image": file ? file.split(',')[1] : "",
        "teacher_role_id": teacherTabroleid,
        "classroom_id":teacherTabclassid,
 
        "student_firstname": StudentTabfirstname,
        "student_lastname": StudentTablastname,
        "student_email": StudentTablastemail,
        "student_password": StudentTablastpassword,
        "student_currentlevel": LevelsData,
        "student_continuityscore": StudentTablastcontinuityscore,
        "student_role_id": StudentTablastroleid,
        "student_image": studentImage ? studentImage.split(',')[1] : "",

        "gameid": selectevalue, 
      }
      console.log(userData);
   
      let responseData = await AdminSchoolAssignTouserService.store(userData).json()
      console.log('responseData',responseData);
      if (responseData.status == true) {
        setLoading(false);
        swal("Record Save Succesfully").then((willDelete) => {
          if (willDelete) {
            navigate("/admin/school/assign/user");
          }
      }) 
        
      }
    } catch (error) {
      console.log(error)
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    } 
      
  };

  useEffect(() => {
    getClassList();
    getGameList(); 
    getLevelsList();
    setTeacherroleidTabInput(2);
    setstudroleidTabInput(3);
}, [])
  const getClassList = async () => {
    try {
        let responseData = await AdminClassService.index().json();
        setClassList(responseData.data);
    } catch (error) {
        console.log(error);
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message);
        }
    }
}
  const onClassroomChange = (e) => {
    setClassroomData(e.target.value);
}

// level list
  const getLevelsList = async () => {
    try {
        let responseData = await AdminLevelService.index().json();
        setLevelsList(responseData.data);
    } catch (error) {
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message);
        }
    }
} 
  const onLevelChange = (e) => {
    setLevelsData(e.target.value);
}


// Game list
  const getGameList = async () => { 
    try {  
        let responseData = await AdminGameService.index().json();  
        setGameList(responseData.data);
    } catch (error) {
        console.log(error);
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message);
        }
    }
}
  const handleSelectChange  = (selectedItems) => {    
    const selectedValues = selectedItems.map(item => item.value);
    selectedarrayValues(selectedValues);
    setSelected(selectedItems);
  }
  const options = GameList.map(element => ({
    value: element.id,
    label: element.title, 
}));

 
  const tabChanged = (tabChangeEvent) => {
    const { prevIndex, nextIndex } = tabChangeEvent;
    setNextIndex(nextIndex);
    console.log("prevIndex", prevIndex);
    console.log("nextIndex", nextIndex);
  };
 
  return (
    <>
      <MainPagetitle mainTitle="School with User add" pageTitle={'School with User add'} parentTitle={'Home'} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add School with User</h4>
              </div>
              <div className="card-body">
                <div className='form-box-Wizard'>
                {loading ? (
                  <div class="spinner">
                      <div class="lds-roller">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div> 
                  </div>
                ) : (
                <form className="form-valide" onSubmit={(event)=>onSubmitForm(event)}>
                  <FormWizard
                    shape="circle"
                    color="#e74c3c"
                    onComplete={(event)=>onSubmitForm(event)}
                    onTabChange={tabChanged}
                    nextButtonTemplate={(handleNext) => (
                      <a className="base-button wizard-btn" onClick={() => {
                        if(nextIndex == 1) {
                          if (SchoolTabname === "" || SchoolTabaddress === "" || SchoolTabstate === "" || SchoolTabcity === "") {
                            setSchoolValidation(true);
                          } else {
                            handleNext ();
                          }
                          return;
                        } 
                        if (nextIndex == 2) {
                          if (teacherTabfirstname === "" || teacherTablastname === "" || teacherTabemail === "" || teacherTabpassword === "" || teacherTabclassid === "" || (file == undefined || file == "" || file == null)) {
                            setTeacherValidation(true);
                          } else {
                            handleNext ();
                          }
                          return;
                        }
                        if (nextIndex == 3) {
                          if (StudentTabfirstname === "" || StudentTablastname === "" || StudentTablastemail === "" || StudentTablastpassword === "" || LevelsData === "" || StudentTablastcontinuityscore === "" || (studentImage == undefined || studentImage == "" || studentImage == null)) {
                            setStudentValidation(true);
                          } else {
                            handleNext ();
                          }
                          return;
                        }
                      }}>
                        Next
                      </a>
                    )}
                    finishButtonTemplate={(handleComplete) => (
                      <button className="finish-button wizard-btn" type="submit"  >
                        Record Save
                      </button>
                    )}
                  >
                    <FormWizard.TabContent title="Add School" icon="ti-check">
              
                      {/* <h1>First Tab</h1> */}
                      <div className="row"> 
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >Name<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='name' id="val-username" placeholder=""  value={SchoolTabname}
                              onChange={(e) => setnameTabInput(e.target.value)} />
                              {(schoolValidation && SchoolTabname == "") && <span className="text-danger">name field is required</span>}
                            </div>	
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >Address<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='address' id="val-username" placeholder=""  value={SchoolTabaddress} onChange={(e) => setaddressTabInput(e.target.value)} />
                                {(schoolValidation && SchoolTabaddress == "") && <span className="text-danger">address field is required</span>}
                            </div>	
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >State<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='state' id="val-username" placeholder="" value={SchoolTabstate} onChange={(e) => setstateTabInput(e.target.value)} />
                                {(schoolValidation && SchoolTabstate == "") && <span className="text-danger">state field is required</span>}
                            </div>	
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >City<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='city' id="val-username" placeholder="" value={SchoolTabcity} onChange={(e) => setcityTabInput(e.target.value)} />
                                {(schoolValidation && SchoolTabcity == "") && <span className="text-danger">city field is required</span>}
                            </div>	
                      </div>
                    </FormWizard.TabContent>

                    <FormWizard.TabContent title="Add Teacher" icon="ti-check">
                      {/* <h1>Second Tab</h1> */}
                      
                      <div className="row">
                     
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor="val-firstname" className="form-label" >First Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='firstname' placeholder="" value={teacherTabfirstname} onChange={(e) => setfirstnameTabInput(e.target.value)} />
                                        {(teacherValidation && teacherTabfirstname == "") && <span className="text-danger">firstname field is required</span>}
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor="val-lastname" className="form-label" >Last Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='lastname' placeholder="" value={teacherTablastname} onChange={(e) => setlastnameTabInput(e.target.value)} />
                                        {(teacherValidation && teacherTablastname == "") && <span className="text-danger">lastname field is required</span>}
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor="val-email" className="form-label" >Email<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='email' placeholder="" value={teacherTabemail} onChange={(e) => setemailTabInput(e.target.value)} />
                                        {(teacherValidation && teacherTabemail == "") && <span className="text-danger">email field is required</span>}
                                    </div>
                                    <div className="col-xl-6 mb-3 ">
                                        <div className='d-flex align-items-end'>
                                            <div className='w-100'>
                                                <label htmlFor="val-password" className="form-label" >Password<span className="text-danger">*</span></label>
                                                <input type={passwordVisible ? "text" : "password"} className="form-control" name='password' id="val-password" placeholder=""  onChange={(e) => setpasswordTabInput(e.target.value)}  />
                                                {(teacherValidation && teacherTabpassword == "") && <span className="text-danger">password field is required</span>}
                                            </div>
                                            <div><button
                                                className="btn btn-outline-primary"
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                                style={{marginTop: (teacherValidation && teacherTabpassword == "") ? "-59px" : "" }}
                                            >
                                                <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                                                <span className="visually-hidden">{passwordVisible ? "Hide" : "Show"}</span>
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor="val-image" className="form-label" >Image<span className="text-danger">*</span></label> 
                                        <input type="file" className="form-control" name='image'  accept="image/*"  onChange={(e) => onFileChange(e, file)} />
                                        {(teacherValidation && imageValidation) && <span className="text-danger">image field is required</span>}
                                    </div> 
                                    <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-school_id" className="form-label" >Select Class<span className="text-danger">*</span></label>
                                                    <select className="form-control" name="classroom_id"  onChange={(e) => setclassroomidTabInput(e.target.value)} >
                                                        <option disabled selected value=''>Select Class</option>
                                                        {ClassdataList.map((element, index) =>
                                                            <option key={index} value={element.id}>{element.classname} </option>
                                                        )}
                                                    </select>
                                                    {(teacherValidation && teacherTabclassid == "") && <span className="text-danger">class field is required</span>}
                                                </div>
                                        <input type="hidden" className="form-control" name='teacher_role_id' value={2} onChange={(e) => setTeacherroleidTabInput(e.target.value)}/>
                                    
                                    </div>
                    </FormWizard.TabContent>

                    <FormWizard.TabContent title="Add Student" icon="ti-check">
                      {/* <h1>third Tab</h1>  */}
                      <div className="row">
 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-student_firstname" className="form-label" >First Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='student_firstname' placeholder=""  value={StudentTabfirstname} onChange={(e) => setstudfirstnameTabInput(e.target.value)} />
                                            {(studentValidation && StudentTabfirstname == "") && <span className="text-danger">firstname field is required</span>}
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-student_lastname" className="form-label" >Last Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='student_lastname' placeholder="" value={StudentTablastname} onChange={(e) => setstudlastnameTabInput(e.target.value)} />
                                            {(studentValidation && StudentTablastname == "") && <span className="text-danger">lastname field is required</span>}
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-student_email" className="form-label" >Email<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='student_email' placeholder=""value={StudentTablastemail} onChange={(e) => setstudemailTabInput(e.target.value)} />
                                            {(studentValidation && StudentTablastemail == "") && <span className="text-danger">email field is required</span>}
                                        </div> 
                                        <div className="col-xl-6 mb-3 ">
                                            <div className='d-flex align-items-end'>
                                                <div className='w-100'>
                                                    <label htmlFor="val-student_password" className="form-label" >Password<span className="text-danger">*</span></label>
                                                    <input type={passwordVisible ? "text" : "password"} className="form-control" name='student_password' id="val-student_password" placeholder="" onChange={(e) => setstudpasswordTabInput(e.target.value)} />
                                                    {(studentValidation && StudentTablastpassword == "") && <span className="text-danger">password field is required</span>}
                                                </div>
                                                <div><button
                                                    className="btn btn-outline-primary"
                                                    type="button"
                                                    onClick={togglePasswordVisibility}
                                                    style={{marginTop: (studentValidation && StudentTablastpassword == "") ? "-59px" : "" }}
                                                >
                                                    <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                                                    <span className="visually-hidden">{passwordVisible ? "Hide" : "Show"}</span>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-image" className="form-label" >Image<span className="text-danger">*</span></label>
                                            {/* <input type="file" className="form-control" name='image' id="val-username" placeholder=""/> */}
                                            <input type="file" className="form-control" name='student_image' accept="image/*" onChange={(e) => onStudentImageChange(e, file)} />
                                            {(studentValidation && studentImageValidation) && <span className="text-danger">image field is required</span>}
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-student_currentlevel" className="form-label" >Select Current Level<span className="text-danger">*</span></label>
                                                    <select className="form-control" name="student_currentlevel" onChange={onLevelChange}  >
                                                        <option selected disabled value={''}>Select Current Level</option>
                                                        {LevelsList.map(Level => (
                                                            <option key={Level.id} value={Level.id}>{Level.title}</option>
                                                        ))}
                                                    </select>
                                                    {(studentValidation && LevelsData == "") && <span className="text-danger">current level field is required</span>}
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-student_continuityscore" className="form-label" >Continuity Score<span className="text-danger">*</span></label>
                                                    <input type="number" className="form-control" name='student_continuityscore' id="val-continuityscore" placeholder="" value={StudentTablastcontinuityscore} onChange={(e) => setstudcontinuityscoreTabInput(e.target.value)} />
                                                    {(studentValidation && StudentTablastcontinuityscore == "") && <span className="text-danger">continuity score field is required</span>}
                                                </div>
                                                <input type="hidden" className="form-control" name='student_role_id' value={3} onChange={(e) => setstudroleidTabInput(e.target.value)}/>
                                           
                                    </div>
                    </FormWizard.TabContent>

                    <FormWizard.TabContent title="Add Game" icon="ti-check">
                      {/* <h1>Last Tab</h1> */}

                      <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-game" className="form-label" >Select Game<span className="text-danger">*</span></label>
                                                <MultiSelect
                                                   name="game"
                                                   options={options}
                                                    value={selected}
                                                    onChange={handleSelectChange }
                                                   placeholder="Select Games" 
                                                /> 
                                                {(gameIdValidation) && <span className="text-danger">game field is required</span>}
                                        </div>	                       
                    </FormWizard.TabContent>
                  </FormWizard>
                
                   
                  {/* <h5 style={{ color: 'red' }}>{error}</h5> */}
                  {/* <div>
                    <button type="submit" className="btn btn-primary me-1">Submit</button>
                    <Link to={`/admin/module_index`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>
                  </div> */}
                </form>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default SchoolAssignToUsersAdd;