import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
// import { Multiselect } from "multiselect-react-dropdown";
import { MultiSelect } from "react-multi-select-component";


import AdminSchoolService from '../../api/services/AdminSchoolService';
import AdminGameService from '../../api/services/AdminGameService';


const SchoolAdd = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [GameList, setGameList] = useState([]);
    const [GameData, setState] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectevalue, selectedarrayValues] = useState([]);
    const [fields, setFields] = useState([{ value: '' }]);

    useEffect(() => {
        getGameList();
    }, [])

    const handleSelectChange = (selectedItems) => {
        const selectedValues = selectedItems.map(item => item.value);
        selectedarrayValues(selectedValues);
        setSelected(selectedItems);
    }
    const getGameList = async () => {
        try {
            let responseData = await AdminGameService.index().json();
            setGameList(responseData.data);
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    const options = GameList.map(element => ({
        value: element.id,
        label: element.title,
        // disabled: !element.available  
    }));


    const onSubmitForm = async (event) => {
        event.preventDefault();

        try {

            var userData = {
                "name": event.target.name.value,
                "address": event.target.address.value,
                "state": event.target.state.value,
                "city": event.target.city.value,
                "brin_number": event.target.brin_number.value,
                "place": event.target.place.value,
                "stitching": event.target.stitching.value,
                "game": selectevalue,
                "classname": fields.map((element) => {
                    return (element ? element.value.replace(/"/g, '') : "_")
                }).join(',')
            }

            let responseData = await AdminSchoolService.store(userData).json()
            if (responseData.status === true) {

                navigate("/admin/school");
            }
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    };

    const handleAddField = () => {
        setFields([...fields, { value: '' }]);
    };

    // Handle removing a field
    const handleRemoveField = (index) => {
        const updatedFields = fields.filter((field, i) => i !== index);
        setFields(updatedFields);
    };
    const handleChange = (index, event) => {
        const updatedFields = [...fields];
        updatedFields[index].value = event.target.value;
        setFields(updatedFields);
    };


    return (
        <>
            <MainPagetitle mainTitle="School" pageTitle={'School'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Add School</h4>
                            </div>
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row">

                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='name' id="val-username" placeholder="" />
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Brin Number<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='brin_number' id="val-username" placeholder="" />
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Stitching<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='stitching' id="val-username" placeholder="" />
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Place<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='place' id="val-username" placeholder="" />
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Address<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='address' id="val-username" placeholder="" />
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >State<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='state' id="val-username" placeholder="" />
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >City<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='city' id="val-username" placeholder="" />
                                        </div>
                                        {<div className="col-xl-6 mb-3">
                                            <label htmlFor="val-game" className="form-label" >Select Game<span className="text-danger">*</span></label>
                                            <MultiSelect
                                                name="game"
                                                options={options}
                                                value={selected}
                                                onChange={handleSelectChange}
                                                placeholder="Select Games"
                                            />
                                        </div>}
                                        <div>
                                            <label htmlFor="val-game" className="form-label" >Add Class<span className="text-danger">*</span></label>
                                            <div className="d-flex gap-3 flex-wrap">
                                                {fields.map((field, index) => (
                                                    <div key={index} style={{ marginBottom: '10px' }} className='d-flex gap-3'>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={field.value}
                                                            onChange={(e) => handleChange(index, e)}
                                                            required
                                                        // placeholder={`Class ${index + 1}`}
                                                        />
                                                        {index > 0 && (
                                                            <input type='button' className="btn btn-danger me-1" value={'Remove'} onClick={() => handleRemoveField(index)} />

                                                            // </input>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            <input type='button' className="btn btn-primary me-1" onClick={handleAddField} value={'Add Class'} />
                                        </div>

                                    </div>
                                    <div className="col-xl-6 mb-3"></div>
                                    <h5 style={{ color: 'red' }}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <Link to={`/admin/school`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default SchoolAdd;