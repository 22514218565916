import React, { useState,useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom'; 
import MainPagetitle from '../../layouts/MainPagetitle';
import { MultiSelect } from "react-multi-select-component"; 

import AdminClassService from '../../api/services/AdminClassService';
import AdminSubjectService from '../../api/services/AdminSubjectService';
import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";
                    
const Classesadd = () => {   
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [SubjectdataList, setSubjectList] = useState([]); 
    const [selected, setSelected] = useState([]);
    const [selectevalue, selectedarrayValues] = useState([]);
     
     
    // subject list
    
    const getSubjectList = async () => { 
      try { 
          let responseData = await AdminSubjectService.index().json();  
          setSubjectList(responseData.data);
      } catch (error) { 
          console.log(error);               
          if (error.name === 'HTTPError') {
              const errorJson = await error.response.json();  
              setError(errorJson.message);
          }
      }
  }
      const onSubmitForm = async (event) => {
        event.preventDefault();
        try {
          var userData = {
            "classname": event.target.classname.value,  
            "subject_id":  selectevalue
             
          }
            let responseData = await AdminClassService.store(userData).json()
            if (responseData.status === true) { 
              navigate("/admin/class");
            }
            if (responseData.status === false) { 
                swal("class name already exists").then(( ) => { 
                }) 
              }

          } catch (error) {  
            if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
          }
       
          
    };
    const options = SubjectdataList.map(element => ({
        value: element.id,
        label: element.name,
        // disabled: !element.available  
    }));
    const handleSelectChange  = (selectedItems) => {    
      const selectedValues = selectedItems.map(item => item.value);
      selectedarrayValues(selectedValues);
      setSelected(selectedItems);
    }
    useEffect(() => {
      if (localStorage.getItem('usertoken')) { 
            getSubjectList(); 
        }
        else {
            navigate('/');
        }

    }, [])
   
    return(
        <> 
        
        <MainPagetitle mainTitle="Class" pageTitle={'Class'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
			    	<div className="col-xl-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Add Class</h4>
                            </div>           
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row"> 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Class Name<span className="text-danger">*</span></label>
                                            <input type="text" name="classname" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                        </div>    
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-subject_id" className="form-label" >Select Subject<span className="text-danger">*</span></label>
                                                <MultiSelect
                                                   name="subject_id"
                                                   options={options}
                                                    value={selected}
                                                    onChange={handleSelectChange }
                                                   placeholder="Select Subject" 
                                                /> 
                                        </div>	  
                                        </div> 
                                    <h5 style={{color:'red'}}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <Link  to={`/admin/class`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                     </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};
export default Classesadd;