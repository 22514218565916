import React, { useContext, useState } from "react";
import { useDispatch , useSelector } from 'react-redux';

import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { navtoggle } from "../../../store/actions/AuthActions";
import logoadmin from '../../../images/logo/Logo-vr-onderwijs-white.png';  
import miniadminlogo from '../../../images/logo/Logo-mini.png';  


const NavHader = () => {
  const [toggle, setToggle] = useState(false); 
    const { navigationHader, openMenuToggle, background } = useContext(
      ThemeContext
    );
    const dispatch = useDispatch();
    const sideMenu = useSelector(state => state.sideMenu);
    const handleToogle = () => {
      dispatch(navtoggle());
      setToggle(!toggle);
    };
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo"> 
        {/* <h3 className="text-white text-center">GAME &nbsp; LMS</h3> */}  
        {toggle ? ( 
          <img src={miniadminlogo} class="logo" alt="Logo" width={40} height={40} className="m-auto"/>
        ) : ( 
          <img src={logoadmin} class="logo" alt="Logo" width={150} height={40} className="m-auto"/>   
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {              
          handleToogle()
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>          
        </div>
      </div>
    </div>
  );
};

export default NavHader;
