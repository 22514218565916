import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
 
import AdminGameService from '../../api/services/AdminGameService'; 
import AdminLevelService from '../../api/services/AdminLevelService';

import swal from "sweetalert";

const GameUpdate = () => {
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL+'Game/';
    const [loading, setLoading] = useState(true);

    const [file, setFile] = useState()
    const [Error, setError] = useState('');
    const [GameUpdate, SetGameUpdate] = useState([]); 
    const params = useParams();
    const navigate = useNavigate()  
    const [LevelsData, setLevelsData] = useState('');
    const [LevelsList, setLevelsList] = useState([]);
      
    const onFileChange = (e,file) => {
        if(file)
        {
          setFile();
          return;
        }
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
          var image = fileReader.result
          setFile(image)
        }
      } 

    const GetGameUpdate = async (id) => {
        try {
            setLoading(true); 
            let responseData = await AdminGameService.show(id).json()  
            console.log(responseData);
            SetGameUpdate(responseData.data);   
            setLoading(false);      
        } catch (error) {     
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json(); 
                setError(errorJson.message)
            }
        }
    }
    const getLevelsList = async () => { 
        try { 
            let responseData = await AdminLevelService.index().json();  
            setLevelsList(responseData.data);
        } catch (error) {               
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();  
                setError(errorJson.message);
            }
        }
    }
  
      const onLevelChange = (e) => { 
        setLevelsData(e.target.value);
      }

    useEffect(() => { 
        getLevelsList();
        if (localStorage.getItem('usertoken')) {  
            GetGameUpdate(params.id); 
        }
        else {
            navigate('/');
        }

    }, [])
     
    const handleSubmit = async (event) => {
        event.preventDefault(); 
        try {
            var userData = {
                title: event.target.title.value,
                image: file ? file.split(',')[1] : "",
                passing_score: event.target.passing_score.value,
                totalscore: event.target.totalscore.value,
                level_id: event.target.level_id.value,
            }
            const data = await AdminGameService.update(params.id, userData).json(); 
            if (data.status === true) { 
                swal("Game Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/game')
                    }
                })

            }


        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json(); 
                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }
    return (
        <Fragment>
<MainPagetitle mainTitle="Game" pageTitle={'Game'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Edit Game</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                {loading ? (
                                    <div class="spinner">
                                        <div class="lds-roller">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div> 
                                    </div> 
                                ) : (
                                    <form onSubmit={handleSubmit}> 
                                        <div className="row"> 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-image" className="form-label" >Image<span className="text-danger">*</span></label><br></br>
                                            <img className="img-drop-area" height={GameUpdate.image ? 100 : 0} width={GameUpdate.image ? 100 : 0} src={GameUpdate.image ? imageUrl + GameUpdate.image : "Game"} alt='Game'/>
                                            <p></p>
                                            <input type="file" className="form-control" name='image' id="val-image" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e,file)} />
                                        </div>	
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Title<span className="text-danger">*</span></label>
                                                <input type="text" name="title" defaultValue={GameUpdate.title} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                            </div>   
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-passing_score" className="form-label" >Passing Score<span className="text-danger">*</span></label>
                                                <input type="number" className="form-control" defaultValue={GameUpdate.passing_score} name='passing_score' id="val-passing_score" placeholder=""/>
                                            </div>	
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-totalscore" className="form-label" >Total Score<span className="text-danger">*</span></label>
                                                <input type="number" className="form-control" defaultValue={GameUpdate.totalscore} name='totalscore' id="val-passing_score" placeholder=""/>
                                            </div>	 
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-level_id" className="form-label" >Select Level<span className="text-danger">*</span></label>
                                                <select className="form-control" id="level_id" name="level_id" onChange={onLevelChange}  > 
                                                <option selected disabled value={''}>Select Level</option> 
                                                {LevelsList.map(Level => (
                                                        <option key={Level.id} value={Level.id} selected={Level.id === GameUpdate.level_id ? GameUpdate.level_id : '' ? true : ''}>{Level.title}</option>
                                                    ))}
                                                </select>
                                             </div>	    

                                        </div>
                                        <h5 style={{color:'red'}}>{Error}</h5>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button>
                                            <Link  to={`/admin/game`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                         </div>
                                         
                                    </form>
                                   )}
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </Fragment>
    );
};

export default GameUpdate;
