import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
 
import AdminSubjectService from '../../api/services/AdminSubjectService';
import { Editor } from "@tinymce/tinymce-react";

import swal from "sweetalert";

const SubjectsEdit = () => {
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL+'Subject/'; 
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState()
    const [Error, setError] = useState('');
    const [SubjectUpdate, SetSubjectUpdate] = useState([]);
    const params = useParams();
    const navigate = useNavigate()   
     
    const GetSubjectUpdate = async (id) => {
        try {
            setLoading(true);
            let responseData = await AdminSubjectService.show(id).json() 
            SetSubjectUpdate(responseData.data); 
            setLoading(false); 
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json(); 
                setError(errorJson.message)
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('usertoken')) { 
            GetSubjectUpdate(params.id);
        }
        else {
            navigate('/');
        }

    }, [])
    const onFileChange = (e,file) => {
        if(file)
        {
          setFile();
          return;
        }
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
            var image = fileReader.result
            setFile(image)
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            var userData = {
                name: event.target.name.value,  
                icon: file ? file.split(',')[1] : "", 
            }
            const data = await AdminSubjectService.update(params.id, userData).json();

            if (data.status === true) {

                swal("Subject Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/subject')
                    }
                })

            }


        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }
    return (
        <Fragment>
            <MainPagetitle mainTitle="Subjects" pageTitle={'Subjects'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Edit Subject</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                        <div className=" mb-3  "> 
                                                <img className="img-drop-area" height={SubjectUpdate.icon ? 20 : 0} width={SubjectUpdate.icon ? 20 : 0} src={SubjectUpdate.icon ? imageUrl + SubjectUpdate.icon : "Subject"} alt='Subject'/>
                                             </div>
                                        <div className="col-xl-6 mb-3"> 
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Icon </label><br></br>
                                                <input type="file" className="form-control" name='icon' id="val-icon" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e,file)} />
                                             </div>
                                            
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Title<span className="text-danger">*</span></label>
                                                <input type="text" name="name" defaultValue={SubjectUpdate.name} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                            </div>  
                                              
                                            <p className='text-danger fs-12'>{Error}</p>

                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button> 
                                            <Link  to={`/admin/subject`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                        </div>
                                    </form>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default SubjectsEdit;
