
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';



export function signupAction(email, password, navigate) {
	
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                //history,
            );
            dispatch(confirmedSignupAction(response.data));
            navigate('/dashboard');
			//history.push('/dashboard');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function Logout(navigate) {
	localStorage.removeItem('userDetails');
	localStorage.removeItem('usertoken');
    navigate('/');
	//history.push('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password,device_name ,navigate) {
    return  (dispatch) => {
         login(email, password,device_name)
            .then((response) => { 
                console.log(response)
                var data={
                    // "email": "demo@example.com",
                    "email": response.data.user.email,
                    "idToken": response.data.idToken,
                    // "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImQ0OWU0N2ZiZGQ0ZWUyNDE0Nzk2ZDhlMDhjZWY2YjU1ZDA3MDRlNGQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vcmVhY3QtY291cnNlLWI3OThlIiwiYXVkIjoicmVhY3QtY291cnNlLWI3OThlIiwiYXV0aF90aW1lIjoxNjk5NTk3NDE4LCJ1c2VyX2lkIjoicW10NmRSeWlwSWFkOFVDYzBRcE1WMk1FTlN5MSIsInN1YiI6InFtdDZkUnlpcElhZDhVQ2MwUXBNVjJNRU5TeTEiLCJpYXQiOjE2OTk1OTc0MTgsImV4cCI6MTY5OTYwMTAxOCwiZW1haWwiOiJkZW1vQGV4YW1wbGUuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImRlbW9AZXhhbXBsZS5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwYXNzd29yZCJ9fQ.EOv-1-VaO05DOjf-nv06j0jfakBQ4B6X0tOr6Ll1hJAtTajOPWaAmmkq1JJc1E_ka9meiJ0WnWnPnC_to4AKfJNM36xyUQBT_n07tj1HuVKEvgBZOx854f71Vcfema0wvBN1uDgVMWtibn4EOSFm-SKv2DiRZI60_aEl508q7oppPNSGolj4-TJebnyVXqdab-o8z3xFj6pQd14-V8r2OpQdkF8VaNT0PeMsnhuZo2OyjYaujP8WufymSN_KtmCGV6WNNS0IVA5WzsQGNuer5LllzKu9rB_hIl-pVDvDOa46CP8DZICOSnPN8tWeV9favOLLcjvzJynaV714D3tQlA",
                    "expiresIn": 3600,
                    "expireDate":'2024-05-10T20:20:48.031Z'

                }
                saveTokenInLocalStorage(data);
                runLogoutTimer(
                    dispatch,
                    data.expiresIn * 5000000,
                    navigate,
                );
               dispatch(loginConfirmedAction(data));	              
                navigate('/dashboard');                
            })
            .catch((error) => {
                
                const errorMessage = formatError(error.response.data);
                console.log(errorMessage)
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {
    return {        
      type: 'NAVTOGGLE',
    };
};