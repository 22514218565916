import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

import { Modal, Button, Collapse } from "react-bootstrap"
import AdminUserService from '../api/services/AdminUserService'
	;
import bg6 from '../../images/background/bg6.jpg';
import logoadmin from '../../images/logo/Logo-vr-onderwijs_dark.png';
import swal from "sweetalert";
function Login(props) {
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	const [email, setEmail] = useState();
	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [error, setError] = useState('');
	const [password, setPassword] = useState();
	const [counter, setCounter] = useState(30);
	const [OTP, setOTP] = useState(new Array(4).fill(""));
	const [username, setusername] = useState('');
	const [userid, setuserid] = useState('');

	const [isResendEnabled, setIsResendEnabled] = useState(false);
	useEffect(() => {
		if (counter === 0) {
			setIsResendEnabled(true);
			return;
		}

		const timerId = setInterval(() => {
			setCounter(prevCountdown => prevCountdown - 1);
		}, 1000);

		return () => clearInterval(timerId);
	}, [counter]);
	const onLogin = async (e) => {

		e.preventDefault();

		try {
			const data = await AdminUserService.login(email, password).json();


			if (data.status === true) {

				setusername(data.message)
				setuserid(data.userid)
				setShow(true);
				setError('')
			}

			// navigate('/dashboard')

		} catch (error) {
			setErrors(error)
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				setError(errorJson.message)
			} else {
				setError("The provided credentials are incorrect.")
			}
		}
		// }
	}
	const handleClose = () => {
		setShow(false);

	};

	var d = new Date();
	function handleChange(e, index) {
		const value = e.target.value;

		// Allow only digits and ensure the input is not more than 1 character
		if (!/^\d*$/.test(value) || value.length > 1) return;

		// Update the OTP state
		const newOTP = [...OTP.map((data, indx) => (indx === index ? value : data))];
		setOTP(newOTP);
		// setOTP([...OTP.map((data, indx) => (indx === index ? e.target.value : data))]);
		if (value && index < OTP.length - 1) {
			document.getElementById(`otp-input-${index + 1}`).focus();
		}
	}
	const handleKeyDown = (e, index) => {
		// Focus the previous input if Backspace is pressed and the current input is empty
		if (e.key === 'Backspace' && !OTP[index] && index > 0) {
			document.getElementById(`otp-input-${index - 1}`).focus();
		}
	};
	const verifyOTP = async () => {
		try {

			let userdata = {
				"userid": userid,
				"otp": OTP.join("")
			}
			const data = await AdminUserService.verifyotp(userdata).json();
			if (data.status === true) {
				localStorage.setItem("user", JSON.stringify(data.user));

				localStorage.setItem("usertoken", JSON.stringify(data.token));
				swal(data.message).then((willDelete) => {
					if (willDelete) {
						navigate('/dashboard')
					}
				})
			}
			else {


				swal(data.message).then((willDelete) => {
					if (willDelete) {
						// setShow(false);
					}
				})
			}







		} catch (error) {
			setErrors(error)
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				setError(errorJson.message)
			} else {
				setError("The provided credentials are incorrect.")
			}
		}

	}
	const resendOTP = async () => {
		setCounter(30);
		setIsResendEnabled(false);
		try {

			let userdata = {
				"userid": userid,

			}
			const data = await AdminUserService.resendotp(userdata).json();
			if (data.status === true) {

				swal(data.message).then((willDelete) => {
					if (willDelete) {

					}
				})
			}
			else {


				swal(data.message).then((willDelete) => {
					if (willDelete) {
						// setShow(false);
					}
				})
			}





		} catch (error) {
			setErrors(error)
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				setError(errorJson.message)
			} else {
				setError("The provided credentials are incorrect.")
			}
		}

	}

	return (
		<>
			<div className="page-wraper">
				<div className="browse-job login-style3">
					<div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh" }}>
						<div className="row gx-0">
							<div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
								<div id="mCSB_1" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style={{ maxHeight: "653px" }}>
									<div id="mCSB_1_container" className="mCSB_container" style={{ position: "relative", top: "0", left: "0", dir: "ltr" }}>
										<div className="login-form style-2">
											<div className="card-body">
												<div className="logo-header m-0">
													<Link to={"#"} className="logo"><img src={logoadmin} alt="" className="m-auto w-75 light-logo" /></Link>
												</div>
												<h3 className='text-center'>Admin</h3>
												<div className="nav nav-tabs border-bottom-0" >
													<div className="tab-content w-100" id="nav-tabContent">
														<div className="tab-pane fade active show" id="nav-personal">





															<form className=" dz-form " onSubmit={onLogin}>

																<div className="dz-separator-outer m-b5">
																	<div className="dz-separator bg-primary style-liner"></div>
																</div>
																<p>Enter your e-mail address and your password. </p>
																<div className="form-group mb-3">

																	<input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
																	{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
																</div>
																<div className="form-group mb-3">

																	<input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
																	{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
																</div>
																{error && (
																	<div className='text-red border fs-18  text-center border-danger p-2 my-2 rounded'>
																		{error}
																	</div>
																)}
																<div className="form-group text-left  ">
																	<button type="submit" className="btn btn-primary dz-xs-flex m-r5">login</button>
																	<span className="form-check d-inline-block ms-2">
																		<input type="checkbox" className="form-check-input" id="check1" name="example1" />
																		<label className="form-check-label" htmlFor="check1">Remember me</label>
																	</span>
																</div>


															</form>

														</div>

													</div>
												</div>
											</div>
											<div className="card-footer">
												<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
													<div className="col-lg-12 text-center">
														<p>{d.getFullYear()} ©
															{" "}Developed by{" "} <br></br>
															<a href="https://rigicglobalsolutions.com/" target="_blank" rel="noreferrer">
																Rigic Global Solutions Pvt Ltd
															</a>{" "}

														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose} backdrop="static">


				<Modal.Body>
					<div class="modal-container otp-popup">
						<h1>OTP Verification</h1>
						<p>{username}</p>
						<div class="otp-input">

							{OTP.map((digit, index) => (
								<input
									key={index}
									id={`otp-input-${index}`} // Unique ID for each input
									type="text"
									value={digit}
									onChange={(e) => handleChange(e, index)}
									onKeyDown={(e) => handleKeyDown(e, index)}
									maxLength={1}

								/>
							))}


						</div>
						<button onClick={() => verifyOTP()} className='otp-button btn btn-primary dz-xs-flex m-r5'>Verify</button>
						<div className="resend-text">
							Didn't receive the code?  <button
								onClick={resendOTP}
								disabled={!isResendEnabled}
							>
								{isResendEnabled ? 'Resend OTP' : `Resend OTP in ${counter}s`}
							</button>

							{/* {isResendEnabled ?
								<span id="timer"> {"(" + counter + ")"}</span>
								:
								<>
									<span className="resend-link" onClick={() => resendOTP()}>Resend Code</span>
									<span id="timer"> {"(" + counter + ")"}</span>
								</>
							} */}

						</div>
					</div>
				</Modal.Body>


			</Modal>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);