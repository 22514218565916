import { SVGICON,IMAGES } from "../../constant/theme";

export const MenuList = [
   
    {
        title: 'Dashboard',
        classsChange: 'mm-collapse',
        to: '/dashboard',
        iconStyle: SVGICON.Home,    
    },
    {
        title: 'Games', 
        iconStyle: SVGICON.List,
        to: '/admin/game',
    },
    {
        title: 'Levels', 
        iconStyle: SVGICON.List,
        to: '/admin/level',
    },
    {
        title: 'Game Domains', 
        iconStyle: SVGICON.List,
        to: '/admin/game_domain',
    },
    {
        title: 'Schools', 
        iconStyle: SVGICON.List,
        to: '/admin/school',
    },
    {
        title: 'Users', 
        iconStyle: SVGICON.List,
        to: '/admin/user',
    }, 

    {
        title: 'Teachers', 
        iconStyle: SVGICON.List,
        to: '/admin/Teachers',
    },
    {
        title: 'Students', 
        iconStyle: SVGICON.List,
        to: '/admin/Students',
    },
    {
        title: 'Student Zero Tests',
        iconStyle: SVGICON.List,
        to: '/admin/users/zero_test',
    }, 
    {
        title: 'Student Games List',
        iconStyle: SVGICON.List,
        to: '/admin/users/game/data',
    },  
   
    // {
    //     title: 'Subjects', 
    //     iconStyle: SVGICON.List,
    //     to: '/admin/subject',
    // },
    // {
    //     title: 'Classes', 
    //     iconStyle: SVGICON.List,
    //     to: '/admin/class',
    // },
   
    {
        title: 'Modules',
        //iconStyle:"fa-brands fa-servicestack" ,
        iconStyle: SVGICON.List,
        to: '/admin/module_index',
    },
    // {
    //     title: 'Module Categories',
    //     iconStyle: SVGICON.List,
    //     to: '/admin/task_index',
    // },  
    {
        title: 'Lessons',
        iconStyle: SVGICON.List,
        to: '/admin/Lesson',
    },  
    
    // {
    //     title: 'Student Game Scores',  
    //     iconStyle: SVGICON.List,
    //     to: '/admin/student_game_score',
    // },

    // {   title: 'School with users', 
    //     iconStyle: SVGICON.List,
    //     to: '/admin/school/assign/user',
    // },
   
    

]