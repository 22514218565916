import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
 
import AdminTaskService from '../../api/services/AdminTaskService';
import AdminModuleService from '../../api/services/AdminModuleService';


import swal from "sweetalert";

const TaskShowUpdate = () => {
    const [file, setFile] = useState()
    const [Error, setError] = useState('');
    const [TaskUpdate, SetTaskUpdate] = useState([]);
    const [TaskmoduleidUpdate, SetforTaskUpdate] = useState([]);
    const params = useParams();
    const navigate = useNavigate()  
    const [module, setModule] = useState([]);
    const [moduleData, setmoduleData] = useState([]); 
    const records = module;

    const GetTaskUpdate = async (id) => {
        try {
            let responseData = await AdminTaskService.show(id).json() 
            
            SetTaskUpdate(responseData.data);  
            SetforTaskUpdate(responseData.data.module_id['0'].id); 

        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json(); 
                setError(errorJson.message)
            }
        }
    }
    const getModule = async () => { 
        try { 
            let responseData = await AdminModuleService.index().json();    
            setModule(responseData.data);
        } catch (error) { 
            console.log(error);
        }  
      };
    const onModuleChange = (e) => { 
        setmoduleData(e.target.value);
      }
     

    useEffect(() => { 
        if (localStorage.getItem('usertoken')) { 
            GetTaskUpdate(params.id);
            getModule(); 
        }
        else {
            navigate('/');
        }

    }, [])
     
    const handleSubmit = async (event) => {
        event.preventDefault(); 
        try {
            var userData = {
                name_task: event.target.name_task.value,
                module_id: event.target.module_id.value,
            }
            const data = await AdminTaskService.update(params.id, userData).json();

            if (data.status === true) {

                swal("Task Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/task_index')
                    }
                })

            }


        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }
    return (
        <Fragment>
<MainPagetitle mainTitle="Category edit" pageTitle={'Category edit'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Edit Category</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Select Module<span className="text-danger">*</span></label>
                                        <select className="form-control" id="module_id" name="module_id" onChange={onModuleChange}  >
                                        {module.map((element, index) =>
                                            <option key={index} value={element.id} selected={element.id === TaskUpdate.module_id ? TaskUpdate.module_id : '' ? true : ''}
                                            > {element.name} </option>
                                            )}
  
                                        </select>
                                        </div>	
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Title<span className="text-danger">*</span></label>
                                                <input type="text" name="name_task" defaultValue={TaskUpdate.name_task} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                            </div>   
                                        </div>
                                        <h5 style={{color:'red'}}>{Error}</h5>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button>
                                             <Link  to={`/admin/task_index`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default TaskShowUpdate;
