import React, { useState,useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom'; 
import MainPagetitle from '../../layouts/MainPagetitle';


import AdminSubjectService from '../../api/services/AdminSubjectService';
import swal from "sweetalert";
                    
const Subjectsadd = () => {   
    
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")  
    const onFileChange = (e,file) => {
      if(file)
      {
        setFile();
        return;
      }
      const fileReader = new FileReader()
      fileReader.readAsDataURL(e.target.files[0])
      fileReader.onload = () => {
        var image = fileReader.result
        setFile(image)
      }
    }
     
      const onSubmitForm = async (event) => {
        event.preventDefault();
        try {
          var userData = {
            "name": event.target.name.value,  
            "icon": file ? file.split(',')[1] : "",  
          }
            let responseData = await AdminSubjectService.store(userData).json()
            if (responseData.status === true) {
              if (responseData.status === true) { 
                swal("Subject Create Succesfully").then((willDelete) => {
                    if (willDelete) {
                      navigate("/admin/subject");
                    }
                }) 
            }  
              
            }
          } catch (error) {
            console.log(error)
            if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
          }
       
    };
   
    return(
        <> 
        <MainPagetitle mainTitle="Subjects" pageTitle={'Subjects'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
			    	<div className="col-xl-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Add Subject</h4>
                            </div>           
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row">
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Icon<span className="text-danger">*</span></label>
                                                <input type="file" className="form-control" name='icon' id="val-icon" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e,file)} />
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Name<span className="text-danger">*</span></label>
                                            <input type="text" name="name" className="form-control" id="exampleFormControlInput1" placeholder="" />
                                        </div>   
                                    </div> 
                                    <h5 style={{color:'red'}}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <Link  to={`/admin/subject`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link> 
                                     </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};
export default Subjectsadd;