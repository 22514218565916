import React, { Fragment, useState, useEffect,useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
 
import AdminLessonService from '../../api/services/AdminLessonService'; 
import AdminModuleService from '../../api/services/AdminModuleService';
import { Editor } from "@tinymce/tinymce-react";

import swal from "sweetalert";

const ModuleShowUpdate = () => {
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState()
    const [Error, setError] = useState('');
    const [LessonUpdate, SetLessonUpdate] = useState([]);
    const params = useParams();
    const navigate = useNavigate()    
    const [ModulesData, setModules] = useState([]);
    const [selectedModuleId, setSelectedModuleId] = useState('');
    // const [selectedModuleTasks, setSelectedModuleTasks] = useState([]);
    // const [selectedCategory, setSelecteCategory] = useState([]);
    const mod_id = LessonUpdate.module_id;
    // console.log(LessonUpdate.module_id);
    useEffect(() => {
        fetchModuleData();
        if (localStorage.getItem('usertoken')) { 
            GetLessoneUpdate(params.id); 
        }
        else {
            navigate('/');
        }

    }, [])
        
  
    const fetchModuleData  = async () => {
        try {
            setLoading(true);
            let responseData = await AdminModuleService.index().json();  
            setModules(responseData.data);
            setLoading(false); 
        } catch (error) {
            console.log(error);
        } 
    };   

    const handleModuleChange = (e) => {  
        const moduleId = e.target.value;
        setSelectedModuleId(moduleId); 
    
        // let selectedModule = null;
        // for (let i = 0; i < ModulesData.length; i++) { 
        //     if (ModulesData[i].id == moduleId) {
        //         selectedModule = ModulesData[i]; 
        //         break;  
        //     }
        // } 
        // if (selectedModule) {
        //     setSelectedModuleTasks(selectedModule.tasks);
        // } else {
        //     setSelectedModuleTasks([]);  
        // }
    };
    // const handleCategoryChange = (e) => {   
    //     setSelecteCategory(e.target.value);  
    // };
    
   
    const GetLessoneUpdate = async (id) => {
        try {
            let responseData = await AdminLessonService.show(id).json()   
            SetLessonUpdate(responseData.data); 
            // let responseDatadata = await AdminModuleService.index().json();  
            // setModules(responseDatadata.data);             
            // setSelectedModuleId(responseData.data.module_id); 
            // let selectedModule = null;     
            //  for (let i = 0; i < responseDatadata.data.length; i++) {  
            //     if (responseDatadata.data[i].id == responseData.data.module_id) {
            //         selectedModule = responseDatadata.data[i]; 
            //         console.log(selectedModule);   
            //         break;    
            //     }
            // } 
            
            // if (selectedModule) {
            //     setSelectedModuleTasks(selectedModule.tasks);
            // } else {
            //     setSelectedModuleTasks([]);  
            // }
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json(); 
                setError(errorJson.message)
            }
        }
    }
   
    
    const handleSubmit = async (event) => {
        console.log(event.target.module_id.value);
        event.preventDefault();
        
        try {
            var userData = {
                title: event.target.title.value,
                video_url: event.target.video_url.value, 
                module_id: event.target.module_id.value,
                description: event.target.description.value, 
                notes: event.target.notes.value   
            }
            const data = await AdminLessonService.update(params.id, userData).json();

            if (data.status === true) {

                swal("Lesson Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/lesson')
                    }
                })

            } 

        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }
    return (
        <Fragment>
            <MainPagetitle mainTitle="Lesson" pageTitle={'Lesson'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Edit Lesson</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                        
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Select Module<span className="text-danger">*</span></label>
                                            <select onChange={handleModuleChange} className="form-control" id="module_id" name="module_id"  >  
                                            <option disabled  >Select Module</option>
                                              {ModulesData.map((element, index) =>
                                                <option key={index} value={element.id} selected={element.id == LessonUpdate.module_id ? LessonUpdate.module_id : '' ? true : ''}>{element.name}</option>
                                              )}
                                            </select> 
                                        </div>	
                                         
                                             
                                         
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Title<span className="text-danger">*</span></label>
                                            <input type="text" name="title"  defaultValue={LessonUpdate.title} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                        </div>  
                                       
                                            
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Video Url<span className="text-danger">*</span></label>
                                            <input type="url" defaultValue={LessonUpdate.video_url} className="form-control" name='video_url' id="val-username" placeholder=""/>
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-notes" className="form-label" >notes</label>
                                            <input type="text" className="form-control" name='notes' defaultValue={LessonUpdate.notes} id="val-notes" placeholder=""/>
                                        </div>	
                                        <div className="col-xl-12 mb-3">
                                            <label htmlFor="val-description" className="form-label" >Description</label> 
                                             <Editor   
                                            apiKey="mzqtqwlw84qnnu5kf0a75l26z26pon7ty2tl1lw5b7u5ukf7"  
                                            textareaName='description' 
                                            initialValue={LessonUpdate.description}
                                            init={{
                                              height: 200,
                                              menubar: false,
                                              plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "code",
                                                "help",
                                                "wordcount",
                                              ],
                                              toolbar:
                                                "undo redo | blocks | " +
                                                "bold italic forecolor | alignleft aligncenter " +
                                                "alignright alignjustify | bullist numlist outdent indent | " +
                                                "removeformat | help",
                                              content_style:
                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                            }}
                                          />
                                        </div>	
                                        
                                        
                                        </div>
                                        <p className='text-danger fs-12'>{Error}</p>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button>

                                            <Link  to={`/admin/lesson`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link> 

                                        </div>
                                    </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </Fragment>
    );
};

export default ModuleShowUpdate;
