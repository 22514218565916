import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminModuleService from '../../api/services/AdminModuleService';
import { Editor } from "@tinymce/tinymce-react";

import swal from "sweetalert";

const ModuleShowUpdate = () => {
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Module/';
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState()
    const [Error, setError] = useState('');
    const [ModuleUpdate, SetModuleUpdate] = useState([]);
    const params = useParams();
    const navigate = useNavigate()
    // const [RoleList, setRoleList] = useState([]);

    // useEffect(() => {
    //     getRoleList();
    // }, []);

    // const getRoleList = async () => {
    //     try {
    //       let responseData = await AdminModuleService.Role_index().json();  
    //       setRoleList(responseData.data);
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };  
    const GetModuleUpdate = async (id) => {
        try {
            setLoading(true);
            let responseData = await AdminModuleService.show(id).json()
            SetModuleUpdate(responseData.data);
            setLoading(false);
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('usertoken')) {

            GetModuleUpdate(params.id);
        }
        else {
            navigate('/');
        }

    }, [])
    const onFileChange = (e, file) => {
        if (file) {
            setFile();
            return;
        }
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
            var image = fileReader.result
            setFile(image)
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            var userData = {
                "name": event.target.name.value,
                "subtitle": event.target.subtitle.value,
                "image": file ? file.split(',')[1] : "",
                "description": event.target.description.value,

            }

            const data = await AdminModuleService.update(params.id, userData).json();

            if (data.status === true) {

                swal("Module Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/module_index')
                    }
                })

            }


        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }
    return (
        <Fragment>
            <MainPagetitle mainTitle="Module" pageTitle={'Module'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Edit Module</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>

                                    ) : (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">

                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Title<span className="text-danger">*</span></label>
                                                    <input type="text" name="name" defaultValue={ModuleUpdate.name} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">SubTitle<span className="text-danger">*</span></label>
                                                    <input type="text" name="subtitle" defaultValue={ModuleUpdate.subtitle} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">image<span className="text-danger">*</span></label><br></br>
                                                    {ModuleUpdate.image ? <img className="img-drop-area" height={100} width={100} name="image" src={file ? file : imageUrl + ModuleUpdate.image} alt='Module' /> : null}
                                                  
                                                    <p></p>
                                                    <input type="file" className="form-control" name='image' id="val-image" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />

                                                </div>
                                                <div className="col-xl-12 mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Description<span className="text-danger">*</span></label>
                                                  
                                                    <Editor
                                                        apiKey="mzqtqwlw84qnnu5kf0a75l26z26pon7ty2tl1lw5b7u5ukf7"
                                                        textareaName='description'
                                                        name='description'
                                                        initialValue={ModuleUpdate.description}
                                                        init={{
                                                            height: 200,
                                                            menubar: false,
                                                            plugins: [
                                                                "advlist",
                                                                "autolink",
                                                                "lists",
                                                                "link",
                                                                "image",
                                                                "charmap",
                                                                "preview",
                                                                "anchor",
                                                                "searchreplace",
                                                                "visualblocks",
                                                                "code",
                                                                "fullscreen",
                                                                "insertdatetime",
                                                                "media",
                                                                "table",
                                                                "code",
                                                                "help",
                                                                "wordcount",
                                                            ],
                                                            toolbar:
                                                                "undo redo | blocks | " +
                                                                "bold italic forecolor | alignleft aligncenter " +
                                                                "alignright alignjustify | bullist numlist outdent indent | " +
                                                                "removeformat | help",
                                                            content_style:
                                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                        }}
                                                    />
                                                </div>

                                                <p className='text-danger fs-12'>{Error}</p>

                                            </div>
                                            <div>
                                                <button type="submit" className="btn btn-primary me-1">Submit</button>
                                                <Link to={`/admin/module_index`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default ModuleShowUpdate;
