import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
 
import AdminStudentGameScoreService from '../../api/services/AdminStudentGameScoreService';
import AdminUsersService from '../../api/services/AdminUsersService'; 
import AdminGameService from '../../api/services/AdminGameService'; 


import swal from "sweetalert";


const StudentGameScoreUpdate = () => {
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState()
    const [Error, setError] = useState('');
    const [StudentGameScoreUpdate, SetStudentGameScoreUpdate] = useState([]);
    const params = useParams();
    const navigate = useNavigate()  
    const [StudentList, setStudentList] = useState([]);
    const [StudentData, setStudentData] = useState([]); 
    const [GameList, setGameList] = useState([]); 
    const [GameData, setGameData] = useState([]); 
    const [PassData, setPassData] = useState(1);
      
    const GetStudentGameScoreUpdate = async (id) => {
        try {
            setLoading(true);
            let responseData = await AdminStudentGameScoreService.show(id).json();  
            SetStudentGameScoreUpdate(responseData.data);   
        
            let responseDatainner = await AdminStudentGameScoreService.SchoolWithstudentData().json();    
            setStudentList(responseDatainner.data); 
            
            setStudentData(responseData.data.user_id); 
            setLoading(false); 
            let selectedStudent = null; 
            for (let i = 0; i < responseDatainner.data.length; i++) {
                console.log('call',responseData.data.user_id);   
                console.log(responseDatainner.data[i].id);   
                if (responseDatainner.data[i].id == responseData.data.user_id) {   
                    selectedStudent = responseDatainner.data[i];   
                    break;  
                }
            }  
            if (selectedStudent) {  
                setGameList(selectedStudent.school.game);
            } else {
                setGameList([]);  
            }
             
            setTimeout(() => { 
                 return false;  
            }, 1000);  
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json(); 
                setError(errorJson.message)
            }
        }
        
    } 
     
    const getStudentList = async () => { 
        try { 
            let responseData = await AdminStudentGameScoreService.SchoolWithstudentData().json();    
            setStudentList(responseData.data);
        } catch (error) { 
            console.log(error);
        }  
      };
    // const onStudentGetChange = (e) => {  
    //     const id_of_student = e.target.value;  
    //     setStudentData(e.target.value); 
    //     let selectedStudent = null; 
    //     for (let i = 0; i < StudentList.length; i++) {  
    //         if (StudentList[i].id == id_of_student) { 
    //             selectedStudent = StudentList[i];  
    //             break;  
    //         }
    //     } 
       
    //     if (selectedStudent) { 
    //         setGameList(selectedStudent.school.game);
    //     } else {
    //         setGameList([]);  
    //     }
    //   }

    const onStudentGetChange = (e) => {
        const id_of_student = e.target.value;
        setStudentData(id_of_student);  
        const selectedStudent = StudentList.find(Student => Student.id == id_of_student);  
        if (selectedStudent && selectedStudent.classroom && selectedStudent.classroom.subjects && selectedStudent.classroom.subjects.length > 0) {
             const firstSubject = selectedStudent.classroom.subjects[0];
            if (firstSubject && firstSubject.level && firstSubject.level.length > 0) { 
                setGameList(firstSubject.level[0].games); 
            } else {
                console.log('Level not found for the first subject');
                setGameList([]);
            }
        } else {
            console.log('Selected game not found');
            setGameList([]);
        }
    }

    //   const getGameList = async () => { 
    //     try { 
    //         let responseData = await AdminGameService.index().json();    
    //         setGameList(responseData.data);
    //     } catch (error) { 
    //         console.log(error);
    //     }  
    //   };

    const onGameGetChange = (e) => { 
        setGameData(e.target.value);
      }
      const onPassGetChange = (e) => {  
        setPassData(e.target.value);
      }

      useEffect(() => { 
        const selectedStudent = StudentList.find(Student => Student.id == StudentGameScoreUpdate.user_id);  
      
        if (selectedStudent && selectedStudent.classroom && selectedStudent.classroom.subjects && selectedStudent.classroom.subjects.length > 0) {
             const firstSubject = selectedStudent.classroom.subjects[0];
            if (firstSubject && firstSubject.level && firstSubject.level.length > 0) { 
                setGameList(firstSubject.level[0].games); 
            } else {
                console.log('Level not found for the first subject');
                setGameList([]);
            }
        } else {
            console.log('Selected game not found');
            setGameList([]);
        } 
    }, [StudentList, StudentGameScoreUpdate.game_id]);

    useEffect(() => { 
        
       
        
        // getGameList();
        // setStudentData(StudentGameScoreUpdate.user_id); 
        // let selectedStudent = null; 
        // for (let i = 0; i < StudentList.length; i++) { 
           
        //     if (StudentList[i].id == StudentGameScoreUpdate.user_id) {   
        //         selectedStudent = StudentList[i];  
        //         console.log(selectedStudent);
        //         break;  
        //     }
        // } 
        
        // if (selectedStudent) { 
        //     setGameList(selectedStudent.school.game);
        // } else {
        //     setGameList([]);  
        // }

        if (localStorage.getItem('usertoken')) { 
            GetStudentGameScoreUpdate(params.id); 
            getStudentList(); 
        }
        else {
            navigate('/');
        }

    }, [])
     
    const handleSubmit = async (event) => {
        event.preventDefault(); 
        try {
            var userData = {
                user_id: event.target.user_id.value,
                game_id: event.target.game_id.value,
                is_passed: PassData,
                score: event.target.score.value, 
            }
            const data = await AdminStudentGameScoreService.update(params.id, userData).json();

            if (data.status === true) {

                swal("Student Game Score Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/student_game_score')
                    }
                })

            }
             if (data.status === false) {  
              setError(data.message); 
            }


        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }
    return (
        <Fragment>
<MainPagetitle mainTitle="Student Game Score " pageTitle={'Student Game Score'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Edit Student Game Score</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                {loading ? (
                                            <div class="spinner">
                                                <div class="lds-roller">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div> 
                                            </div>
                                        
                                        ) : (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row"> 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-user_id" className="form-label" >Select Student<span className="text-danger">*</span></label>
                                            <select className="form-control" id="user_id" name="user_id" onChange={onStudentGetChange}  >

                                            {StudentList.map((element, index) =>
                                            <option key={index} value={element.id} selected={element.id === StudentGameScoreUpdate.user_id ? StudentGameScoreUpdate.user_id : '' ? true : ''}>{element.firstname} {element.lastname} </option>
                                            )}
                                            </select>
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-game_id" className="form-label" >Select Game<span className="text-danger">*</span></label>
                                            <select className="form-control" id="game_id" name="game_id" onChange={onGameGetChange}  >

                                            {GameList.map((element, index) =>
                                            <option key={index} value={element.id} selected={element.id === StudentGameScoreUpdate.game_id ? StudentGameScoreUpdate.game_id : '' ? true : ''}>{element.title} </option>
                                            )}
                                            </select>
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-is_passed" className="form-label" >Is Pass ?<span className="text-danger">*</span></label>
                                            <select className="form-control" id="is_passed" name="is_passed" onChange={onPassGetChange}   >
                                                <option value={1} selected={StudentGameScoreUpdate.is_passed === 1} >Pass</option>
                                                <option value={0} selected={StudentGameScoreUpdate.is_passed === 0}>Fail</option>                                             
                                            </select>
                                        </div>	 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-score" className="form-label" >Score<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" defaultValue={StudentGameScoreUpdate.score}  name='score' id="val-score" placeholder=""/>
                                        </div>
                                        </div>
                                        <h5 style={{color:'red'}}>{Error}</h5>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button>  
                                            <Link  to={`/admin/student_game_score`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link>
                                         </div>
                                    </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default StudentGameScoreUpdate;
