import React, { useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminTaskService from '../../api/services/AdminTaskService';
import AdminModuleService from '../../api/services/AdminModuleService';
   
                    
const ModuleAdd = () => {   
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [module, setModule] = useState([]);
    const [moduleData, setmoduleData] = useState([]);
    const records = module;
    // const [module_id, setModuledata] = useState([])
    // const [module_option, setModule] = useState([]);
  
      const onSubmitForm = async (event) => {
        event.preventDefault();
        try {
          var userData = { 
            "module_id": event.target.module_id.value,
            "name_task": event.target.name_task.value,              
          }
            let responseData = await AdminTaskService.store(userData).json()
            if (responseData.status === true) { 
              navigate("/admin/task_index");
            }
          } catch (error) {
            
            if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
          }
       
    };

    const getModule = async () => { 
        try { 
            let responseData = await AdminModuleService.index().json();    
            setModule(responseData.data);
        } catch (error) { 
            console.log(error);
        }  
      };
    const onModuleChange = (e) => { 
        setmoduleData(e.target.value);
      }
      useEffect(() => {
        getModule(); 
      }, []);
 
   
    return(
        <> 
        <MainPagetitle mainTitle="Category add" pageTitle={'Category add'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
			    	<div className="col-xl-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Add Category</h4>
                            </div>           
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row">
                                   
                                    <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Select Module<span className="text-danger">*</span></label>
                                        <select className="form-control" id="module_id" name="module_id" onChange={onModuleChange}  >

                                        {module.map((element, index) =>
                                        <option key={index} value={element.id}>{element.name} </option>
                                        )}
                                        </select>
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='name_task' id="val-username" placeholder=""/>
                                        </div>	 
                                    </div>
                                    <h5 style={{color:'red'}}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                     </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};
export default ModuleAdd;