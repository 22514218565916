import React, { useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminStudentGameScoreService from '../../api/services/AdminStudentGameScoreService';
import AdminUsersService from '../../api/services/AdminUsersService'; 
import AdminGameService from '../../api/services/AdminGameService';  


                    
const StudentGameScoreAdd = () => {   
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [StudentList, setStudentList] = useState([]);
    const [StudentData, setStudentData] = useState([]); 
    const [GameList, setGameList] = useState([]);
    const [GameData, setGameData] = useState([]); 
    const [PassData, setPassData] = useState(1); 
    console.log('game list',GameList);  
      const onSubmitForm = async (event) => {
        event.preventDefault();
        
        try {  
          var userData = { 
            "user_id": event.target.user_id.value,
            "game_id": event.target.game_id.value,
            "is_passed": PassData,
            "score": event.target.score.value,              
          }
          
            let responseData = await AdminStudentGameScoreService.store(userData).json() 
            if (responseData.status === true) { 
              navigate("/admin/student_game_score");
            } 
            if (responseData.status === false) {  
              setError(responseData.message); 
            }
          } catch (error) {
            
            if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
          }
       
    };

    const getStudentList = async () => { 
        try { 
            let responseData = await AdminStudentGameScoreService.SchoolWithstudentData().json();    
           
            setStudentList(responseData.data);
        } catch (error) { 
            console.log(error);
        }  
      };
      const onStudentGetChange = (e) => {
          const id_of_student = e.target.value;
          setStudentData(id_of_student);  
          const selectedStudent = StudentList.find(Student => Student.id == id_of_student);  

          if (selectedStudent && selectedStudent.classroom && selectedStudent.classroom.subjects && selectedStudent.classroom.subjects.length > 0) {
              let gameTitles = [];

              for (let i = 0; i < selectedStudent.classroom.subjects.length; i++) {
                  const subject = selectedStudent.classroom.subjects[i];
                  console.log(subject);

                  if (subject && subject.level && subject.level.length > 0) {
                      for (let j = 0; j < subject.level.length; j++) {
                          const level = subject.level[j]; 

                          if (level.games && level.games.length > 0) {
                            for (let k = 0; k < level.games.length; k++) {
                                const game = level.games[k]; 
                                
                                gameTitles.push({ id: game.id, title: game.title });
                            }
                        } else {
                            console.log(`Games not found for level ${level.id}`);
                        }
                      }
                  } else {
                      console.log(`Levels not found for subject ${i + 1}`);
                  }
              }

              if (gameTitles.length > 0) {
                  setGameList(gameTitles);  
              } else {
                  console.log('No game titles found for any subject');
                  setGameList([]);
              }
          } else {
              console.log('Selected student or classroom not found');
              setGameList([]);
          }


      }
    
    
        // let selectedStudent = null;
        
        // for (let i = 0; i < StudentList.length; i++) { 
          
        //     if (StudentList[i].id == id_of_student) { 
        //         selectedStudent = StudentList[i];  
        //         break;  
        //     }
        // } 
       
        // if (selectedStudent) { 
        //     setGameList(selectedStudent.school.game);
        // } else {
        //     setGameList([]);  
        // }
    // }

      // const getGameList = async () => { 
      //   try { 
      //       let responseData = await AdminGameService.index().json();    
      //       setGameList(responseData.data);
      //   } catch (error) { 
      //       console.log(error);
      //   }  
      // };

    const onGameGetChange = (e) => {  
        setGameData(e.target.value);
      }
      const onPassGetChange = (e) => {  
        setPassData(e.target.value);
      }

      
      useEffect(() => {  
        getStudentList(); 
        // getGameList()
      }, []);
 
   
    return(
        <> 
        <MainPagetitle mainTitle="Student Game Score" pageTitle={'Student Game Score'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
			    	<div className="col-xl-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Add Student Game Score</h4>
                            </div>           
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row">
                                   
                                    <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-user_id" className="form-label" >Select Student<span className="text-danger">*</span></label>
                                            <select className="form-control" id="user_id" name="user_id" onChange={onStudentGetChange}  >
                                            <option disabled selected value=''>Select Student</option>
                                            {StudentList.map((element, index) =>
                                            <option key={index} value={element.id}>{element.firstname} {element.lastname} </option>
                                            )}
                                            </select>
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-game_id" className="form-label" >Select Game<span className="text-danger">*</span></label>
                                            <select className="form-control" id="game_id" name="game_id" onChange={onGameGetChange}  >
                                            <option disabled selected value=''>Select Game</option>
                                            { GameList.map((element, index) => (
                                                      <option key={index} value={element.id}>{element.title}</option>
                                                  ))
                                              }
                                            </select>
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-is_passed" className="form-label" >Is Pass ?<span className="text-danger">*</span></label>
                                            <select className="form-control" id="is_passed" name="is_passed" onChange={onPassGetChange}   >
                                                <option value={1}>Pass</option>
                                                <option value={0}>Fail</option>                                             
                                            </select>
                                        </div>	 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-score" className="form-label" >Score<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name='score' id="val-score" placeholder=""/>
                                        </div>
                                    </div>
                                    <h5 style={{color:'red'}}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <Link  to={`/admin/student_game_score`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link> 

                                     </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};
export default StudentGameScoreAdd;