
import clientAuth from "../clientAuth"
export default {
    index: (userData) => clientAuth.post(`users`, { json: userData }),
    store: (userData) => clientAuth.post('users_add', { json: userData }),
    show: (id) => clientAuth.get(`users_show/${id}`),
    update: (id, userData) => clientAuth.post(`users_update/${id}`, { json: userData }),
    destroy: (id) => clientAuth.delete(`users_delete/${id}`),  

    role_index: () => clientAuth.get(`getrole`), 
    // download: (userData) => clientAuth.post('bulk-download-certificate', { json: userData }),
    
    AllTeachers_index: () => clientAuth.get(`allteacher_list`),
    AllStudents_index: () => clientAuth.get(`allstudent_list`), 
};