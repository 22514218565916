import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminUsersService from '../../api/services/AdminUsersService';
import AdminSchoolService from '../../api/services/AdminSchoolService';
import AdminClassService from '../../api/services/AdminClassService';
import AdminLevelService from '../../api/services/AdminLevelService';
import swal from "sweetalert";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'react-multi-select-component';




const UserAdd = () => {

    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [Roles, setRoles] = useState([]);
    const [UserroleData, setUserroleData] = useState([]);
    const [SchoolData, setSchoolData] = useState([]);
    const records = Roles;
    const [SchoolList, setSchoolList] = useState([]);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [ClassdataList, setClassList] = useState([]);
    const [ClassroomData, setClassroomData] = useState([]);
    const [LevelsList, setLevelsList] = useState([]);
    const [LevelsData, setLevelsData] = useState('');
    const [selectRoleId, setSelectRoleId] = useState();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const [selectevalue, selectedarrayValues] = useState([]);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };


    const onFileChange = (e, file) => {
        if (file) {
            setFile();
            return;
        }
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
            var image = fileReader.result
            setFile(image)
        }
    }
    const getSchoolList = async () => {
        try {
            let responseData = await AdminSchoolService.index().json();
            setSchoolList(responseData.data);
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    const getLevelsList = async () => {
        try {
            let responseData = await AdminLevelService.index().json();
            setLevelsList(responseData.data);
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }

    const onSchoolChange = (e) => {
        var id = e.target.value

        let getgamelist = SchoolList.length != 0 ? SchoolList.filter(function (item) {
            return item.id == id.toString();
        }) : '';

        setClassList(getgamelist[0].classname)
        var getvalue = getgamelist[0].classname != null ? getgamelist[0].classname.map(option => ({ value: option, label: option })) : ""

        setClassroomData(getvalue)
        setSchoolData(e.target.value);
        setSelected([])
    }

    const options = ClassdataList.map(element => ({
        value: element,
        label: element,
        // disabled: !element.available  
    }));

    const onClassroomChange = (e) => {
        console.log(e.target.value, "onclassroom")
        // setClassroomData(e.target.value);
    }
    const handleSelectChange = (selectedItems) => {
        const selectedValues = selectedItems.map(item => item.value);
        selectedarrayValues(selectedValues);
        setSelected(selectedItems);
    }
    const getClassList = async () => {
        try {
            let responseData = await AdminClassService.index().json();
            // setClassList(responseData.data);
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }

    const onLevelChange = (e) => {

        setLevelsData(e.target.value);
    }

    useEffect(() => {
        getClassList();
        getSchoolList();
        getRoles();
        getLevelsList();
    }, [])
    const onUserRoleChange = (e) => {
        setSelectRoleId(e.target.value)
        setUserroleData(e.target.value);
    }

    const onSubmitForm = async (event) => {

        event.preventDefault();

        try {
            var userData = {
                "firstname": event.target.firstname.value,
                "lastname": event.target.lastname.value,
                "email": event.target.email.value,
                "password": event.target.password.value,
                "role_id": selectRoleId ? selectRoleId : "",
                "image": file ? file.split(',')[1] : "",
                "school_id": event.target.school_id.value,
                "classroom": selectRoleId == 2 ? selectevalue.toString() : event.target.classroom_id.value,
                // "classroom_id": selectRoleId == 2 ? 0 : event.target.classroom_id.value,

                "currentlevel": selectRoleId == 2 ? 0 : event.target.currentlevel.value,
                "continuityscore": selectRoleId == 2 ? 0 : event.target.continuityscore.value
            }



            let responseData = await AdminUsersService.store(userData).json()
            setLoading(true);
            if (responseData.status === true) {
                setLoading(false);
                swal("User Create Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate("/admin/user");
                    }
                })
            }





        } catch (error) {

            if (error.name === 'HTTPError') {
                setLoading(false);
                const errorJson = await error.response.json();
                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    };

    const getRoles = async () => {
        try {
            let responseData = await AdminUsersService.role_index().json();
            setRoles(responseData.data);

        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <MainPagetitle mainTitle="User" pageTitle={'User'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Add User</h4>
                            </div>
                            <div className="card-body">
                                {loading ? (
                                    <div class="spinner">
                                        <div class="lds-roller">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                ) : (
                                    <form className="form-valide" onSubmit={onSubmitForm}>
                                        <div className="row">
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-firstname" className="form-label" >First Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name='firstname' placeholder="" />
                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-lastname" className="form-label" >Last Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name='lastname' placeholder="" />
                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-email" className="form-label" >Email<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name='email' placeholder="" />
                                            </div>
                                            <div className="col-xl-6 mb-3 ">
                                                <div className='d-flex align-items-end'>
                                                    <div className='w-100'>
                                                        <label htmlFor="val-password" className="form-label" >Password<span className="text-danger">*</span></label>
                                                        <input type={passwordVisible ? "text" : "password"} className="form-control" name='password' id="val-password" placeholder="" />
                                                    </div>
                                                    <div><button
                                                        className="btn btn-outline-primary"
                                                        type="button"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                                                        <span className="visually-hidden">{passwordVisible ? "Hide" : "Show"}</span>
                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-image" className="form-label" >Image</label>
                                                {/* <input type="file" className="form-control" name='image' id="val-username" placeholder=""/> */}
                                                <input type="file" className="form-control" name='image' accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />

                                            </div>
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-role_id" className="form-label" >Select User Role<span className="text-danger">*</span></label>
                                                <select className="form-control" name="role_id" onChange={onUserRoleChange}  >
                                                    <option disabled selected value=''>Select User Role</option>
                                                    {Roles.map((element, index) =>
                                                        <option key={index} value={element.id}>{element.name} </option>
                                                    )}
                                                </select>

                                            </div>


                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-school_id" className="form-label" >Select School</label>
                                                <select className="form-control" name="school_id" onChange={onSchoolChange}  >
                                                    <option disabled selected value=''>Select School</option>
                                                    {SchoolList.map((element, index) =>
                                                        <option key={index} value={element.id}>{element.name} </option>
                                                    )}
                                                </select>
                                            </div>
                                            {UserroleData === '2' ? <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-school_id" className="form-label" >Select Class</label>
                                                {/* <MultiSelect
                                                    options={ClassdataList}
                                                    value={ClassroomData}
                                                    onChange={handleSelectChange}
                                                    // onChange={setSelected}
                                                    labelledBy="Select"
                                                    // disabled

                                                /> */}
                                                <MultiSelect
                                                    name="class"
                                                    options={options}
                                                    value={selected}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select Games"
                                                />

                                            </div> : (
                                                <>
                                                    <div className="col-xl-6 mb-3">

                                                        <label htmlFor="val-school_id" className="form-label" >Select Class</label>
                                                        <select className="form-control" name="classroom_id" onChange={onClassroomChange}  >
                                                            <option disabled selected value=''>Select Class</option>
                                                            {ClassroomData.map((element, index) =>
                                                                <option key={index} value={element.value}>{element.label} </option>
                                                            )}
                                                        </select>
                                                        {/* <MultiSelect
                                                            options={ClassdataList}
                                                            value={ClassroomData}
                                                            onChange={onClassroomChange}
                                                            labelledBy="Select"

                                                        /> */}

                                                    </div>
                                                    {/* <div className="col-xl-6 mb-3">
                                                        <label htmlFor="val-currentlevel" className="form-label" >Select Current Level<span className="text-danger"></span></label>
                                                        <select className="form-control" name="currentlevel" onChange={onLevelChange}  >
                                                            <option selected disabled value={''}>Select Current Level</option>
                                                            {LevelsList.map(Level => (
                                                                <option key={Level.id} value={Level.id}>{Level.title}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-xl-6 mb-3">
                                                        <label htmlFor="val-continuityscore" className="form-label" >Continuity Score<span className="text-danger"></span></label>
                                                        <input type="number" className="form-control" name='continuityscore' id="val-continuityscore" placeholder="" />
                                                    </div> */}
                                                </>
                                            )}

                                        </div>


                                        <h5 style={{ color: 'red' }}>{error}</h5>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button>
                                            <Link to={`/admin/user`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>
                                        </div>
                                    </form>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default UserAdd;