import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
import UserService from "../../api/services/UserService";
import swal from "sweetalert";
const ChangePassword = () => {

    const navigate = useNavigate()
    const [error, setError] = useState("")

    useEffect(() => {
        if (localStorage.getItem('usertoken')) {

        }
        else {
            navigate('/');
        }

    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            var userData = {

                current_password: event.target.current_password.value,
                new_password: event.target.new_password.value,
                confirm_password: event.target.confirm_password.value

            }
            let responseData = await UserService.changepassword(userData).json();
            if (responseData.status === true) {
                swal("Password Changed!").then((willDelete) => {
                    if (willDelete) {
                        navigate('/dashboard');
                    }
                })
            }
            else {
                setError(responseData.message)
            }

        }
        catch (error) {
            console.log(error)
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }


    }
    return (
        <Fragment>
            <MainPagetitle mainTitle="Change Password" pageTitle={'Change Password'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Change Password</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">

                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-firstname" className="form-label" >Current Password<span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" name='current_password' placeholder="" />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-firstname" className="form-label" >New Password<span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" name='new_password' placeholder="" />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="val-firstname" className="form-label" >Confirm Password<span className="text-danger">*</span></label>
                                                <input type="password" className="form-control" name='confirm_password' placeholder="" />
                                            </div>

                                        </div>
                                        <h5 style={{ color: 'red' }}>{error}</h5>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button>
                                            <Link to={`/dashboard`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default ChangePassword;
