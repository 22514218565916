import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';

import swal from "sweetalert"; 
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminModuleService from '../../api/services/AdminModuleService';

const Module_task_complete = () => {
    const [Error, setError] = useState('');

    const [ModuleList, setModuleList] = useState([]);
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 10;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = ModuleList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(ModuleList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
    const [Teacherlist, setTeacherList] = useState([]);
    // console.log(Teacherlist);
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    } 
    // teacher_list
    const getTeacherlist= async (id) => { 
        try { 
            setLoading(true);setLoading(true);
            let responseData = await AdminModuleService.teacher_list(id).json(); 
            // console.log(responseData.data );  
            const dataArray = Object.values(responseData.data);
            setTeacherList(dataArray);
            setLoading(false);      
        } catch (error) { 
            console.log(error);
        }  
      };
        // const getModuleList = async (id) => {  
        //     try { 
        //         let responseData = await AdminModuleService.show_module_complete_list(id).json();  
        //         setModuleList(responseData.data); 
        //     } catch (error) {   
        //         console.log(error);               
        //         if (error.name === 'HTTPError') {
        //             const errorJson = await error.response.json();  
        //             setError(errorJson.message);
        //         }
        //     }
        // }
    useEffect(() => {
        // getTeacherlist();
        if (localStorage.getItem('usertoken')) { 
            getTeacherlist(params.id); 
        }
        else {
            navigate('/');
        }

    }, []) 
     
    return (
        <>
        <MainPagetitle mainTitle="Teacher Module Complete" pageTitle={'Teacher Module Complete'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Teacher Module Complete List</h4>
                                       
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th >
                                                        <strong>No.</strong>
                                                    </th> 
                                                    <th>
                                                        <strong>Name</strong>
                                                    </th> 
                                                    <th>
                                                        <strong>Email</strong>
                                                    </th> 
                                                    <th>
                                                        <strong>Percentage</strong>
                                                    </th>  
                                                    <th  style={{ textAlign: 'center' }}>
                                                        <strong>Status</strong>
                                                    </th> 
                                                     
                                                </tr>
                                            </thead>
                                            <tbody>
        
                                                {   Teacherlist.map((element, index) => { 
                                                    console.log('data',element.firstname);  
                                                        return (
                                                            <tr style={{ textAlign: 'center' }}> 
                                                                <td>   
                                                                    {index + 1}
                                                                </td>  
                                                                <td>
                                                                    {element.firstname} {element.lastname}
                                                                </td>
                                                                <td>
                                                                    {element.email}
                                                                </td> 
                                                                <td> 
                                                                    {element.percentageCompleted} %  
                                                                </td> 
                                                                <td style={{ textAlign: 'center' }}> 
                                                                    {element.status} 
                                                                </td> 
                                                                
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>
                                    )}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            {/* <div className='dataTables_info'>
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {ModuleList.length < lastIndex ? ModuleList.length : lastIndex}
                                                {" "}of {ModuleList.length} entries
                                            </div> */}
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                {/* <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default Module_task_complete;
