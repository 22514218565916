import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"; 
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminTaskService from '../../api/services/AdminTaskService';

const TaskList = () => {
    const [Error, setError] = useState('');

    const [TaskList, setTaskList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = TaskList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(TaskList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
     
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    } 
    const getTaskList = async () => { 
        try { 
            let responseData = await AdminTaskService.index().json();  
            setTaskList(responseData.data);
        } catch (error) { 
            console.log(error);               
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();  
                setError(errorJson.message);
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('usertoken')) { 
            getTaskList(); 
        }
        else {
            navigate('/');
        }

    }, [])
    const handleDelete = async (e) => {

        try {  
            let responseData = await AdminTaskService.destroy(e).json()
            console.log(responseData);
            if (responseData.status === true) {
                swal("Category Delete Succesfully").then((willDelete) => {
                    if (willDelete) {
                        getTaskList();
                    }
                })

            }else{
                swal("Cannot delete this Category because it is associated with a Lesson.").then((willDelete) => {
                    if (willDelete) {
                        getTaskList();
                    }
                })
            }
            

        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
    const handleCallback = () => {
        // Update the name in the component's state
        getTaskList()

    }
    return (
        <>
    <MainPagetitle mainTitle={'Module Categories'} pageTitle={'Module Categories'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">Module Category List</h4>
                                        <div>
                                            <Link to={`/admin/task_add`} className="btn btn-primary btn-sm ms-1">+ Add Category</Link> {" "}
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th><strong>No.</strong></th>
                                                    <th><strong>Name</strong></th>
                                                    <th><strong>Module Name</strong></th> 
                                                   <th><strong>Action</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody> 
                                                {
                                                    records.map((element, index) => { 
                                                        
                                                        return (
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <td> {index + 1} </td>
                                                                <td> {element.name_task}  </td>   
                                                                <td>
                                                                {element.module  ? `${element.module[0].name}` : 'Module Not Available'}
                                                                </td>  
                                                                <td style={{ textAlign: 'center' }}> 
                                                                    <div className="d-flex">
                                                                        <Link

                                                                            to={`/admin/task_show/${element.id}`}
                                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </Link>
                                                                        <Link
                                                                            onClick={() =>
                                                                                swal({
                                                                                    title: "Are you sure?", 
                                                                                    icon: "warning",
                                                                                    buttons: true,
                                                                                    dangerMode: true,
                                                                                }).then((willDelete) => {
                                                                                    if (willDelete) {
                                                                                        (handleDelete(element.id));
                                                                                    }
                                                                                })
                                                                            }

                                                                            className="btn btn-danger shadow btn-xs sharp"
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {TaskList.length < lastIndex ? TaskList.length : lastIndex}
                                                {" "}of {TaskList.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default TaskList;
