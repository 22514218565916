 
import clientAuth from "../clientAuth"
export default {
    index: () => clientAuth.get(`school/assign/user/index`),
    store: (userData) => clientAuth.post('school/assign/user/store', { json: userData }),
    show: (id) => clientAuth.get(`school/assign/user/show/${id}`),
    update: (id, userData) => clientAuth.post(`school/assign/user/update/${id}`, { json: userData }),
    destroy: (id) => clientAuth.delete(`users_delete/${id}`), 

    // export user
    export: () => clientAuth.get(`school/assign/user/export`),
    import: (userData) => clientAuth.post(`school/assign/user/import`),
};