import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer out-footer">
			<div className="copyright">
				<p>{d.getFullYear()} © 
					
					{" "}Developed by{" "}
					<a href="https://rigicglobalsolutions.com/" target="_blank"  rel="noreferrer">
						Rigic Global Solutions Pvt Ltd
					</a>{" "}
					
				</p>
			</div>
		</div>
	);
};

export default Footer;
