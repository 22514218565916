import React, { Fragment, useState, useEffect,useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
import { MultiSelect } from "react-multi-select-component"; 

import AdminClassService from '../../api/services/AdminClassService';
import AdminSubjectService from '../../api/services/AdminSubjectService';
import { Editor } from "@tinymce/tinymce-react";

import swal from "sweetalert";

const ClassesEdit = () => { 
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(true);
    const [Error, setError] = useState('');
    const [ClassUpdate, SetClassUpdate] = useState([]);
    const params = useParams();
    const navigate = useNavigate()  
    const [SubjectdataList, setSubjectList] = useState([]); 
    const [selected, setSelected] = useState([]);
    const [selectevalue, selectedarrayValues] = useState([]);
  
    const GetClassUpdate = async (id) => {
        try {
            setLoading(true);
            let responseData = await AdminClassService.show(id).json() 
            SetClassUpdate(responseData.data); 
            setLoading(false);      
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json(); 
                setError(errorJson.message)
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('usertoken')) { 
            GetClassUpdate(params.id);
        }
        else {
            navigate('/');
        }

    }, [])
     
    const handleSubmit = async (event) => {
        event.preventDefault(); 
        try {
            var userData = {
                classname: event.target.classname.value,   
                subject_id: selectevalue
            }
            const data = await AdminClassService.update(params.id, userData).json(); 
            if (data.status === true) { 
                swal("Class Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/class')
                    }
                }) 
            } 
        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }

     // subject list 
     const getSubjectList = async () => { 
        try { 
            let responseData = await AdminSubjectService.index().json();  
            setSubjectList(responseData.data);
        } catch (error) { 
            console.log(error);               
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();  
                setError(errorJson.message);
            }
        }
    } 
 
    const selectedOptions = useMemo(() => {
        return SubjectdataList.filter(subjects => ClassUpdate.subjects && ClassUpdate.subjects.some(item => item.id === subjects.id));
    }, [SubjectdataList, ClassUpdate]);

    useEffect(() => {
        // Map the selectedOptions to a format suitable for your selected state
        setSelected(selectedOptions.map(option => ({ value: option.id, label: option.name })));
    }, [selectedOptions]);
   
    const handleSelectChange  = (selectedItems) => {    
      const selectedValues = selectedItems.map(item => item.value);
      selectedarrayValues(selectedValues);
      setSelected(selectedItems);
    }

    const options = SubjectdataList.map(element => ({
        value: element.id,
        label: element.name, 
    }));

    useEffect(() => {
      if (localStorage.getItem('usertoken')) { 
            getSubjectList(); 
        }
        else {
            navigate('/');
        }

    }, [])

    return (
        <Fragment>
            <MainPagetitle mainTitle="Class" pageTitle={'Class'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Edit Class</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                    <form onSubmit={handleSubmit}>
                                        <div className="row"> 
                                             
                                            <div className="col-xl-6 mb-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Class name<span className="text-danger">*</span></label>
                                                <input type="text" name="classname" defaultValue={ClassUpdate.classname} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                            </div>  
                                            <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-subject_id" className="form-label" >Select Subject<span className="text-danger">*</span></label>
                                                <MultiSelect
                                                   name="subject_id"
                                                   options={options}
                                                    value={selected}
                                                    onChange={handleSelectChange }
                                                   placeholder="Select Subject" 
                                                /> 
                                        </div>	  
                                          
                                            
                                            <p className='text-danger fs-12'>{Error}</p>

                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button> 
                                            <Link  to={`/admin/class`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                        </div>
                                    </form>
                                    )}
                                </div> 
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default ClassesEdit;
