import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"; 
import MainPagetitle from '../../layouts/MainPagetitle';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import AdminSchoolAssignTouserService from '../../api/services/AdminSchoolAssignTouserService';

const SchoolAssignToUsers = () => {
    var PdfUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL+'Game-scores-certificate/';
    const [loading, setLoading] = useState(true);
    const [Error, setError] = useState(''); 
    const [SchoolWithAssignUser, setSchoolWithAssignUserList] = useState([]);
     
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = SchoolWithAssignUser.slice(firstIndex, lastIndex);
    const npage = Math.ceil(SchoolWithAssignUser.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const onDrop = async(acceptedFiles) => {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("file", file);
        const bearerToken = JSON.parse(localStorage.getItem('usertoken'));
        try {
            const responseData = await axios.post(
                `${process.env.REACT_APP_LARAVEL_IMAGE_URL}api/admin/school/assign/user/import`,
                formData,
                {
                    headers: {
                        "content-type": "multipart/form-data",
                        Authorization: `Bearer ${bearerToken}`,
                    },
                }
            );
            if (responseData.data.status == true) {
                swal(responseData.data.message).then((willDelete) => {
                    if (willDelete) {
                        getSchoolWithAssignUserList();
                    }
                })
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Failed to upload file');
        }
        setOpen(false);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    } 
    const getSchoolWithAssignUserList = async () => { 
        try { 
            setLoading(true);
            let responseData = await AdminSchoolAssignTouserService.index().json();  
         
            setSchoolWithAssignUserList(responseData.data);
            setLoading(false); 
        } catch (error) { 
            console.log(error);               
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();  
                setError(errorJson.message);
            }
        }
    }

    const getExportUser = async () => { 
        try { 
            const bearerToken = JSON.parse(localStorage.getItem('usertoken'));
            const response = await axios.get(
                `${process.env.REACT_APP_LARAVEL_IMAGE_URL}api/admin/school/assign/user/export`,
                {
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`
                    }
                }
            );
    
            // Create a URL for the blob response
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            // Create a link element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'school_assign_users.xlsx'); // Set the filename
    
            // Append the link to the body and trigger the click event
            document.body.appendChild(link);
            link.click();
    
            // Clean up by revoking the URL object
            window.URL.revokeObjectURL(url);
        } catch (error) { 
            console.log(error);               
            if (error.response && error.response.data) {
                setError(error.response.data.message);
            } else {
                setError('An error occurred while exporting.');
            }
        }
    }

    // const getExportUser = async () => {
    //     try {
    //         const bearerToken = JSON.parse(localStorage.getItem('usertoken'));
    //         const response = await axios.get(
    //           `${process.env.REACT_APP_IMAGE_URL}api/admin/closing/export`,
    //            {
    //             responseType: 'blob',
    //             headers: {
    //                 'Authorization': `Bearer ${bearerToken}`
    //             }
    //         });
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'closings.xlsx');
    //         document.body.appendChild(link);
    //         link.click();
    //     } catch (error) {
    //         console.log(error);
    //         if (error.name === "HTTPError") {
    //             const errorJson = await error.response.json();
    //             setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")));
    //         }
    //     }
    // }

    useEffect(() => {
        
        if (localStorage.getItem('usertoken')) { 
            getSchoolWithAssignUserList(); 
        }
        else {
            navigate('/');
        }

    }, [])
    const handleDelete = async (e) => {

        try {  
            let responseData = await AdminSchoolAssignTouserService.destroy(e).json()
            console.log(responseData);
            if (responseData.status === true) {
                swal("User Delete Succesfully").then((willDelete) => {
                    if (willDelete) {
                        setSchoolWithAssignUserList();
                    }
                })

            } 
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
    const handleCallback = () => {
        // Update the name in the component's state
        setSchoolWithAssignUserList()

    }
    return (
        <>
    <MainPagetitle mainTitle={'School with User'} pageTitle={'School with User'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <h4 className="heading mb-0">School with User List</h4>
                                        <div>
                                        <button className='btn btn-primary btn-sm ms-1' onClick={() => setOpen(true)}>Import Excel</button>
                                        {open && (
                                            <div className="popup">
                                            <div className="popup-inner">
                                                <h2>Upload Excel File</h2>
                                                <div {...getRootProps()} className="dropzone">
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop a file here, or click to select one</p>
                                                </div>
                                                {/* <form onSubmit={}> */}
                                                    {/* <button className='btn btn-primary btn-sm ms-1 mt-2 ' onClick={() => handleFileUpload()}>Upload</button> */}
                                                     <button className='btn btn-primary btn-sm ms-1 mt-2 ' onClick={() => setOpen(false)}>Close</button>
                                                {/* </form> */}
                                            </div>
                                            </div>
                                        )}
                                        {/* {fileData && (
                                                    <div>
                                                    <h3>Uploaded Data:</h3>
                                                    <pre>{JSON.stringify(fileData, null, 2)}</pre>
                                                    </div>
                                                )} */}
                                        <button className="   fbtn btn-primary btn-sm ms-1 text-white" type="submit" 
                                        onClick={()=>getExportUser()} >
                                                Export User
                                            </button>
                                            <Link to={`/admin/school/assign/user/add`} className="btn btn-primary btn-sm ms-1">+ Add School with users</Link> {" "}
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th><strong>No.</strong></th>
                                                    <th><strong>School Name</strong></th>
                                                    <th><strong>User Name</strong></th>
                                                    <th><strong>Email</strong></th>
                                                    <th><strong>User Role</strong></th>  
                                                   {/* <th><strong>Action</strong></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    records.map((element, index) => {

                                                        return (
                                                            <tr style={{ textAlign: 'center' }}>
                                                                <td>{index + 1}</td>
                                                                  
                                                                <td> 
                                                                {element.school && element.school.name ? element.school.name : 'N/A'}

                                                                </td>  
                                                                <td>{element.firstname+" "+ element.lastname}</td>
                                                                <td>{element.email}</td>
                                                                <td> 
                                                                 {element.roles && element.roles.length > 0 ? element.roles[0].name : 'N/A'} 
                                                                 </td> 
                                                                
                                                                

                                                                
                                                                 
                                                                {/* <td style={{ textAlign: 'center' }}> 
                                                                    <div className="d-flex">
                                                                        <Link

                                                                            to={`/admin/school/assign/user/update/${element.id}`}
                                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </Link>
                                                                        <Link
                                                                            onClick={() =>
                                                                                swal({
                                                                                    title: "Are you sure?", 
                                                                                    icon: "warning",
                                                                                    buttons: true,
                                                                                    dangerMode: true,
                                                                                }).then((willDelete) => {
                                                                                    if (willDelete) {
                                                                                        (handleDelete(element.id));
                                                                                    }
                                                                                })
                                                                            }

                                                                            className="btn btn-danger shadow btn-xs sharp"
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>

                                        </table>
                                    )}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {SchoolWithAssignUser.length < lastIndex ? SchoolWithAssignUser.length : lastIndex}
                                                {" "}of {SchoolWithAssignUser.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default SchoolAssignToUsers;
