import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import MainPagetitle from '../../layouts/MainPagetitle';
import { MultiSelect } from "react-multi-select-component";

import AdminSchoolService from '../../api/services/AdminSchoolService';
import AdminGameService from '../../api/services/AdminGameService'

import swal from "sweetalert";

const SchoolUpdate = () => {
    const [loading, setLoading] = useState(true);

    const [fields, setFields] = useState([{ value: "" }])
    const [Error, setError] = useState('');
    const [SchoolUpdate, SetSchoolUpdate] = useState([]);
    const params = useParams();
    const navigate = useNavigate()
    const [GameList, setGameList] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectevalue, selectedarrayValues] = useState([]);

    useEffect(() => {
        getGameList();

        if (localStorage.getItem('usertoken')) {
            GetSchoolUpdate(params.id);
        }
        else {
            navigate('/');
        }

    }, [])

    const selectedOptions = useMemo(() => {
        return GameList.filter(game => SchoolUpdate.game && SchoolUpdate.game.some(item => item.id === game.id));
    }, [GameList, SchoolUpdate]);

    useEffect(() => {
        setSelected(selectedOptions.map(option => ({ value: option.id, label: option.title })));

        const selectedValues = selectedOptions.map(item => item.id);
        selectedarrayValues(selectedValues);
    }, [selectedOptions]);


    const handleSelectChange = (selectedItems) => {
        const selectedValues = selectedItems.map(item => item.value);
        selectedarrayValues(selectedValues);
        setSelected(selectedItems);
    }

    const options = GameList.map(element => ({
        value: element.id,
        label: element.title,
        // disabled: !element.available  
    }));

    const getGameList = async () => {
        try {
            let responseData = await AdminGameService.index().json();
            setGameList(responseData.data);
        } catch (error) {

            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    const GetSchoolUpdate = async (id) => {
        try {
            setLoading(true);
            let responseData = await AdminSchoolService.show(id).json()
            SetSchoolUpdate(responseData.data);
            setLoading(false);
            const classList = responseData.data.classname != null || responseData.data.classname.length != 0 ? responseData.data.classname.map((element) => {
                return element
            }) : ""
            setFields(classList)





            // selectedarrayValues(selectedValues);

        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message)
            }
        }
    }
    let addFormFields = () => {
        setFields([...fields, { value: '' }]);
    }


    let removeFormFields = (i) => {
        let values = [...fields];
        values.splice(i, 1);
        setFields(values)
    }
    // le
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            var userData = {
                name: event.target.name.value,
                address: event.target.address.value,
                state: event.target.state.value,
                city: event.target.city.value,
                brin_number: event.target.brin_number.value,
                place: event.target.place.value,
                stitching: event.target.stitching.value,
                game: selectevalue,

                classname: fields.map((element) => {
                    return (element ? element.replace(/"/g, '') : "_")
                }).join(',')
            }
            console.log(userData, "classname")
            const data = await AdminSchoolService.update(params.id, userData).json();

            if (data.status === true) {

                swal("School Update Succesfully").then((willDelete) => {
                    if (willDelete) {
                        navigate('/admin/school')
                    }
                })

            }


        }
        catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
            }
        }

    }
    return (
        <Fragment>
            <MainPagetitle mainTitle="School" pageTitle={'School'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Edit School</h4>
                            </div>
                            <div className="card-body">
                                <div className="form-validation">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>

                                    ) : (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Name<span className="text-danger">*</span></label>
                                                    <input type="text" name="name" defaultValue={SchoolUpdate.name} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-username" className="form-label" >Brin Number<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name='brin_number' defaultValue={SchoolUpdate.brin_number} id="val-username" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-username" className="form-label" >Stitching<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name='stitching' defaultValue={SchoolUpdate.stitching} id="val-username" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-username" className="form-label" >Place<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name='place' defaultValue={SchoolUpdate.place} id="val-username" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-username" className="form-label" >Address<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" defaultValue={SchoolUpdate.address} name='address' id="val-username" placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-username" className="form-label" >State<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name='state' id="val-username" defaultValue={SchoolUpdate.state} placeholder="" />
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-username" className="form-label" >City<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name='city' id="val-username" defaultValue={SchoolUpdate.city} placeholder="" />
                                                </div>
                                                {<div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-game" className="form-label" >Select Game<span className="text-danger">*</span></label>
                                                    <MultiSelect
                                                        name="game"
                                                        options={options}
                                                        value={selected}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select Games"
                                                    />
                                                </div>}
                                                <div>
                                                    <label htmlFor="val-game" className="form-label" >Add Class<span className="text-danger">*</span></label>
                                                    <div className="d-flex gap-3 flex-wrap">
                                                        {/* {fields.map((field, index) => (
                                                    <div key={index} style={{ marginBottom: '10px' }} className='d-flex gap-3'>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={field.value}
                                                            // onChange={(e) => handleChange(index, e)}
                                                            
                                                        // placeholder={`Class ${index + 1}`}
                                                        />
                                                        {index > 0 && (
                                                            <button className="btn btn-danger me-1" onClick={() => removeFormFields(index)}>
                                                                Remove
                                                            </button>
                                                        )}
                                                    </div>
                                                ))} */}
                                                        {fields.map((element, index) => {
                                                            return (

                                                                <div key={index} style={{ marginBottom: '10px' }} className='d-flex gap-3'>
                                                                    {/* <label htmlFor="val-username" className="form-label" >Add Class</label> */}
                                                                    <input type="text" className="form-control mt-2" name="i_title"
                                                                        onChange={(e) => {
                                                                            const updatedFields = [...fields];
                                                                            updatedFields[index] = e.target.value;

                                                                            setFields(updatedFields);
                                                                        }}
                                                                        value={element.value}
                                                                        required
                                                                        defaultValue={element ? element.replace(/"/g, '') : ""}
                                                                    />

                                                                    {
                                                                        index ?
                                                                            <button className="btn btn-danger  btn-sm ms-1" onClick={() => removeFormFields(index)}>
                                                                                Remove
                                                                            </button>

                                                                            : null
                                                                    }


                                                                </div>
                                                            );
                                                        })}

                                                    </div>
                                                    <input type='button' className="btn btn-primary me-1" onClick={addFormFields} value={'Add Class'} />
                                                </div>



                                            </div>

                                            <h5 style={{ color: 'red' }}>{Error}</h5>
                                            <div>
                                                <button type="submit" className="btn btn-primary me-1">Submit</button>

                                                <Link to={`/admin/school`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>

                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Fragment>
    );
};

export default SchoolUpdate;
