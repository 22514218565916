import client from "../client";
import clientAuth from "../clientAuth";

export default {
  // 
  // login: (email,password) => client.post("session/login",{json:{
  //   email: email,
  //   password: password,
  //   device_name:"web"
  // }}),
  login: (userData) => clientAuth.post("session/login", { json: userData }),
  //
  logout: () => clientAuth.post("session/logout"),

  index: () => clientAuth.get("user/index"),

  store: (email, password, name, role) => clientAuth.post('user/store', {
    json: {
      email: email,
      password: password,
      name: name,
      role: role
    }
  }),

  show: (id) => clientAuth.get('user/show/' + id),

  update: (id, name) => clientAuth.post('user/update/' + id, {
    json: {
      name: name
    }
  }),

  active_student: (id, userData) => clientAuth.post('active_student/' + id, { json: userData }),
  import: (userData) => clientAuth.post('school/users/import', { json: userData }),
  changepassword: (userData) => clientAuth.post('changepassword', { json: userData }),
  
};