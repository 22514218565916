import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from "sweetalert"; 
import MainPagetitle from '../../layouts/MainPagetitle.js';
import { MultiSelect } from "react-multi-select-component";

import AdminUserZeroTestService from '../../api/services/AdminUserGameService.js';
import AdminSchoolService from '../../api/services/AdminSchoolService.js';
import AdminGameService from '../../api/services/AdminGameService.js';


const UserGamesList = () => {
    const [Error, setError] = useState('');
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL+'User/';
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState('');
    const [UserZeroTestList, setUserZeroTestList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 50;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = UserZeroTestList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(UserZeroTestList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate(); 
    const [SchoolList, setSchoolList] = useState([]);
    const [SchoolData, setSchoolData] = useState([]);
    const [GameList, setGameList] = useState([]);
    const [GameData, setGameData] = useState([]);
    const [selectedGames, setSelectedGames] = useState([]);
    const [selectedGameValue, selectedGameValues] = useState([]);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [selectedSchoolValue, selectedSchoolValues] = useState([]);

     function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    
    const getSchoolList = async () => {
        try {
            let responseData = await AdminSchoolService.index().json();
            setSchoolList(responseData.data);
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    const schooloptions = SchoolList.map(element => ({
        value: element.id,
        label: element.name,
     // disabled: !element.available  
    })); 
    
    const handleSelectSchoolChange  = (selectedSchoolItems) => {    
        const selectedValues = selectedSchoolItems.map(item => item.value);
        selectedSchoolValues(selectedValues);
        setSelectedSchools(selectedSchoolItems);
        getUserZeroTestList(selectedValues,selectedGameValue);
      }

    const getGameList = async () => {
        try {
            let responseData = await AdminGameService.index().json();
            setGameList(responseData.data);
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    } 
    const options = GameList.map(element => ({
            value: element.id,
            label: element.title,
         // disabled: !element.available  
    }));
 
    const handleSelectGameChange  = (selectedGameItems) => {    
        const selGameValues = selectedGameItems.map(item => item.value);
        selectedGameValues(selGameValues);
        setSelectedGames(selectedGameItems);
        getUserZeroTestList(selectedSchoolValues,selGameValues);
      }

    const getUserZeroTestList = async (selectedSchoolValue,selectedGameValue) => { 
        try { 
            var userData = {
                school_id:selectedSchoolValue,
                game_id : selectedGameValue,
            }  
            setLoading(true);
            let responseData = await AdminUserZeroTestService.index(userData).json(); 
            console.log(responseData);
            setTotalData(responseData.data.length); 
            setUserZeroTestList(responseData.data);
            setLoading(false); 
        } catch (error) {
            console.log(error);
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();
                setError(errorJson.message);
            }
        }
    }
    useEffect(() => {   
        if (localStorage.getItem('usertoken')) { 
            getGameList();
            getSchoolList();
            getUserZeroTestList(selectedSchoolValue,selectedGameValue);
        }
        else {
            navigate('/');
        }

    }, [])

   
    const handleCallback = () => {
        // Update the name in the component's state
        getUserZeroTestList(selectedSchoolValue,selectedGameValue);
        getSchoolList();
        getGameList();

    }
    return (
        <>
    <MainPagetitle mainTitle="Student Games List" pageTitle={'Student Games List'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        <div className="d-flex justify-content-between text-wrap align-items-center gap-3 ">
                                            <div><h4 className="heading mb-0">Student Game List</h4></div> 
                                            <div>
                                            <MultiSelect
                                                   name="school_id"
                                                   options={schooloptions}
                                                    value={selectedSchools}
                                                    onChange={handleSelectSchoolChange }
                                                   placeholder="Select Schools" 
                                                /> 
                                            </div>
                                            <div>  
                                                <MultiSelect
                                                   name="game_id"
                                                   options={options}
                                                    value={selectedGames}
                                                    onChange={handleSelectGameChange }
                                                   placeholder="Select Games" 
                                                /> 
                                            </div>
                                        </div> 
                                        {/* <div>
                                            <Link to={`/admin/useradd`} className="btn btn-primary btn-sm ms-1">+ Add User</Link> {" "}
                                        </div> */}
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    {loading ? (
                                        <div class="spinner">
                                            <div class="lds-roller">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div> 
                                        </div>
                                    
                                    ) : (
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr style={{ textAlign: '' }}>
                                                    <th >
                                                        <strong>No.</strong>
                                                    </th>   
                                                    <th>
                                                        <strong>Student Name</strong>
                                                    </th>
                                                    <th>
                                                        <strong>School Name</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Game Name</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Game Domain Name</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Level Name</strong>
                                                    </th> 
                                                    <th>
                                                        <strong>Score</strong>
                                                    </th>
                                                    <th  style={{ textAlign: 'left' }}>
                                                        <strong>Status</strong>
                                                    </th> 
                                                   {/* <th><strong>Action</strong></th> */}
                                                </tr>
                                            </thead>
                                            {(totalData === 0) ?
                                           <div style={{padding:'10px'}}><h5>No Data Found</h5></div>
                                            : 
                                            <tbody>

                                                {
                                                    records.map((element, index) => { 
                                                         return (
                                                            <tr style={{ textAlign: ' ' }}>
                                                                <td>
                                                                    {index + 1}
                                                                </td> 
                                                                <td> 
                                                                       {element.user_name}
                                                                 </td> 
                                                                  
                                                                <td>
                                                                    {element.school_name} 
                                                                </td> 
                                                                <td>
                                                                    {element.game_name} 
                                                                </td> 
                                                                <td>
                                                                    {element.domain_name} 
                                                                </td> 
                                                                <td>
                                                                    {element.level_name} 
                                                                </td> 
                                                                <td>
                                                                    {element.score} 
                                                                </td> 
                                                                <td style={{ textAlign: 'left' }}>
                                                                    {element.status} 
                                                                </td> 
                                                                
                                                                {/* <td style={{ textAlign: '' }}> 
                                                                    <div className="d-flex"> */}
                                                                        {/* <Link

                                                                            to={`/admin/useredit/${element.id}`}
                                                                            className="btn btn-primary shadow btn-xs sharp me-1"
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </Link>
                                                                        <Link
                                                                            onClick={() =>
                                                                                swal({
                                                                                    title: "Are you sure?", 
                                                                                    icon: "warning",
                                                                                    buttons: true,
                                                                                    dangerMode: true,
                                                                                }).then((willDelete) => {
                                                                                    if (willDelete) {
                                                                                        (handleDelete(element.id));
                                                                                    }
                                                                                })
                                                                            }

                                                                            className="btn btn-danger shadow btn-xs sharp"
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link> */}
                                                                    {/* </div>
                                                                </td> */}
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                            }
                                        </table>
                                    )}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                                            <div className='dataTables_info'>
                                         
                                                Showing {lastIndex - recordsPage + 1} to{" "}
                                                {UserZeroTestList.length < lastIndex ? UserZeroTestList.length : lastIndex}
                                                {" "}of {UserZeroTestList.length} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={prePage}
                                                >
                                                    <i className="fa-solid fa-angle-left" />
                                                </Link>
                                                <span>
                                                    {number.map((n, i) => (
                                                        <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                            onClick={() => changeCPage(n)}
                                                        >
                                                            {n}

                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={nextPage}
                                                >
                                                    <i className="fa-solid fa-angle-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default UserGamesList;
