import React, { useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminGameService from '../../api/services/AdminGameService';
import AdminLevelService from '../../api/services/AdminLevelService';

   
                    
const GameAdd = () => {   
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")  
    const [LevelsData, setLevelsData] = useState('');
    const [LevelsList, setLevelsList] = useState([]);  
 
    const onFileChange = (e,file) => {
      if(file)
      {
        setFile();
        return;
      }
      const fileReader = new FileReader()
      fileReader.readAsDataURL(e.target.files[0])
      fileReader.onload = () => {
        var image = fileReader.result
        setFile(image)
      }
    }

      const onSubmitForm = async (event) => {
        event.preventDefault();
        try { 
          var userData = { 
            "title": event.target.title.value,
            "image": file ? file.split(',')[1] : "",
            "passing_score": event.target.passing_score.value, 
            "totalscore": event.target.totalscore.value, 
            "level_id": event.target.level_id.value,  
          }
            let responseData = await AdminGameService.store(userData).json()
            if (responseData.status === true) { 
              navigate("/admin/game");
            }
          } catch (error) {
            
            if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
          }
       
    };
    
    const getLevelsList = async () => { 
      try { 
          let responseData = await AdminLevelService.index().json();  
          setLevelsList(responseData.data);
      } catch (error) {               
          if (error.name === 'HTTPError') {
              const errorJson = await error.response.json();  
              setError(errorJson.message);
          }
      }
  }

    const onLevelChange = (e) => { 
      setLevelsData(e.target.value);
    }
 
      useEffect(() => { 
        getLevelsList();
      }, []);
 
   
    return(
        <> 
        <MainPagetitle mainTitle="Game" pageTitle={'Game'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
			    	<div className="col-xl-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Add Game</h4>
                            </div>           
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row"> 
                                    <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-image" className="form-label" >Image<span className="text-danger">*</span></label>
                                            <input type="file" className="form-control" name='image' id="val-image" accept="image/png, image/jpeg" onChange={(e)=>onFileChange(e,file)} />
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-title" className="form-label" >Title<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='title' id="val-title" placeholder=""/>
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-passing_score" className="form-label" >Passing Score<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name='passing_score' id="val-passing_score" placeholder=""/>
                                        </div>	 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-totalscore" className="form-label" >Total Score<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name='totalscore' id="val-passing_score" placeholder=""/>
                                        </div>	  
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-level_id" className="form-label" >Select Level<span className="text-danger">*</span></label>
                                            <select className="form-control" id="level_id" name="level_id" onChange={onLevelChange}  > 
                                            <option selected disabled value={''}>Select Level</option> 
                                            {LevelsList.map(Level => (
                                                    <option key={Level.id} value={Level.id}>{Level.title}</option>
                                                ))}
                                            </select>
                                         </div>	 
                                    </div>
                                    <h5 style={{color:'red'}}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <Link  to={`/admin/game`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link> 
                                     </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};
export default GameAdd;