import React, { useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';

import AdminLevelService from '../../api/services/AdminLevelService';
import AdminClassService from '../../api/services/AdminClassService';
import AdminSubjectService from '../../api/services/AdminSubjectService';

                    
const Levelsadd = () => {   
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")
    const [Classlist, setClasses] = useState([]); 
    const [ClassesData, setClassesData] = useState('');
    const [Subjectlist, setSubject] = useState([]); 
    const [Subjectlistmain, setSubjectmain] = useState([]); 
    const [SubjectData, setSubjectData] = useState('');
     
  
      const onSubmitForm = async (event) => {
        event.preventDefault();
        try {
          var userData = { 
            "title": event.target.title.value,
            "subject_id": event.target.subject_id.value, 
            "classroom_id": event.target.classroom_id.value,              
          }
            let responseData = await AdminLevelService.store(userData).json()
            if (responseData.status === true) { 
              navigate("/admin/level");
            }
          } catch (error) { 
            if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
          }
       
    };

    const getClasses = async () => { 
        try { 
            let responseData = await AdminClassService.index().json();    
            setClasses(responseData.data);
        } catch (error) { 
            console.log(error);
        }  
      };

      const getSubject = async () => { 
        try { 
            let responseData = await AdminSubjectService.index().json();   
            setSubject(responseData.data);
        } catch (error) { 
            console.log(error);
        }  
      };
      const onClassChange = (e) => { 
        const selectedClassId = e.target.value; 
        setClassesData(selectedClassId);  
        const selectedClass = Classlist.find(cls => cls.id == selectedClassId);  
            if (selectedClass) { 
                setSubjectmain(selectedClass.subjects);
            } else {
                console.log('Selected class not found');
                setSubjectmain([]); 
            }
        }
        
        const onSubjectChange = (e) => { 
            setSubjectData(e.target.value);
          }
      
      useEffect(() => {
        getClasses(); 
        getSubject();
      }, []);
 
   
    return(
        <> 
        <MainPagetitle mainTitle="Levels" pageTitle={'Levels'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
			    	<div className="col-xl-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Add Level</h4>
                            </div>           
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row">
                                    <div className="col-xl-4 mb-3">
                                        <label htmlFor="val-title" className="form-label" >Title<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='title' id="val-title" placeholder="title"/>
                                    </div>	
                                    <div className="col-xl-4 mb-3">
                                            <label htmlFor="val-classroom_id" className="form-label" >Select Class<span className="text-danger">*</span></label>
                                            <select className="form-control" id="classroom_id" name="classroom_id" onChange={onClassChange}  > 
                                            <option selected disabled value={''}>Select Class</option>
                                            {Classlist.map((element, index) =>
                                            <option key={index} value={element.id}>{element.classname} </option>
                                            )}
                                            </select>
                                    </div>	
                                    <div className="col-xl-4 mb-3">
                                            <label htmlFor="val-subject_id" className="form-label" >Select Subject<span className="text-danger">*</span></label>
                                            <select className="form-control" id="subject_id" name="subject_id" onChange={onSubjectChange}  > 
                                            <option selected disabled value={''}>Select Subject</option> 
                                            {Subjectlistmain.map(subject => (
                                                    <option key={subject.id} value={subject.id}>{subject.name}</option>
                                                ))}
                                            </select>
                                    </div>	
                                        
                                    </div>
                                    <h5 style={{color:'red'}}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <Link  to={`/admin/level`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link> 

                                     </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};
export default Levelsadd;