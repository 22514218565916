import React, { useState,useEffect,useRef} from 'react';
import {useNavigate, Link} from 'react-router-dom'; 
import MainPagetitle from '../../layouts/MainPagetitle';


import AdminLessonService from '../../api/services/AdminLessonService';
import AdminTaskService from '../../api/services/AdminTaskService';
import AdminModuleService from '../../api/services/AdminModuleService'; 
import { Editor } from "@tinymce/tinymce-react";
   
                    
const LessonAdd = () => {   
    
    const navigate = useNavigate();
    const [file, setFile] = useState()
    const [error, setError] = useState("")     
    const [ModulesData, setModules] = useState([]);
    const [selectedModuleId, setSelectedModuleId] = useState('');
    // const [selectedModuleTasks, setSelectedModuleTasks] = useState([]);
   
    useEffect(() => {
      fetchModuleData ();  
    }, []);
     
    const fetchModuleData  = async () => {
        try {
          let responseData = await AdminModuleService.index().json();   
          setModules(responseData.data);
        } catch (error) {
          console.log(error);
        } 
      };   
      const handleModuleChange = (e) => {  
          const moduleId = e.target.value;
          setSelectedModuleId(moduleId); 
      
          // let selectedModule = null;
          // for (let i = 0; i < ModulesData.length; i++) {
          //   console.log("Selected Module:", moduleId); 
          //     if (ModulesData[i].id == moduleId) {
          //         selectedModule = ModulesData[i]; 
          //         break;  
          //     }
          // } 
          // if (selectedModule) {
          //     setSelectedModuleTasks(selectedModule.tasks);
          // } else {
          //     setSelectedModuleTasks([]);  
          // }
      };
    
     

      const onSubmitForm = async (event) => {
        event.preventDefault();
        try {
          var userData = {
            "title": event.target.title.value, 
            "video_url": event.target.video_url.value, 
            "notes": event.target.notes.value,  
            "module_id": event.target.module_id.value, 
            "description": event.target.description.value,   
          }
            let responseData = await AdminLessonService.store(userData).json()
            if (responseData.status === true) {
             
              navigate("/admin/lesson");
            }
          } catch (error) {
            console.log(error)
            if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
          }
       
    };
   
    return(
        <> 
        <MainPagetitle mainTitle="Lesson" pageTitle={'Lesson '} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
			    	<div className="col-xl-12">
                        <div className="card"> 
                            <div className="card-header">
                                <h4 className="card-title">Add Lesson</h4>
                            </div>           
                            <div className="card-body">
                                <form className="form-valide" onSubmit={onSubmitForm}>
                                    <div className="row">
                                    
                                    <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Select Module<span className="text-danger">*</span></label>
                                            <select onChange={handleModuleChange} className="form-control" id="module_id" name="module_id"  >  
                                            <option disabled selected>Select Module</option>
                                              {ModulesData.map((element, index) =>
                                                <option key={index} value={element.id}>{element.name}</option>
                                              )}
                                            </select> 
                                        </div>	
                                         
                                        {/* <input type="hidden" id="module_id"   name="module_id" value={selectedTaskId}  />

                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-Module" className="form-label" >Module<span className="text-danger">*</span></label>
                                            <input type="text"   className="form-control"   placeholder={selectedModuleName}/>
                                        </div>	 */}
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >title<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='title' id="val-username" placeholder=""/>
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-username" className="form-label" >Video Url<span className="text-danger">*</span></label>
                                            <input type="url" className="form-control" name='video_url' id="val-username" placeholder=""/>
                                        </div>	
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-notes" className="form-label" >notes</label>
                                            <input type="text" className="form-control" name='notes' id="val-notes" placeholder=""/>
                                        </div>	
                                        <div className="col-xl-12 mb-3">
                                            <label htmlFor="val-description" className="form-label" >Description</label> 
                                            {/* <textarea name='description' id='val-description' className="form-control" rows={4}  cols={40}></textarea> */}
                                            <Editor   
                                            apiKey="mzqtqwlw84qnnu5kf0a75l26z26pon7ty2tl1lw5b7u5ukf7"  
                                            textareaName='description' 
                                            init={{
                                              height: 200,
                                              menubar: false,
                                              plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "code",
                                                "help",
                                                "wordcount",
                                              ],
                                              toolbar:
                                                "undo redo | blocks | " +
                                                "bold italic forecolor | alignleft aligncenter " +
                                                "alignright alignjustify | bullist numlist outdent indent | " +
                                                "removeformat | help",
                                              content_style:
                                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                            }}
                                          />
                                        </div>	

                                      
                                        
                                    </div>
                                    <h5 style={{color:'red'}}>{error}</h5>
                                    <div>
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <Link  to={`/admin/lesson`}  type="reset" className="btn btn-danger light ms-1">Cancel</Link> 
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    );
};
export default LessonAdd;