
import clientAuth from "../clientAuth"
export default {
    index: () => clientAuth.get(`module_index`),
    store: (userData) => clientAuth.post('module_add', { json: userData }),
    show: (id) => clientAuth.get(`module_show/${id}`),
    update: (id, userData) => clientAuth.post(`module_update/${id}`, { json: userData }),
    destroy: (id) => clientAuth.delete(`module_delete/${id}`),

    Role_index: () => clientAuth.get(`teacher`),   
    // Task_index: () => clientAuth.get(`task_index`),
    show_module_complete_list: (id) => clientAuth.get(`completemodule_list/${id}`),
      
    teacher_list: (id) => clientAuth.get(`teacher/${id}`),
    
    T_update: (id, userData) => clientAuth.post(`taskupdate/${id}`, { json: userData }),
    
     // module_its_task_index
     MT_list_index: () => clientAuth.get(`module_its_task_list`) 
};