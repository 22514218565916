import React, { useState, useEffect,useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import FormWizard from "react-form-wizard-component";

import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import AdminModuleService from '../../api/services/AdminModuleService';
import AdminGameService from '../../api/services/AdminGameService';
import AdminLevelService from '../../api/services/AdminLevelService';
import AdminClassService from '../../api/services/AdminClassService';
import AdminSchoolAssignTouserService from '../../api/services/AdminSchoolAssignTouserService';

import { Editor } from "@tinymce/tinymce-react";
import swal from "sweetalert";


const SchoolAssignToUsersAdd = () => {
    var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL+'User/';
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState()
  const [error, setError] = useState("")
  const [GameList, setGameList] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectevalue, selectedarrayValues] = useState([]);
  const [LevelsList, setLevelsList] = useState([]);
  const [LevelsData, setLevelsData] = useState('');
  const [ClassdataList, setClassList] = useState([]);
  const [ClassroomData, setClassroomData] = useState([]);
   // school input
  const [SchoolTabname, setnameTabInput] = useState("");
  const [SchoolTabaddress, setaddressTabInput] = useState("");
  const [SchoolTabstate, setstateTabInput] = useState("");
  const [SchoolTabcity, setcityTabInput] = useState("");
  // teacher input
  const [teacherTabfirstname, setfirstnameTabInput] = useState("");
  const [teacherTablastname, setlastnameTabInput] = useState("");
  const [teacherTabemail, setemailTabInput] = useState(""); 
  const [teacherTabpassword, setpasswordTabInput] = useState(""); 
  const [teacherTabroleid, setTeacherroleidTabInput] = useState(""); 
  const [teacherTabclassid, setclassroomidTabInput] = useState(""); 
   
  // student input
  const [StudentTabfirstname, setstudfirstnameTabInput] = useState("");
  const [StudentTablastname, setstudlastnameTabInput] = useState("");
  const [StudentTablastemail, setstudemailTabInput] = useState("");
  const [StudentTablastcontinuityscore, setstudcontinuityscoreTabInput] = useState("");
  const [StudentTablastpassword, setstudpasswordTabInput] = useState("");
  const [StudentTablastcurrentlevel, setstudcurrentlevelTabInput] = useState("");
  const [StudentTablastroleid, setstudroleidTabInput] = useState("");
 
  const [SchoolAssingUSerUpdate, SetSchoolAssingUSerUpdate] = useState([]);

  
  const GetSchoolAssingUSerUpdate = async (id) => {
    try {
        setLoading(true);
        let responseData = await AdminSchoolAssignTouserService.show(id).json();  
        console.log(responseData);
        SetSchoolAssingUSerUpdate(responseData.data);   
    }   catch (error) {
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json(); 
            setError(errorJson.message)
        }
    }
}
  const checkValidateTab = () => { 
    if (SchoolTabname === "",SchoolTabaddress === "",SchoolTabstate === "",SchoolTabcity === ""
      ,teacherTabfirstname === "",teacherTablastname === "",teacherTabemail === "" 
    ) {
      return false;
    }
    
    return true;
  };
  // error messages
  const errorMessages = () => { 
    alert("Please fill in the required fields");
  };

  const onFileChange = (e, file) => {
    if (file) {
      setFile();
      return;
    }
    const fileReader = new FileReader()
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      var image = fileReader.result
      setFile(image)
    }
  }
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
};
   const handleComplete = () => {
    console.log("Form completed!"); 
  }; 
  const onSubmitForm = async (event) => {
     console.log('event',event.target);
    event.preventDefault();
    try {

      var userData = {
        name: SchoolTabname,
        address: SchoolTabaddress,
        state: SchoolTabstate,
        city: SchoolTabcity,

        firstname: teacherTabfirstname,
        lastname: teacherTablastname,
        email : teacherTabemail,
        password: teacherTabpassword,
        image: file ? file.split(',')[1] : "",
        teacher_role_id: teacherTabroleid,
        classroom_id:teacherTabclassid,
 
        student_firstname: StudentTabfirstname,
        student_lastname: StudentTablastname,
        student_email: StudentTablastemail,
        student_password: StudentTablastpassword,
        student_currentlevel: LevelsData,
        student_continuityscore: StudentTablastcontinuityscore,
        student_role_id : StudentTablastroleid,
        student_image : file ? file.split(',')[1] : "",

        gameid: selectevalue,
        // "state": file ? file.split(',')[1] : "",
      }
   
      
      const data = await AdminSchoolAssignTouserService.update(params.id, userData).json();

      if (data.status === true) {

          swal("schoool with user Update Succesfully").then((willDelete) => {
              if (willDelete) {
                navigate("/admin/school/assign/user")
              }
          })

      }
       if (data.status === false) {  
        setError(data.message); 
      }

    } catch (error) {
      console.log(error)
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    } 
      
  };

  const selectedOptions = useMemo(() => {
    return GameList.filter(game => SchoolAssingUSerUpdate.games && SchoolAssingUSerUpdate.games.some(item => item.id === game.id));
}, [GameList, SchoolAssingUSerUpdate]);


  useEffect(() => {
    getClassList();
    getGameList(); 
    getLevelsList();

    setnameTabInput();
    setaddressTabInput();
    setstateTabInput();
    setcityTabInput(); 

    setfirstnameTabInput();
    setlastnameTabInput();
    setemailTabInput();
    setpasswordTabInput();
    setTeacherroleidTabInput();
    setclassroomidTabInput(); 

   setstudfirstnameTabInput();
   setstudlastnameTabInput();
   setstudemailTabInput();
   setstudcontinuityscoreTabInput();
   setstudpasswordTabInput();
   setstudcurrentlevelTabInput();
   setstudroleidTabInput();
   SetSchoolAssingUSerUpdate(); 

    setSelected(selectedOptions.map(option => ({ value: option.id, label: option.title })));
    if (localStorage.getItem('usertoken')) { 
        GetSchoolAssingUSerUpdate(params.id);  
    } 
    
}, [selectedOptions])
  const getClassList = async () => {
    try {
        let responseData = await AdminClassService.index().json();
        setClassList(responseData.data);
    } catch (error) {
        console.log(error);
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message);
        }
    }
}
  const onClassroomChange = (e) => {
    setClassroomData(e.target.value);
}

// level list
  const getLevelsList = async () => {
    try {
        let responseData = await AdminLevelService.index().json();
        setLevelsList(responseData.data);
    } catch (error) {
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message);
        }
    }
} 
  const onLevelChange = (e) => {
    setLevelsData(e.target.value);
}


// Game list
  const getGameList = async () => { 
    try {  
        let responseData = await AdminGameService.index().json();  
        setGameList(responseData.data);
    } catch (error) {
        console.log(error);
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message);
        }
    }
}
  const handleSelectChange  = (selectedItems) => {    
    const selectedValues = selectedItems.map(item => item.value);
    selectedarrayValues(selectedValues);
    setSelected(selectedItems);
  }
  const options = GameList.map(element => ({
    value: element.id,
    label: element.title, 
}));

 
  const tabChanged = (tabChangeEvent) => {
    const { prevIndex, nextIndex } = tabChangeEvent;
    console.log("prevIndex", prevIndex);
    console.log("nextIndex", nextIndex);
  };
 
  return (
    <>
      <MainPagetitle mainTitle="School with User Edit" pageTitle={'School with User Edit'} parentTitle={'Home'} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Edit School with User</h4>
              </div>
              <div className="card-body">
                <div className='form-box-Wizard'>
              <form className="form-valide" onSubmit={(event)=>onSubmitForm(event)}>
                  <FormWizard
                    shape="circle"
                    color="#e74c3c"
                    onComplete={(event)=>onSubmitForm(event)}
                    onTabChange={tabChanged}
                    nextButtonTemplate={(handleNext) => (
                      <button className="base-button wizard-btn" onClick={handleNext}>
                        next
                      </button>
                    )}
                    finishButtonTemplate={(handleComplete) => (
                      <button className="finish-button wizard-btn" type="submit"  >
                        Record Save
                      </button>
                    )}
                  >
                    <FormWizard.TabContent title="Add School" icon="ti-check">
              
                      {/* <h1>First Tab</h1> */}
                      <div className="row"> 
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >Name<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='name' id="val-username" placeholder=""  
                                // value={SchoolTabname }
                                value={SchoolTabname || SchoolAssingUSerUpdate?.school?.[0]?.name || ''}
                              onChange={(e) => setnameTabInput(e.target.value)} isValid={checkValidateTab()} />
                            </div>	
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >Address<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='address' id="val-username" placeholder=""  
                                // value={SchoolTabaddress}
                                value={SchoolTabaddress || SchoolAssingUSerUpdate?.school?.[0]?.name || ''}
                                 onChange={(e) => setaddressTabInput(e.target.value)} isValid={checkValidateTab()} />
                            </div>	
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >State<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='state' id="val-username" placeholder="" 
                                // value={SchoolTabstate} 
                                value={SchoolTabstate || SchoolAssingUSerUpdate?.school?.[0]?.state || ''}
                                onChange={(e) => setstateTabInput(e.target.value)} isValid={checkValidateTab()}/>
                            </div>	
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="val-username" className="form-label" >City<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='city' id="val-username" placeholder=""
                                //  value={SchoolTabcity} 
                                 value={SchoolTabcity || SchoolAssingUSerUpdate?.school?.[0]?.city || ''}
                                 onChange={(e) => setcityTabInput(e.target.value)} isValid={checkValidateTab()}/>
                            </div>	
                      </div>
                    </FormWizard.TabContent>

                    <FormWizard.TabContent title="Add Teacher" icon="ti-check">
                      {/* <h1>Second Tab</h1> */}
                      
                      <div className="row">
                     
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor="val-firstname" className="form-label" >First Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='firstname' placeholder="" 
                                        // value={teacherTabfirstname} 
                                        value={teacherTabfirstname || SchoolAssingUSerUpdate?.teacher?.[0]?.firstname || ''}
                                        onChange={(e) => setfirstnameTabInput(e.target.value)} isValid={checkValidateTab()}/>
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor="val-lastname" className="form-label" >Last Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='lastname' placeholder="" 
                                        // value={teacherTablastname} 
                                        value={teacherTablastname || SchoolAssingUSerUpdate?.teacher?.[0]?.lastname || ''}
                                        onChange={(e) => setlastnameTabInput(e.target.value)} isValid={checkValidateTab()}/>
                                    </div>
                                    <div className="col-xl-6 mb-3">
                                        <label htmlFor="val-email" className="form-label" >Email<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='email' placeholder="" 
                                        // value={teacherTabemail} 
                                        value={teacherTabemail || SchoolAssingUSerUpdate?.teacher?.[0]?.email || ''}
                                        onChange={(e) => setemailTabInput(e.target.value)} isValid={checkValidateTab()}/>
                                    </div>
                                    
                                    <div className="col-xl-6 mb-3">
                                    <img
                                        className="img-drop-area"
                                        height={SchoolAssingUSerUpdate.teacher?.[0]?.image ? 50 : 0}
                                        width={50}
                                        src={SchoolAssingUSerUpdate.teacher?.[0]?.image ? `${imageUrl}${SchoolAssingUSerUpdate.teacher[0].image}` : "User"}
                                        alt="User"
                                        />


                                        <label htmlFor="val-image" className="form-label" >Image<span className="text-danger">*</span></label> 
                                        <input type="file" className="form-control" name='image' accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />

                                    </div> 
                                    <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-school_id" className="form-label" >Select Class</label>
                                                    <select className="form-control" name="classroom_id"  onChange={(e) => setclassroomidTabInput(e.target.value)} >
                                                        <option disabled selected value=''>Select Class</option>
                                                        {ClassdataList.map((element, index) =>
                                                            <option key={index} value={element.id} selected={SchoolAssingUSerUpdate?.classroom?.[0]?.id == element.id }>{element.classname} </option>
                                                        )}
                                                    </select>
                                                </div>
                                        <input type="hidden" className="form-control" name='teacher_role_id' value={2} onChange={(e) => setTeacherroleidTabInput(e.target.value)}/>
                                    
                                    </div>
                    </FormWizard.TabContent>

                    <FormWizard.TabContent title="Add Student" icon="ti-check">
                      {/* <h1>third Tab</h1>  */}
                      <div className="row">
 
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-student_firstname" className="form-label" >First Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='student_firstname' placeholder=""  value={StudentTabfirstname || SchoolAssingUSerUpdate.firstname} onChange={(e) => setstudfirstnameTabInput(e.target.value)} isValid={checkValidateTab()}/>
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-student_lastname" className="form-label" >Last Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='student_lastname' placeholder="" value={StudentTablastname || SchoolAssingUSerUpdate.lastname} onChange={(e) => setstudlastnameTabInput(e.target.value)} isValid={checkValidateTab()}/>
                                        </div>
                                        <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-student_email" className="form-label" >Email<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='student_email' placeholder=""value={StudentTablastemail || SchoolAssingUSerUpdate.email} onChange={(e) => setstudemailTabInput(e.target.value)} isValid={checkValidateTab()} />
                                        </div> 
                                         
                                        <div className="col-xl-6 mb-3">
                                        <img className="img-drop-area" height={SchoolAssingUSerUpdate.image ? 50 : 0} width={50} src={SchoolAssingUSerUpdate.image ? imageUrl + SchoolAssingUSerUpdate.image: "User"} alt='User'/>
                                            <label htmlFor="val-image" className="form-label" >Image<span className="text-danger">*</span></label>
                                            
                                            <input type="file" className="form-control" name='student_image' accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />

                                        </div>
                                        <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-student_currentlevel" className="form-label" >Select Current Level<span className="text-danger">*</span></label>
                                                    <select className="form-control" name="student_currentlevel" onChange={onLevelChange}  >
                                                        <option selected disabled value={''}>Select Current Level</option>
                                                        {LevelsList.map(Level => (
                                                            <option key={Level.id} value={Level.id} selected={SchoolAssingUSerUpdate.currentlevel == Level.id}>{Level.title}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-xl-6 mb-3">
                                                    <label htmlFor="val-student_continuityscore" className="form-label" >Continuity Score<span className="text-danger">*</span></label>
                                                    <input type="number" className="form-control" name='student_continuityscore' id="val-continuityscore" placeholder="" value={StudentTablastcontinuityscore || SchoolAssingUSerUpdate.continuityscore} onChange={(e) => setstudcontinuityscoreTabInput(e.target.value)} isValid={checkValidateTab()}/>
                                                </div>
                                                <input type="hidden" className="form-control" name='student_role_id' value={3} onChange={(e) => setstudroleidTabInput(e.target.value)}/>
                                           
                                    </div>
                    </FormWizard.TabContent>

                    <FormWizard.TabContent title="Add Game" icon="ti-check">
                      {/* <h1>Last Tab</h1> */}

                      <div className="col-xl-6 mb-3">
                                            <label htmlFor="val-game" className="form-label" >Select Game<span className="text-danger">*</span></label>
                                                <MultiSelect
                                                   name="game"
                                                   options={options}
                                                    value={selected}
                                                    onChange={handleSelectChange }
                                                   placeholder="Select Games" 
                                                /> 
                                        </div>	                       
                    </FormWizard.TabContent>
                  </FormWizard>
                
                   
                  <h5 style={{ color: 'red' }}>{error}</h5>
                  {/* <div>
                    <button type="submit" className="btn btn-primary me-1">Submit</button>
                    <Link to={`/admin/module_index`} type="reset" className="btn btn-danger light ms-1">Cancel</Link>
                  </div> */}
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default SchoolAssignToUsersAdd;