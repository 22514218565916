import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Outlet } from "react-router-dom";


/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";
 
//  Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Login from "./pages/Login";
 


// module
import ModuleList from "./components/Module/ModuleList";
import ModuleShowUpdate from "./components/Module/ModuleShowUpdate";
import ModuleAdd from "./components/Module/ModuleAdd";
import Module_task_complete from "./components/Module/Module_task_complete";


// task
import TaskList from "./components/Tasks/TaskList";
import TaskShowUpdate from "./components/Tasks/TaskShowUpdate.js";
import TaskAdd from "./components/Tasks/TaskAdd.js";

// LessonList
import LessonList from "./components/Lesson/LessonList";
import LessonAdd from "./components/Lesson/LessonAdd";
import LessonShowUpdate from "./components/Lesson/LessonShowUpdate";


// school
import SchoolList from "./components/School/SchoolList";
import SchoolAdd from "./components/School/SchoolAdd";
import SchoolUpdate from "./components/School/SchoolUpdate";

// user 
import UserList from "./components/User/UserList";
import UserAdd from "./components/User/UserAdd";
import UserEdit from "./components/User/UserEdit";

// Teacher and student list
import TeachersUserList from "./components/User/TeachersUserList.js";
import StudentsUserList from "./components/User/StudentsUserList.js";

// Games
import GameList from "./components/GameModule/GameList.js";
import GameAdd from "./components/GameModule/GameAdd.js";
import GameUpdate from "./components/GameModule/GameUpdate.js";

// Student Game Score
import StudentGameScoreList from "./components/StudentGameScore/StudentGameScoreList.js";
import StudentGameScoreAdd from "./components/StudentGameScore/StudentGameScoreAdd.js";
import StudentGameScoreUpdate from "./components/StudentGameScore/StudentGameScoreUpdate.js";

// subject
import SubjectsList from "./components/Subject/SubjectsList.js";
import Subjectsadd from "./components/Subject/Subjectsadd.js";
import SubjectsEdit from "./components/Subject/SubjectsEdit.js";

// class
import ClassesList from "./components/Classes/ClassesList.js";
import Classesadd from "./components/Classes/Classesadd.js";
import ClassesEdit from "./components/Classes/ClassesEdit.js";
 
// levels
import LevelsList from "./components/Levels/LevelsList.js";
import Levelsadd from "./components/Levels/Levelsadd.js";
import LevelsEdit from "./components/Levels/LevelsEdit.js";

import SchoolAssignToUsers from "./components/SchoolAssignUsers/SchoolAssignToUsers.js";
import SchoolAssignToUsersAdd from "./components/SchoolAssignUsers/SchoolAssignToUsersAdd.js";
import SchoolAssignToUsersUpdate from "./components/SchoolAssignUsers/SchoolAssignToUsersUpdate.js";

// Games : 03-07-2024
import GameDomainList from "./components/GameDomain/GameDomainList.js";

// Zero Test :12-07-2024
import UserZeroTestList from "./components/UserZeroTest/UserZeroTestList.js";
import UserGamesList from "./components/UserGame/UserGamesList.js";

import ChangePassword from "./components/ChangePassword/changepassword.js";
const allroutes = [
  // Dashboard
  { url: "", component: <Login /> },
  { url: "dashboard", component: <Home /> },
  { url: "admin/change-password", component: <ChangePassword /> },
  
  // school assign to users
  { url: "admin/school/assign/user", component: <SchoolAssignToUsers /> },
  { url: "admin/school/assign/user/add", component: <SchoolAssignToUsersAdd /> },
  { url: "admin/school/assign/user/update/:id", component: <SchoolAssignToUsersUpdate /> },
  
  // Module
  { url: "admin/module_index", component: <ModuleList /> },
  { url: "admin/module_add", component: <ModuleAdd /> },
  { url: "admin/module_show/:id", component: <ModuleShowUpdate /> }, 
  { url: "admin/module_task_complete/:id", component: <Module_task_complete /> },
  
  // Task
  { url: "admin/task_index", component: <TaskList /> },
  { url: "admin/task_add", component: <TaskAdd /> },
  { url: "admin/task_show/:id", component: <TaskShowUpdate /> },

  // Lesson
  { url: "admin/lesson", component: <LessonList /> },
  { url: "admin/lessonadd", component: <LessonAdd /> },
  { url: "admin/lessonedit/:id", component: <LessonShowUpdate /> },


  // school
  { url: "admin/school", component: <SchoolList /> },
  { url: "admin/schooladd", component: <SchoolAdd /> },
  { url: "admin/schoolupdate/:id", component: <SchoolUpdate /> },

   // User
   { url: "admin/user", component: <UserList /> },
   { url: "admin/useradd", component: <UserAdd /> },
   { url: "admin/useredit/:id", component: <UserEdit /> },

   { url: "admin/Teachers", component: <TeachersUserList /> },
   { url: "admin/Students", component: <StudentsUserList /> },
  
  //  Games
   { url: "admin/game", component: <GameList /> },
   { url: "admin/gameadd", component: <GameAdd /> },
   { url: "admin/gameupdate/:id", component: <GameUpdate /> },

  //  student game score
   { url: "admin/student_game_score", component: <StudentGameScoreList /> },
   { url: "admin/studentgamescoreadd", component: <StudentGameScoreAdd /> },
   { url: "admin/studentgamescoreupdate/:id", component: <StudentGameScoreUpdate /> },
 
  //  subject
  { url: "admin/subject", component: <SubjectsList /> },
  { url: "admin/subjectadd", component: <Subjectsadd /> },
  { url: "admin/subjectedit/:id", component: <SubjectsEdit /> },

   //  class
   { url: "admin/class", component: <ClassesList /> },
   { url: "admin/classadd", component: <Classesadd /> },
   { url: "admin/classedit/:id", component: <ClassesEdit /> },

   //  Levels
   { url: "admin/level", component: <LevelsList /> },
   { url: "admin/leveladd", component: <Levelsadd /> },
   { url: "admin/leveledit/:id", component: <LevelsEdit /> },

    // Game Domains : 03-07-2024
   { url: "admin/game_domain", component: <GameDomainList /> },

   //Student Zero Test Data : 12-07-2024
   {url : "admin/users/zero_test",component:<UserZeroTestList />},
   //Student Game Data : 12-07-2024
   {url : "admin/users/game/data",component:<UserGamesList />},
  ];

const Markup = () => {

  function NotFound() {
    const url = allroutes.map((route) => route.url);
    let path = window.location.pathname
    path = path.split('/')
    path = path[path.length - 1]

    if (url.indexOf(path) <= 0) {
      return <Error404 />
    }
  }

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/page-lock-screen' element={<LockScreen />} />
        <Route path='/page-error-400' element={<Error400 />} />
        <Route path='/page-error-403' element={<Error403 />} />
        <Route path='/page-error-404' element={<Error404 />} />
        <Route path='/page-error-500' element={<Error500 />} />
        <Route path='/page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};


function MainLayout() {
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
};



export default Markup;
