import client from "../client";
import clientAuth from "../clientAuth"

export default {
  // 
  login: (email, password) => client.post("session/login", {
    json: {
      email: email,
      password: password,
      device_name: "web"
    }
  }),
  //
  logout: () => clientAuth.post("session/logout"),
  verifyotp: (userdata) => client.post("session/verifyotp", { json: userdata }),
  resendotp: (userdata) => client.post("session/resendotp", { json: userdata })

};